import * as actionTypes from "../actions/actionTypes";

const initialState = {
  location: "",
  page: 0,
  homeSearch: false
};

const reducer = (state, action) => {
  state = state || initialState;
  let _location = state.location;
  let _page = state.page;
  let _homeSearch = state.homeSearch;

  switch (action.type) {
    case actionTypes.SET_NAVIGATION:
      _location = action.location;
      {
        return Object.assign({}, state, {
          location: _location
        });
      }

    case actionTypes.SET_PAGE:
      _page = action.page;
      {
        return Object.assign({}, state, {
          page: _page
        });
      }

    case actionTypes.SET_HOME_SEARCH:
      _homeSearch = action.homeSearch;
    {
      return Object.assign({}, state, {
        homeSearch: _homeSearch
      });
    }
    case actionTypes.RESET_HOME_SEARCH:
      _homeSearch = action.homeSearch;
    {
      return Object.assign({}, state, {
        homeSearch: _homeSearch
      });
    }
  }
  return state;
};

export default reducer;
