import 'babel-polyfill';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore, REHYDRATE } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import 'bootstrap';
import Layout from './components/layout/Layout';
import Maintenance from './components/layout/maintenancen';
import createStore from '../redux-store/store';

require( './assets/scss/style.scss' );

const store = createStore( window.REDUX_DATA );
const persistor = persistStore( store, {}, () => {
    store.dispatch( { type: REHYDRATE } );
} );
let jsx = (
    <PersistGate loading={ null } persistor={ persistor }>
        <ReduxProvider store={ store }>
            <Router>
                <Layout/>
            </Router>
        </ReduxProvider>
    </PersistGate>

);
let jsxMaintenance = (
    <PersistGate loading={ null } persistor={ persistor }>
        <ReduxProvider store={ store }>
            <Router>
                <Maintenance/>
            </Router>
        </ReduxProvider>
    </PersistGate>

);
const app = document.getElementById( 'app' );
ReactDOM.hydrate( ( process.env.MAINTENANCE === 'true' ? jsxMaintenance : jsx ), app );
