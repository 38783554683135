import React from 'react';

import Filterelement from './filter/Filterelement';

class Filter extends React.Component {
    constructor( props ) {
        super( props );
    }

    render() {
        return (
            <div className="filter-wrapper">
                <div className=" border-dark filter filter-padd">
                    <br/>
                    <Filterelement StateValues={ this.props.values }/>
                </div>
            </div>
        );
    }
}

export default Filter;
