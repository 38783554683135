import React from 'react';
import { connect } from 'react-redux';

class Maintenance extends React.Component {
    constructor() {
        super();
        this.state = {
            loc: ''
        };
    }

    componentDidMount() {
    }

    render() {
        return (
                <div className="warning-content">
                    <h1><b>This site</b> is under Maintenance
                    </h1>

                    <p>
                        Wir führen derzeit planmäßige Wartungsarbeiten an unserem Shop duchgeführt.<br></br>
                    Bitte versuchen Sie es in einigen Minuten erneut.
                        <hr></hr>
                        We are currently performing scheduled maintenance on this onlineshop. <br></br>
                    Please try again in a few minutes.
                    </p>
                </div>
        );
    }
}

const mapStateToProps = state => ( {
    firmData: state.firmData
} );
export default connect( mapStateToProps )( Maintenance );
