import React from 'react';
import Calendar from './AssemblyDate/Calendar';

class AssemblyDate extends React.Component {
   constructor(props) {
      super(props);
      // initial state
      this.state = {
         tyres: this.props.userdata.tyres,
         userdata: this.props.userdata.userdata,
         passedvalues: this.props.userdata.passedvalues,
         steps: 'BillingAddress',
         step: 3,
         deleteDateTime: null
      };
      this.assemblyCallback = (data) => {
         let newdata = this.state.userdata;
         newdata.dates = data;
         this.setState({userdata: newdata});
      };
      this.deleteCallback = (data) => {
         let newstate = this.state;
         newstate.deleteDateTime = data;
         this.setState(newstate);
      };
      this.deleteChosenDateHandler = (e) => {
         this.setState({deleteDateTime: e});
      };
   };

   showChosenDates() {
      if (this.state.userdata.dates.length != 0) {
         return (this.state.userdata.dates.map((date, index) =>
            <div key={index} className={'checked-date'}>
               <div className={'row'}>
                  <div className={'col-4 number'}>
                     Termin {index + 1}
                  </div>
                  <div className={'col-8 appointment'}>
                     {date.chosenDate} {date.chosenTime}
                     <i className="fas fa-times delete-icon"
                        onClick={() => {
                           this.deleteChosenDateHandler(index);
                        }}
                     />
                  </div>
               </div>
            </div>));
      }
   };

   showButton() {
      return (
         <button
            className={'btn btn-outline-success hint ' + (this.state.userdata.dates.length < 3 ? 'disabled' : '')}
            onClick={() => this.props.callBackFromParent(this.state)}
            disabled={this.state.userdata.dates.length < 3}>
            <span className={'inprogress step-' + this.state.userdata.dates.length}/>
            {(this.state.userdata.dates.length < 3) ?
               this.state.userdata.dates.length == 2 ?
                  'Noch 1 Termin wählen'
                  :
                  'Noch ' + (3 - this.state.userdata.dates.length) + ' Termine wählen'
               :
               'Weiter'
            }
         </button>
      );
   }

   render() {
      return (
         <div className={"assemblydate-wrapper"}>
            <div className="container">
               <h2>MONTAGETERMIN</h2>
               <div className="row">
                  <div className="col-12 d-xl-none">
                     <div className="marg-bot">
                        {this.showButton()}
                        {this.showChosenDates()}
                     </div>
                  </div>
                  <div className="col-xl-9 col-lg-12 col-12 ">
                     <Calendar userdata={this.props.userdata}
                               callBackFromParent={this.assemblyCallback.bind(this)}
                               deleteDateTime={this.state.deleteDateTime}
                               deleteCallback={this.deleteCallback.bind(this)}
                     />
                  </div>
                  <div className="col-3 d-none d-xl-block">
                     <div className="marg-bot">
                        <h3>Gewählte Termine: <br/></h3>
                        {this.showButton()}<br/>
                        {this.showChosenDates()}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default AssemblyDate;
