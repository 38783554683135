import React from 'react';
import { connect } from 'react-redux';
import ShoppingCartTyres from '../../components/ShoppingCartTyres';
import TotalPrice from '../../../../shared/priceAmountCaption/TotalPrice';

let i;
let arr;
let fields = [];
let finalpricearray = [];

class CheckoutConfirmation extends React.Component {
    constructor( props ) {
        super( props );
        // initial state
        this.state = {
            tyres: this.props.userdata.tyres,
            userdata: this.props.userdata.userdata,
            passedvalues: this.props.userdata.passedvalues,
            steps: 'CheckoutConfirmed',
            step: 4
        };
        this.callBack = datafromchild => {
            if (datafromchild !== this.state.computedTotalPrice) {
                this.setState( { computedTotalPrice: datafromchild } );
            }
        };
    }

    vendorMessageHandler( field, e ) {
        fields = this.state.userdata.fields;
        fields[field] = e.target.value;
        let userdata = this.state.userdata;
        userdata.fields = fields;
        this.setState( { userdata: userdata } );
    }

    contactBuilder() {
        if (
            this.props.props.firmData &&
            this.props.props.firmData[0] &&
            this.props.props.firmData[0].AllgemeineDaten
        ) {
            i = this.props.props.firmData[this.props.userdata.passedvalues.passedvalues.counter].AllgemeineDaten;
            arr = (
                <div className="border-card">
                    <h4 className="text-highlight"> Ihr Reifenexperte </h4>
                    <div>{ i.Name }</div>
                    <div>{ i.Strasse }</div>
                    <div>
                        <span> { i.Plz } </span>
                        <span> { i.Ort }</span>
                    </div>
                    <div>
                        <b> Telefon: </b>
                        <span> { i.Telefon }</span>
                    </div>
                    <div>
                        <b> E-Mail: </b>
                        <span>{ i.Email }</span>
                    </div>
                </div>
            );
            return arr;
        } else {
            return <span>Konnte keine Händlerdaten finden</span>;
        }
    }

    dateHandler() {
        let buffer = [];
        for (let i = 0; i < this.state.userdata.dates.length; i++) {
            buffer[i] = (
                <div key={ i }>
                    Terminvorschlag { i + 1 + ':  ' }
                    <span className="text-highlight"> { this.state.userdata.dates[i].chosenDate } </span>
                    <span className="text-highlight"> { this.state.userdata.dates[i].chosenTime } </span>
                </div>
            );
        }
        return buffer;
    }

    mapUserData() {
        return (
            <div className="border-card">
                <h4 className="text-highlight"> Ihre Kontaktdaten </h4>
                <div>
                    <span> { this.state.userdata.fields.firstname } </span>
                    <span>{ this.state.userdata.fields.name }</span>
                </div>
                <div>
                    { this.state.userdata.fields.adress }
                </div>
                <div>
                    { this.state.userdata.fields.zipcode } { this.state.userdata.fields.city }
                </div>
                <div>
                    <b>Telefon: </b>
                    <span>
                    { this.state.userdata.fields.phone }
                </span>
                </div>
                <div>
                    <b>E-Mail: </b>
                    <span>
                    { this.state.userdata.fields.email }
                </span>
                </div>
            </div>
        );
    }

    render() {
        let computedTotalPrice = this.props.totalPrice.toFixed( 2 )
            .replace( '.', ',' );

        return (
            <div className="container">
                <div className="checkout-confirmation-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <h2>BESTELLÜBERSICHT</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-4 col-md-4 col-xl-4">
                            { this.mapUserData() }
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-xl-4 space-between-cards">
                            { this.contactBuilder() }
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-xl-4 space-between-cards">
                            <div className="border-card assembly-date">
                                <h4 className="text-highlight"> Ihr Wunschtermin </h4>
                                { this.dateHandler() }
                            </div>
                        </div>
                    </div>
                    <h4 className="text-highlight title-article-overview"> Ihre Artikel </h4>
                    <div className="row separator">
                        <div className="d-none d-sm-block col-md-3 col-xl-3"/>
                        <div className="d-none d-md-block col-8 col-sm-4 col-md-3 col-xl-3">
                            <h4>Artikel</h4>
                        </div>
                        <div className="col-2 d-none d-md-block col-sm-1 col-md-2 col-xl-2">
                            <h4>Stückpreis</h4>
                        </div>
                        <div className="col-2 d-none d-md-block col-sm-2 col-md-2 col-xl-2 text-center">
                            <h4>Anzahl</h4>
                        </div>
                        <div className="col-4 d-none d-md-block col-sm-4 col-md-2 col-xl-2 text-right">
                            <h4>Summe</h4>
                        </div>
                    </div>
                    <ShoppingCartTyres
                        shoppingCartTyres={ this.props.sctyres }
                        deletable={ false }
                        key={ new Date() }
                        visualizationType="orderOverview"
                    />
                    <div className="row spacing-top">
                        <div className="col-8 col-sm-7 col-md-7 col-xl-7">
                            <h4 className="total-price text-highlight"> Ihr Gesamtpreis (inkl. Mwst.)</h4>
                        </div>
                        <div className="col-4 col-sm-5 col-md-5 col-xl-5">
                            <TotalPrice/>
                        </div>
                    </div>
                    <div className="order-button">
                        <button className={ 'btn btn-primary' }
                                onClick={ () => this.props.callBackFromParent( this.state ) }
                        >
                            Jetzt kostenpflichtig bestellen!
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        totalPrice: state.shoppingcart.totalPrice,
        sctyres: state.shoppingcart.shoppingCartTyres

    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( CheckoutConfirmation );
