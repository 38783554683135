import React from 'react';

class TyreLabel extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            rollingResistance: '',
            wetGrip: '',
            noiseEmission: ''
        };
    }

    render() {
        return (
            <div className={ 'tyrelabel-wrapper' }>
                <span className="icon-tanksaule_ausgefuellt icon-spec"/>
                <span className={ 'text-pos' }> { this.props.rollingResistance } </span>

                <span className="icon-regen_augefuellt icon-spec"/>
                <span className={ 'text-pos' }> { this.props.wetGrip } </span>

                <span className="icon-lautstaerke_ausgefuellt icon-spec"/>
                <span className={ 'text-pos' }> { this.props.noiseEmission } dB </span>
            </div>
        );
    }
}

export default TyreLabel;
