import React from 'react';
import {connect} from 'react-redux';
import {getCarIdByHsnTsn} from '../../../../../redux-store/api';

class CarByHsnTsn extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         fullscreen: false,
         selecthsntsnClass: 'col-sm-12',
         certificateClass: 'd-none',
         markerActive: ''
      };
      this.callbackToParent = (e) => {
         this.props.callbackFunction(e);
      };
   }

   showCertificate() {
      if (this.state.fullscreen) {
         let _state = this.state;
         _state.selecthsntsnClass = 'col-sm-12';
         _state.certificateClass = 'd-none';
         _state.markerActive = '';

         this.setState(_state);
         this.props.middleCallback('d-none d-md-block');
         this.props.hsntsnSizeCallback('col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5');
         this.props.switchmanufacturercallback('d-block');
         this.props.ManualClassCallback('col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 order-xs-last');

      } else {
         let _state = this.state;
         _state.selecthsntsnClass = 'col-xs-12 col-sm-12 col-md-5 col-xl-4';
         _state.certificateClass = 'd-block';
         _state.markerActive = 'marker-active';

         this.setState(_state);
         this.props.middleCallback('d-none');
         this.props.hsntsnSizeCallback('col-md-12');
         this.props.switchmanufacturercallback('d-none');
         this.props.ManualClassCallback('d-none');
      }

      let _state = this.state;
      _state.fullscreen = !this.state.fullscreen;
      this.setState(_state);
   }

   componentDidMount() {
   }

   selectHsnTsn(e) {
      e.preventDefault();

      let hsn = document.getElementById('hsn').value;
      let tsn = document.getElementById('tsn').value;
      this.setState({loading: true});
      setTimeout(
         function () {
            getCarIdByHsnTsn({
               hsn: hsn,
               tsn: tsn
            })
            .then(
               (res) => {
                  if ((res.Error != '' && res.Error != null && res.Error != undefined) || (res.length == 0)) {
                     console.log('Error fetching id by hsntsn', res.Error);
                     this.setState({
                        error: res.Error,
                        loading: false
                     });
                     document.getElementById('hsn').value = '';
                     document.getElementById('hsn')
                     .classList
                     .add('apiError');
                     document.getElementById('tsn').value = '';
                     document.getElementById('tsn')
                     .classList
                     .add('apiError');
                     document.getElementById('hsn')
                     .focus();
                  } else if (!Array.isArray(res)) {
                     this.callbackToParent({id: res})
                  } else {
                     this.setState({
                        carType: res,
                        loading: false
                     });
                     this.callbackToParent({type: this.state.carType});
                  }
               }
            )
            .catch(error => {
               this.setState({
                  error: error,
                  loading: false
               });
            });
         }
         .bind(this),
         100
      );
   }

   changeHsn() {
      if (document.getElementById('hsn').value.length == 4) {
         document.getElementById('tsn').focus();
      }
   }

   render() {
      const content = (
         <div className="hsn-tsn-wrapper bg-milky">
            <div className="row">
               <div className={this.state.selecthsntsnClass} id="selecthsntsn">
                  <div className="innerBox scrollBox">
                     <div className="boxitem"><h3 className="boxheader">Über Ihre Fahrzeugpapiere</h3></div>
                     <form className="hsntsn">
                        <div className="row">
                           <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                              <div className="form-group">
                                 <label htmlFor="hsn" className="control-label">HSN</label>
                                 <small className="text-highlight">4-stellig</small>
                                 <input
                                    maxLength="4"
                                    type="text"
                                    placeholder=""
                                    className="form-control"
                                    id="hsn"
                                    onChange={this.changeHsn}
                                 />
                              </div>
                           </div>
                           <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
                              <div className="form-group">
                                 <label htmlFor="tsn" className="control-label">TSN/VSN</label>
                                 <small className="text-highlight">3- bis 10-stellig</small>
                                 <input
                                    maxLength="10"
                                    type="text"
                                    placeholder=""
                                    className="form-control"
                                    id="tsn"
                                 />
                              </div>
                           </div>
                           <div className="col-sm-12">
                              <button className="btn btn-block btn-primary" onClick={(e) => this.selectHsnTsn(e)}>
                                 Fahrzeug wählen
                              </button>
                           </div>
                        </div>
                     </form>
                     <div>
                        <div className="infoicon-text text-center">
                           <div>
                              <i className="fas fa-info-circle infoicon"></i>
                              Wo finde ich meine HSN, TSN und VSN?
                           </div>
                           <button href="#" className="btn btn-success" onClick={(e) => this.showCertificate(e)}>
                              {this.state.fullscreen ? 'Fahrzeugschein ausblenden' : 'Fahrzeugschein anzeigen'}
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
               <div className={this.state.certificateClass + ' col-xs-12 col-sm-12 col-md-12 col-xl-8'}>
                  <div className="certificate-image">
                     <div className={this.state.markerActive + ' markers first'}>HSN</div>
                     <div className={this.state.markerActive + ' markers second'}>TSN</div>
                  </div>
               </div>
            </div>
         </div>
      );
      return content;
   }
}

CarByHsnTsn.serverFetch = '';// tyreDetailActionSSR; // static declaration of data requirements

const mapStateToProps = state => ({});

const mapDispatchToProps = {
//    tyreDetailAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CarByHsnTsn);
