import React from 'react';
import {pointsCodeGenerator} from '../../../../../../redux-store/api';
import RimDetailsPDF from './rimDetails/RimDetailsPDF';
import RimDetailsTable from './rimDetails/RimDetailsTable';
import RimCardDetails from './rimCard/RimCardDetails';
import RimCardButtons from './rimCard/RimCardButtons';
import RimSpecifications from './rimDetails/RimSpecifications';
import * as actionCreators from '../../../../../../redux-store/actions';
import {connect} from 'react-redux';

let imageBase64,
   imagePath;

class Rim extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: 'Auswählen',
         selectedRim: this.props.rim,
         selectedProductId: (this.props.rim && this.props.rim.items) ? this.props.rim.items[0].id : null,
         selectedRearRimId: (this.props.rim && this.props.rim.items[0].rearId) ? this.props.rim.items[0].rearId : null,
         firstSize: 0,
         rimSize: this.props.rim ? this.props.rim.items[0].size : null,
         chosenItem: 0,
         pscClass: false,
         initialValues: true,
         rimimagebase64: null,
         rearSelected: false,
         hoverState: false,
         favRims: [],
         ar: []
      };
      this.baseState = this.state;
      this.toDataURL = url => fetch(url)
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.onloadend = () => resolve(reader.result);
         reader.onerror = reject;
         reader.readAsDataURL(blob);
      }));
      this.childSelected = (e) => {
         this.props.onChildSelected(e);
      };

      this.setRimWithSize = this.setRimWithSize.bind(this);
   }

   resetState() {
      this.setState(this.baseState);
   }

   selectRimCallback(rim) {
      this.props.onChildSelected(rim);
      this.props.rimSizeCallBack(this.state.rimSize);
      this.childSelected(rim);
   }

   getCodeString() {
      if (this.state.data == 'Auswählen') {
         return this.state.data;
      } else {
         let codeName;
         switch (process.env.REACT_APP_CLUB) {
            case 'points':
            case 'ps-rims':
               codeName = 'Point-S-Code';
               break;
            case 'automeister':
            case 'am-rims':
               codeName = 'Automeister-Code';
               break;
            default:
               codeName = 'Code';
               break;
         }
         return 'Ihr ' + codeName + ': ' + this.state.data;
      }
   }

   createPointsCode() {
      if (this.state.pscClass == false) {
         let pointsCode = {
            productId: this.state.selectedProductId,
            rearRimId: this.state.selectedRearRimId,
            carId: this.props.carId
         };
         this.setState({loading: true});
         pointsCodeGenerator(pointsCode)
         .then(
            (res) => {
               if (res.Error != '' && res.Error != null && res.Error != undefined) {
                  console.error('Error fetching pointscode', res.Error);
                  this.setState({
                     error: res.Error
                  });
               } else {
                  this.setState({
                     data: res,
                     pscClass: true
                  });
               }
            }
         )
         .catch(error => {
            this.setState({
               error: error
            });
         });
      }
   }

   rimSizeHandler(rim) {
      let ar = [];
      let obj = {};
      let x = 1;
      for (let sizes of rim.items) {
         if (!obj[sizes.size]) {
            obj[sizes.size] = sizes.size;
         }
      }
      for (let sizes of Object.keys(obj)) {
         ar.push(
            <div key={sizes}
                 className={'rim-size' + (this.state.rimSize == sizes ? ' selected' : '')}
                 onClick={() => this.rimSizeClickHandler(sizes)}>
               {sizes}
            </div>
         );
         x++;
      }
      return ar;
   }

   rimSizeClickHandler(size) {
      this.setState({rimSize: size});
      this.props.setRim();
      this.props.rimSizeCallBack(size);
   }

   setRimWithSize() {
      this.props.setRim();
      this.props.rimSizeCallBack(this.state.rimSize);
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.filterSelections && this.props.filterSelections.RIMSIZE && (this.props.filterSelections.RIMSIZE != this.state.rimSize)) {
         if (!prevProps.filterSelections || (prevProps.filterSelections && (prevProps.filterSelections.RIMSIZE != this.props.filterSelections.RIMSIZE)) && this.props.filterSelections.RIMSIZE[0]) {
            this.setState({rimSize: this.props.filterSelections.RIMSIZE[0]})
         }
      }
      if (this.state.rimSize != prevState.rimSize || this.props.totalCount != prevProps.totalCount) {
         let ar = this.rimSizeHandler(this.props.rim);
         this.setState({ar});
      }

      if (!this.props.isDetailView && prevState.rimSize != this.state.rimSize) {
         let chosenItem = this.state.chosenItem;
         for (let i = 0; i < this.state.selectedRim.items.length; i++) {
            if (this.state.selectedRim.items[i].size == parseInt(this.state.rimSize)) {
               chosenItem = i;
               i = 1000000;
            }
         }
         if (!this.state.selectedRim.items[chosenItem]) {
            chosenItem = 0;
         }
         if (this.state.selectedRim.items[chosenItem] && this.state.selectedRim.items[chosenItem].status)
            this.setState({
               chosenItem,
               selectedProductId: (this.props.rim && this.props.rim.items[chosenItem]) ? this.props.rim.items[chosenItem].id : 0,
               selectedRearRimId: (this.props.rim && this.props.rim.items[chosenItem].rearId) ? this.props.rim.items[chosenItem].rearId : null
            });
      }
      if ((!this.state.rimSize && this.props.rimSize) || (this.props.isFavoriteView && this.state.rimSize != this.props.rim.items[0].size)) {
         this.setState({rimSize: this.props.rim.items[0].size})
      }
   }

   componentDidMount() {
      this.props.rim.imagePerspectiveUrl = this.props.rim.imagePerspectiveUrl ?
         this.props.rim.imagePerspectiveUrl.split('www.eios.eu')[1] ?
            this.props.rim.imagePerspectiveUrl.split('www.eios.eu')[1] :
            this.props.rim.imagePerspectiveUrl.split('www.eios.eu')[0] :
         this.props.rim.imageFrontUrl.split('www.eios.eu')[1];
      let ar = this.rimSizeHandler(this.props.rim);
      this.setState({ar, favRims: this.props.favRims});
      if (this.state.selectedRim && this.state.selectedRim.imagePerspectiveUrl) {
         const imagePath = '/rimconfigurator/images/' + this.state.selectedRim.imagePerspectiveUrl.split('/rimconfigurator/')[1];
         this.toDataURL(imagePath)
         .then(dataUrl => {
            imageBase64 = dataUrl;
            this.setState({
               rimimagebase64: dataUrl
            });
         });
      }
   }

   specificationHandler(rim) {
      let returnArray = [];
      if (this.state.data == 'Auswählen') {
         let x = 0;
         for (let sizes of rim.items) {
            returnArray.push(
               <div key={(sizes.size + sizes.width + sizes.offset + rim.boltPattern + sizes.rimId + sizes.rearRimId)}
                    onClick={this.specificationClicked.bind(this, x, true, sizes.id, sizes.rearId)}>
                  <RimSpecifications sizes={sizes}
                                     rim={rim}
                                     chosenItem={this.state.chosenItem}
                                     x={x}
                                     selected={false}/>
               </div>
            );
            x++;
         }
      } else {
         returnArray.push(
            <RimSpecifications rim={rim}
                               key={this.state.chosenItem + this.state.selectedRim.items[this.state.chosenItem].rearRimId}
                               selected={true}
                               chosenItem={this.state.chosenItem}/>
         );
         returnArray.push(
            <button key={'resetButton'} onClick={this.resetState.bind(this)} className={'btn btn-outline-primary'}>
               Auswahl ändern
            </button>
         );
      }
      return returnArray;
   }

   specificationClicked(x, rear, rimId, rearRimId) {
      this.setState({
         initialValues: false,
         chosenItem: x,
         pscClass: false,
         rearSelected: rear,
         selectedRimId: rimId,
         selectedProductId: rimId,
         selectedRearRimId: rearRimId
      });
   }

   focusCallBack(e) {
      this.setState({hoverState: e});
   }

   favoriteCallBack(rim) {
      let favRims = {};
      if (!this.props.favRims[this.props.carId]) {
         favRims[this.props.carId] = [];
      } else {
         favRims[this.props.carId] = [...this.props.favRims[this.props.carId]];
      }
      let rimIds = [];
      if (favRims[this.props.carId] && favRims[this.props.carId][0] && favRims[this.props.carId][0].id) {
         for (let x of favRims[this.props.carId]) {
            rimIds.push(x.id)
         }
      }
      if (rimIds && rimIds.includes(rim.id)) {
         favRims[this.props.carId] = favRims[this.props.carId].filter(rimObject => rimObject.id != rim.id)
      } else favRims[this.props.carId].push(rim)
      this.props.onFavRimSelected(favRims);
   }

   render() {
      let rim = this.props.rim;

      let retailPriceForFour = undefined;
      if (rim.items[this.state.chosenItem] && rim.items[this.state.chosenItem].retailPrice) {
         retailPriceForFour = rim.items[this.state.chosenItem].retailPrice * 4;
      }
      let rearRetailPriceForFour = undefined;
      if (rim.items[this.state.chosenItem] && rim.items[this.state.chosenItem].rearRetailPrice) {
         rearRetailPriceForFour = rim.items[this.state.chosenItem].rearRetailPrice * 4;
      }

      let active = this.props.activeRim === rim.id;
      let visible = (this.props.activeRim === null || active);
      if (rim.completeWheel === true) {
         return false;
      } else {
         var formatter = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
         });
         return (
            <div id={rim.id} key={rim.id}
                 className={(active ? 'rim active ' : 'rim preview ') + ((this.props.selectedRim == rim.id) ? 'selected' : '') + (visible ? '' : 'd-none')}>
               <div className={'row'}>
                  {/*----------------------------  Suchergebnisse  ----------------------------*/}
                  <div
                     className={(active ? 'col-12 col-xs-3 col-sm-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 detail' : 'col-12 ')}>
                     <div>
                        <RimCardDetails visible={visible}
                                        active={active}
                                        rim={rim} carId={this.props.carId}
                                        hoverState={this.state.hoverState} setRim={this.setRimWithSize}
                                        focusCallBack={this.focusCallBack.bind(this)}
                                        favoriteCallBack={this.favoriteCallBack.bind(this)}
                                        chosenItem={this.state.chosenItem}
                                        favRims={this.props.favRims}
                                        rimSize={this.state.rimSize}/>
                     </div>
                     <RimCardButtons ar={this.state.ar}
                                     active={active}
                                     visible={visible}
                                     rimSize={this.state.rimSize}
                                     rim={rim}
                                     loadingRims={this.props.loadingRims}
                                     goToDetail={() => this.props.goToDetail()}/>
                  </div>

                  {/*----------------------------  Details  ----------------------------*/}
                  <RimDetailsTable rim={rim}
                                   rearSelected={this.state.rearSelected}
                                   chosenItem={this.state.chosenItem}
                                   active={active}
                                   hoverState={this.state.hoverState}
                                   visible={visible}/>
                  <div className={'d-block d-lg-none col-3'}/>
                  <div className={'col-12 col-xs-12 col-sm-12 col-md-9 col-lg-4 ' + (active ? 'active' : 'nosize')}
                       style={{
                          'display': active && visible ? 'inherit' : 'none',
                          'height': active && visible ? 'inherit' : '0'
                       }}>
                     <div className={'wrapped'}>
                        <div className={'specification-wrapper'}>
                           <div className={'specifications'}>
                              {this.specificationHandler(rim)}
                           </div>
                        </div>
                        <div className={'text-highlight text-right row'}>
                           <div className={'col'}>
                              <span className={'price-span'}>
                                 {rim.items[this.state.chosenItem] && rim.items[this.state.chosenItem].rearRetailPrice ?
                                    <img src={'/auto_v.svg'} className={'axis front'}/> :
                                    <img src={'/auto_hv.svg'} className={'axis front'}/>}
                                 <b className="price">
                                    {rim.items[this.state.chosenItem] && rim.items[this.state.chosenItem].retailPrice ?
                                       formatter.format(rim.items[this.state.chosenItem].retailPrice) : ''}
                                 </b>
                                 <div>pro Stück inkl. MwSt.</div>
                                 {retailPriceForFour ?
                                    <div className="price-for-four">4
                                       Stück <b>{formatter.format(retailPriceForFour)}</b></div>
                                    : ''}
                              </span>
                           </div>
                           {rim.items[this.state.chosenItem] && rim.items[this.state.chosenItem].rearRetailPrice ?
                              <div className={'col'}>
                                    <span className={'price-span'}>
                                       <img src={'/auto_h.svg'} className={'axis'}/>
                                       <b className="price">
                                          {formatter.format(rim.items[this.state.chosenItem].rearRetailPrice)}
                                       </b>
                                       <div>pro Stück inkl. MwSt.</div>
                                       {rearRetailPriceForFour ?
                                          <div className="price-for-four">4
                                             Stück <b>{formatter.format(rearRetailPriceForFour)}</b></div>
                                          : ''}
                                    </span>
                              </div>
                              : ''
                           }
                        </div>
                        <hr/>
                        <button onClick={() => this.createPointsCode()}
                                className={!this.state.pscClass ? 'btn btn-primary mb-3' : 'btn btn-success cursor-default mb-3'}>
                           {this.getCodeString()}
                        </button>
                        {this.state.data !== 'Auswählen' ?
                           <RimDetailsPDF data={this.state.data}
                                          car={this.props.car}
                                          carImage={this.props.carImage}
                                          pointsCodeHandler={this.state.data}
                                          selectedRim={this.state.selectedRim}
                                          base64img={this.props.base64img}
                                          chosenItem={this.state.chosenItem}/>
                           :
                           ''
                        }
                     </div>
                  </div>
               </div>
            </div>
         );
      }
   }
}

const mapStateToProps = state => {
   return {
      favRims: state.favRims
   };
};

const mapDispatchToProps = dispatch => {
   return {
      onFavRimSelected: favRims => dispatch(actionCreators.saveFavoriteRims(favRims))
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rim);
