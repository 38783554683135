import React from 'react';

let buttonContent;

class RimSpecifications extends React.Component {
    constructor( props ) {
        super( props );
    }

    buttonTextHandler() {
        let sizes = this.props.sizes;
        let rim = this.props.rim;
        let x = this.props.x;
        let chosenItem = this.props.chosenItem;
        let item = rim.items[chosenItem];
        if (this.props.selected === false) {
            if (sizes.rearRimId) {
                return (
                    <div className={ 'mixed' }>
                        <button className={ ( ( chosenItem === x ) ? 'btn round-button btn-primary' : 'btn round-button btn-outline-primary' ) + ' position-relative' }>
                            <div className={ ( ( chosenItem === x ) ? 'axis-front white' : 'axis-front' ) + ' text-left' }>
                                { sizes.width }x{ sizes.size } ET: { sizes.offset } LK: { rim.boltPattern }
                            </div>
                        </button>
                        <button className={ ( ( chosenItem === x ) ? 'btn round-button btn-primary' : 'btn round-button btn-outline-primary' ) + ' position-relative' }>
                            <div className={ ( ( chosenItem === x ) ? 'axis-behind white' : 'axis-behind' ) + ' text-left' }>
                                { sizes.rearWidth }x{ sizes.rearSize } ET: { sizes.rearOffset } LK: { rim.boltPattern }
                            </div>
                        </button>
                    </div>
                );
            } else {
                return (
                    <button className={ ( ( chosenItem === x ) ? 'btn round-button btn-primary' : 'btn round-button btn-outline-primary' ) + ' position-relative' }>
                        <div className={ ( ( chosenItem === x ) ? 'axis-both white' : 'axis-both' ) + ' text-left' }>
                            { sizes.width }x{ sizes.size } ET: { sizes.offset } LK: { rim.boltPattern }
                        </div>
                    </button>
                );
            }
        } else {
            return (
                <div>
                    <button className={ 'btn btn-primary position-relative' }>
                        <div className={ 'axis-front white text-left' }>
                            { item.width }x{ item.size } ET: { item.offset } LK: { rim.boltPattern }
                        </div>
                    </button>
                    { item.rearWidth ?
                        <button className={ 'btn btn-primary position-relative' }>
                            <div className={ 'axis-behind white text-left' }>
                                { item.rearWidth }x{ item.rearSize } ET: { item.rearOffset } LK: { rim.boltPattern }
                            </div>
                        </button>
                        :
                        ''
                    }
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                { this.buttonTextHandler() }
            </div>
        );
    }
}

export default ( RimSpecifications );
