import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

class TotalPrice extends React.Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   render() {
      let preDecimal = 0;
      let decimal = 0;

      // -------------------  price of a single piece ----------------------------
      if (this.props.price !== undefined) {
         let price = (this.props.price.toFixed(2));
         if (price > 0) {
            preDecimal = price.split('.')[0];
            decimal = price.split('.')[1];
         }
      }
      // -------------------  final price of all items ------------------------
      else if (this.props.totalPrice !== undefined) {
         let computedMinPrice = (this.props.totalPrice ? this.props.totalPrice.toFixed(2) : '0.00');
         if (computedMinPrice > 0) {
            preDecimal = computedMinPrice.split('.')[0];
            decimal = computedMinPrice.split('.')[1];
         }
      }

      if (decimal == '00' || decimal === 0) {
         decimal = '-';
      }
      var formatter = new Intl.NumberFormat('de-DE', {
         minimumFractionDigits: 2,
         maximumFractionDigits: 2,
         style: 'currency',
         currency: 'EUR'
      });
      return (
         <div className={'price-wrapper cart-pos ' + this.props.ownClass}>
            <span className='single-price'>{preDecimal ? preDecimal + ',' : ""}
               <span className={decimal !== '-' ? 'price-after-comma' : ''}>{preDecimal ? decimal + ' €' : ""} </span>
            </span>

            <div className={preDecimal ? "d-none" : ""}>
                <span className={'no-price-given'}>{preDecimal ? '' : 'Preis auf Anfrage'}<br/>
                    <small><Link className={'btn btn-success'}
                                 to={'/contact'}>{preDecimal ? '' : 'zur Kontaktseite'}</Link></small></span>
            </div>
            {(((this.props.ownClass === 'result') || (this.props.ownClass === 'detail')) && preDecimal) ?
               <div className='caption'>
                  <div>pro Stück inkl. Mwst.</div>
                  <div>
                     <i className='fas fa-check'/>sofort lieferbar
                  </div>
                  <div className="price-for-four">4 Stück <b>{formatter.format(this.props.price * 4)}</b></div>
               </div>
               : ''
            }
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      totalPrice: state.shoppingcart.totalPrice
   };
};

const mapDispatchToProps = dispatch => {
   return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TotalPrice);

