import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import routes from '../../routes';
import Header from './Header';
import Footer from './Footer';
import Cookies from './components/cookies';
import * as actionCreators from '../../../redux-store/actions';
import { connect } from 'react-redux';
import Breadcrumb from "../../../modules/Rimconfigurator/pages/shared/breadcrumb/Breadcrumb";
import {activeShop} from '../../../redux-store/api';

class Layout extends React.Component {
    constructor() {
        super();
        this.state = {
            loc: '',
           activeShop:false,
           loading:true
        };
    }

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            console.clear();
        }
       activeShop(this.props.firmData[0].Id).then(
          res=>{
             this.setState({activeShop:res,loading:false})
          }
       )
    }

    render() {
        return (
            <div>
                { this.props.firmData ?
                    <Cookies/>
                    :
                    <div/>
                }
                <Route render={ ( { location } ) => {
                        this.state.loc = location.pathname;
                        const { pathname } = location;
                        return (
                            <div className="bodywrapper">
                                <TransitionGroup>
                                    <CSSTransition
                                        key={ pathname }
                                        classNames="page"
                                        timeout={ {
                                            enter: 1000,
                                            exit: 0
                                        } }>
                                        <Route
                                            location={ location }
                                            render={ () => (
                                                <div>
                                                    <Header loc={ pathname }/>
                                                   {location.pathname.includes('/rimconfigurator')?<Breadcrumb path={ location.pathname }/>:""}
                                                   { this.props.firmData && this.state.activeShop  ?
                                                        <div className="bodywrapper">
                                                            <Switch>
                                                                { routes.map( route => (
                                                                    <Route key={ route.path } { ...route } />
                                                                ) ) }
                                                            </Switch>
                                                        </div>
                                                        :
                                                      this.state.loading ?
                                                         <div className={'loading-circle'}>
                                                         </div>
                                                         :
                                                        <div className={ 'container text-center errorimg' }>
                                                            <img src="/images/errorpage.png"
                                                                 className="img-fluid"
                                                            />
                                                            <h1> Leider ist der ausgewählte Händler zurzeit nicht verfügbar...</h1>
                                                        </div>
                                                    }

                                                </div>
                                            ) }
                                        />
                                    </CSSTransition>
                                </TransitionGroup>
                            </div>
                        );
                    } }
                />
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = state => ( {
    firmData: state.firmData
} );
export default connect( mapStateToProps )( Layout );
