import React from 'react';

let openingTimes;
let ii = 0;
let arr2 = [];
let DateObject = [];
let editedOpeningTimes = [];
let SamstagOpeningHours = '';
const openingTimesHandler = ( props ) => {
    if (props.data && props.data[0] && props.data[0].openingTimes) {
        openingTimes = props.data[props.vendorNo].openingTimes.split( '\n' );
        openingTimes.shift();
        for (let i = 0; i < openingTimes.length; i++) {
            editedOpeningTimes = openingTimes[i].toString()
                .split( ' ' );
            DateObject[i] = {
                date: editedOpeningTimes[0],
                time1: editedOpeningTimes[1],
                time2: editedOpeningTimes[2]
            };
            if (DateObject [i].date === 'Sa') {
                SamstagOpeningHours = DateObject [i].time1;
            }
            arr2[i] =
                ( <div key={ DateObject[i].date }>

                    { DateObject[i].time1 } { DateObject[i].time2 } <br/>
                </div> );
        }

        // return arr2;
        return (
            <div>
                <span className="openingtimes-info-text-bold">
                    { 'Mo.-Fr. ' }
                </span>
                <span className="openingtimes-info-text-css"> { arr2[0] }</span>
                <span className="openingtimes-info-text-bold">
                    { 'Sa. ' }
                </span>
                <span className="space-openingtimes-info"> { ' ' }</span>
                <span className="openingtimes-info-text">
                    { SamstagOpeningHours }
                </span>
            </div>
        );
    } else {
        return (
            <span/>
        );
    }
};

export default openingTimesHandler;
