import React from 'react';
import { Link } from 'react-router-dom';

const ShoppingCartEmpty = () => {
    return (
        <div className="sc-empty-wrapper text-center">
            <h3 className="text-center">
                Derzeit befinden sich keine Artikel in Ihrem Warenkorb.
                <br/>
                <small>
                    Um passende Reifen zu finden nutzen Sie doch unsere bequeme
                    Reifensuche:
                </small>
            </h3>
            <div>
                <Link to={ '/' }>
                    <img src="/images/points_schematisch.svg"
                         className="img-fluid max-size"
                    />
                </Link>
            </div>
            <div>
                <Link className="btn btn-primary" to={ '/' }>
                    Zur Suche
                </Link>
            </div>
        </div>
    );
};

export default ShoppingCartEmpty;
