import fetch from "isomorphic-fetch";

let requestLoad;

/*
    selectedTyre Search
    requestBody contains request params
 */
export function getTyres(reqBody, env) {
   let environment = env;
   let requestBody = JSON.parse(reqBody);
   requestBody.env = environment;
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/tyre/search`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(requestBody)
   }).then(res => res.json()).then(res => {
      let newres = [];
      let resItem = res;
      if (resItem.filters) {
         res = resItem.tires;
      }
      for (let items of res) {
         if (items && items.productId) {
            let TireDTO = {
               "productId": items.productId,
               "description": items.description,
               "speedIndex": items.speedIndex,
               "loadIndex": items.loadIndex,
               "tread": items.tread,
               "rft": items.rft,
               "brand": items.brand,
               "ean": items.ean,
               "ipc": items.ipc,
               "totalAmount": items.totalAmount,
               "productImageUrl": items.productImageUrl,
               "vehicleTypes": items.vehicleTypes,
               "seasons": items.seasons,
               "quality": items.quality,
               "B2CPrice": items.B2CPrice,
               "tyreLabel": items.tyreLabel
            }

            newres.push(TireDTO);
         } else {
            newres.push(items);
         }
      }

      return newres;
   });

}

/*
    TODO: remove
    use Detail call
 */

/*
    Get one selectedTyre by ID
 */
export function getTyreDetails(params) {
   const url = `${process.env.HOST ? process.env.HOST : ""}/api/tyre/detail/`;
   return fetch(url + params.id).then(res => res.json().then(res => {
      let newres = {};
      if (res.productId) {
         let TireDTO = {
            "productId": res.productId,
            "description": res.description,
            "speedIndex": res.speedIndex,
            "loadIndex": res.loadIndex,
            "tread": res.tread,
            "rft": res.rft,
            "brand": res.brand,
            "ean": res.ean,
            "ipc": res.ipc,
            "totalAmount": res.totalAmount,
            "productImageUrl": res.productImageUrl,
            "vehicleTypes": res.vehicleTypes,
            "seasons": res.seasons,
            "quality": res.quality,
            "B2CPrice": res.B2CPrice,
            "tyreLabel": res.tyreLabel
         }
         newres = TireDTO;
      }
      return newres;
   }));
}

/*
    Get TyreSizes Async
    Reload if season is changes by CS Rendering
 */
export function getTyreSizes() {
   try {
      return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/tyre/sizes`)
      .then(res => res.json())
      .then(res => res);
   } catch (err) {
   }
}

export function sendOrder(order) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/postOrder`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: order
   })
   .then(function (response) {
      return response.json();
   })
   .catch(function (err) {
      return String(err);
   });
}

export function getCacheFolder() {
   const url = `${process.env.HOST ? process.env.HOST : ""}/api/getCacheFolder`;
   return fetch(url).then(res => res.json()).then(res => res);
}

/*
    Get one selectedTyre by ID
 */
// export function getCarModels(params) {
//   const url = `${process.env.HOST ? process.env.HOST : ""}/api/rimconfigurator/car/`;
// return fetch(url + params.id).then(res => res.json());
// }

/*
   Rim Search
   RequestBody contains request params
*/

export function getCarModels(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getCarModels`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(reqBody)
   }).then(res => res.json());
}

export function getManufacturers(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getManufacturers`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(reqBody)
   }).then(res => res.json());
}

export function getClasses(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getClasses`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

export function getTypes(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getTypes`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

export function getCarIdByHsnTsn(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getCarIdByHsnTsn`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

export function getRimDetails(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getRimDetails`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

export function getCarDetails(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/getCarDetails`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify((reqBody ? reqBody : {}))
   }).then(res => res.json());
}

/*
   Rim Search
   RequestBody contains request params
*/
export function getRims(reqBody) {
   if (requestLoad != reqBody) {
      let newRequestBody = {
         'limit': reqBody.limit,
         'offset': reqBody.offset,
         'filter': reqBody.filterSelection,
         'carId': reqBody.carId
      }
      requestLoad = reqBody;
      return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/searchRims`, {
         method: "post",
         headers: {Accept: "application/json", "Content-Type": "application/json"},
         body: JSON.stringify(newRequestBody)
      }).then(res => res.json());
   } else return false;
}

export function getRatio(width) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/ratios/${width}`, {
      method: "get",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
   }).then(res => res.json());
}

export function getDiameter(width, ratio) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/diameter/${width}/${ratio}`, {
      method: "get",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
   }).then(res => res.json());
}

export function activeShop(debitor) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/activeShop`, {
      method: "post",
      body: JSON.stringify({debitor}),
      headers: {Accept: "application/json", "Content-Type": "application/json"}
   }).then(res => res.json());
}


/*
   Rim Search
   RequestBody contains request params
*/
export function pointsCodeGenerator(reqBody) {
   return fetch(`${process.env.HOST ? process.env.HOST : ""}/api/rim/wishlist`, {
      method: "post",
      headers: {Accept: "application/json", "Content-Type": "application/json"},
      body: JSON.stringify(reqBody)
   })
   .then(function (response) {
      return response.json();
   })
   .catch(function (err) {
      return String(err);
   });
}


export function getAssemblyCosts(productId, debitorNo) {
   const url = `${process.env.HOST ? process.env.HOST : ""}/api/assemblycosts?productId=${productId}&debitorNo=${debitorNo}`;
   return fetch(url).then(res => res.json()).then(res => res);
}
