import * as actionTypes from "../actions/actionTypes";

const initialState = {
   from: "",
   to: "",
   filter: false,
   sortValue: "Preis aufsteigend",
   sortValueJson: {sortDir: "ASC", sortField: "minPrice"},
   changeFlag: false,
   id: "",
   // temp brand
   values: [],
   // brand options
   options: [
      {value: "Accelera", label: "Accelera"},
      {value: "Achilles", label: "Achilles"},
      {value: "Antares", label: "Antares"},
      {value: "Aplus", label: "Aplus"},
      {value: "Apollo", label: "Apollo"},
      {value: "Aptany", label: "Aptany"},
      {value: "Austone", label: "Austone"},
      {value: "Avon", label: "Avon"},
      {value: "Barum", label: "Barum"},
      {value: "Berlin Tires", label: "Berlin Tires"},
      {value: "Bf-goodrich", label: "Bf-goodrich"},
      {value: "Blacklion", label: "Blacklion"},
      {value: "Bridgestone", label: "Bridgestone"},
      {value: "Continental", label: "Continental"},
      {value: "Cooper", label: "Cooper"},
      {value: "Cst", label: "Cst"},
      {value: "Dayton", label: "Dayton"},
      {value: "Debica", label: "Debica"},
      {value: "Delinte", label: "Delinte"},
      {value: "Dayton", label: "Dayton"},
      {value: "Double Coin", label: "Double Coin"},
      {value: "Dunlop", label: "Dunlop"},
      {value: "Duraturn", label: "Duraturn"},
      {value: "Event", label: "Event"},
      {value: "Falken", label: "Falken"},
      {value: "Firestone", label: "Firestone"},
      {value: "Fortuna", label: "Fortuna"},
      {value: "Fortune", label: "Fortune"},
      {value: "Fulda", label: "Fulda"},
      {value: "General Tire", label: "General Tire"},
      {value: "Gislaved", label: "Gislaved"},
      {value: "Goldline", label: "Goldline"},
      {value: "Goodride", label: "Goodride"},
      {value: "Goodyear", label: "Goodyear"},
      {value: "Gt-radial", label: "Gt-radial"},
      {value: "Habilead", label: "Habilead"},
      {value: "Hankook", label: "Hankook"},
      {value: "Hifly", label: "Hifly"},
      {value: "Imperial", label: "Imperial"},
      {value: "Infinity", label: "Infinity"},
      {value: "Kenda", label: "Kenda"},
      {value: "King Meiler", label: "King Meiler"},
      {value: "Kingstar", label: "Kingstar"},
      {value: "Kleber", label: "Kleber"},
      {value: "Kormoran", label: "Kormoran"},
      {value: "Kumho", label: "Kumho"},
      {value: "Landsail", label: "Landsail"},
      {value: "Lanvigator", label: "Lanvigator"},
      {value: "Laufenn", label: "Laufenn"},
      {value: "Leao", label: "Leao"},
      {value: "Linglong", label: "Linglong"},
      {value: "Mabor", label: "Mabor"},
      {value: "Mastersteel", label: "Mastersteel"},
      {value: "Matador", label: "Matador"},
      {value: "Maxtrek", label: "Maxtrek"},
      {value: "Maxxis", label: "Maxxis"},
      {value: "Michelin", label: "Michelin"},
      {value: "Milestone", label: "Milestone"},
      {value: "Minerva", label: "Minerva"},
      {value: "Momo Tire", label: "Momo Tire"},
      {value: "Nankang", label: "Nankang"},
      {value: "Nexen", label: "Nexen"},
      {value: "Nokian", label: "Nokian"},
      {value: "Nordexx", label: "Nordexx"},
      {value: "Novex", label: "Novex"},
      {value: "Ovation", label: "Ovation"},
      {value: "Petlas", label: "Petlas"},
      {value: "Pirelli", label: "Pirelli"},
      {value: "Platin Reifen", label: "Platin Reifen"},
      {value: "Powertrac", label: "Powertrac"},
      {value: "Points 4 Seasons", label: "Points 4 Seasons"},
      {value: "Radar", label: "Radar"},
      {value: "Riken", label: "Riken"},
      {value: "Sailun", label: "Sailun"},
      {value: "Sava", label: "Sava"},
      {value: "Sebring", label: "Sebring"},
      {value: "Seiberling", label: "Seiberling"},
      {value: "Semperit", label: "Semperit"},
      {value: "Starfire", label: "Starfire"},
      {value: "Sumitomo", label: "Sumitomo"},
      {value: "Summerstar", label: "Summerstar"},
      {value: "Sunny", label: "Sunny"},
      {value: "Superia", label: "Superia"},
      {value: "Syron", label: "Syron"},
      {value: "T-tyre", label: "T-tyre"},
      {value: "Taurus", label: "Taurus"},
      {value: "Tecnica", label: "Tecnica"},
      {value: "Tigar", label: "Tigar"},
      {value: "Tomket", label: "Tomket"},
      {value: "Torque", label: "Torque"},
      {value: "Tourador", label: "Tourador"},
      {value: "Toyo", label: "Toyo"},
      {value: "Tracmax", label: "Tracmax"},
      {value: "Triangle", label: "Triangle"},
      {value: "Tristar", label: "Tristar"},
      {value: "Tyfoon", label: "Tyfoon"},
      {value: "Uniroyal", label: "Uniroyal"},
      {value: "Viking", label: "Viking"},
      {value: "Vredestein", label: "Vredestein"},
      {value: "Wanli", label: "Wanli"},
      {value: "Westlake", label: "Westlake"},
      {value: "Winterstar", label: "Winterstar"},
      {value: "Yokohama", label: "Yokohama"},
      {value: "Zeetex", label: "Zeetex"},
      {value: "Ztyre", label: "Ztyre"}
   ],
   season: "summer",
   vehicleType: "pkw",
   speed: "",
   load: "",
   length: "205",
   height: "55",
   diameter: "16",
   // selected brand values mit label and value
   bValues: [],
   // test last bValues before resetFilterSelections
   lastBValues: [],
   // new selected brand values
   brandValues: [],
   //rft
   vals: [],
   // dot/demo
   dotValues: [],
   // noise emission
   nEValues: [66, 75],
   // wet grip
   wGValues: [1, 6],
   finalWGValues: ["A", "F"],
   // rolling resistance
   rRValues: [1, 7],
   finalRRValues: ["A", "G"],
   filterSelections: [
      {id: "BRAND", values: []},
      {id: "DOT_DEMO", values: []},
      {id: "RFT", values: []},
      {id: "NOISE_EMISSION", values: []},
      {id: "WET_GRIP", values: ["A", "F"]},
      {id: "ROLLING_RESISTANCE", values: ["A", "G"]}
   ],
   url: "/searchresult/eyJsZW5ndGgiOiIyMDUiLCJoZWlnaHQiOiI1NSIsImRpYW1ldGVyIjoiMTYiLCJzZWFzb24iOiJzdW1tZXIiLCJzcGVlZCI6IiIsImxvYWQiOiIiLCJ2ZWhpY2xlVHlwZSI6InBrdyIsImZpbHRlclZhbHVlcyI6W3siaWQiOiJCUkFORCIsInZhbHVlcyI6W119LHsiaWQiOiJET1RfREVNTyIsInZhbHVlcyI6W119LHsiaWQiOiJSRlQiLCJ2YWx1ZXMiOltdfSx7ImlkIjoiTk9JU0VfRU1JU1NJT04iLCJ2YWx1ZXMiOltdfSx7ImlkIjoiV0VUX0dSSVAiLCJ2YWx1ZXMiOlsiQSIsIkYiXX0seyJpZCI6IlJPTExJTkdfUkVTSVNUQU5DRSIsInZhbHVlcyI6WyJBIiwiRyJdfV19"
};

const reducer = (state, action, filterValues) => {
   state = state || initialState;
   // test
   let _loading = state.loading;
   let _errorMessage = state.errorMessage;
   // end test
   let _from = state.from;
   let _to = state.to;
   let _season = state.season;
   let _vehicleType = state.vehicleType;
   let _speed = state.speed;
   let _load = state.load;
   let _length = state.length;
   let _height = state.height;
   let _diameter = state.diameter;
   let _filter = state.filter;
   let _id = state.id;
   let _changeFlag = state.changeFlag;
   let _sortValue = state.sortValue;
   let _sortValueJson = state.sortValueJson;
   // temp brand
   let _values = state.values;
   // new brand values
   let _brandValues = state.brandValues;
   // brand values
   let _bValues = state.bValues;
   // last bValues
   let _lastBValues = state.lastBValues;
   let _filterSelections = state.filterSelections;
   let _url = state.url;
   // rft
   let _vals = state.vals;
   // dot/demo
   let _dotValues = state.dotValues;
   let _nEValues = state.nEValues;
   let _wGValues = state.wGValues;
   let _rRValues = state.rRValues;
   let _finalWGValues = state.finalWGValues;
   let _finalRRValues = state.finalRRValues;
   switch (action.type) {
      case actionTypes.SET_SELECTED_VEHICLE_TYPE:
         _vehicleType = action.vehicleType;
      {
         return Object.assign({}, state, {
            vehicleType: _vehicleType
         });
      }
      case actionTypes.SET_SELECTED_SEASON:
         _season = action.season;
      {
         return Object.assign({}, state, {
            season: _season
         });
      }
      case actionTypes.SET_SELECTED_SPEED:
         _speed = action.speed;
      {
         return Object.assign({}, state, {
            speed: _speed
         });
      }
      case actionTypes.SET_SELECTED_LOAD:
         _load = action.load;
      {
         return Object.assign({}, state, {
            load: _load
         });
      }

      case actionTypes.SET_SELECTED_LENGTH:
         _length = action.length;
      {
         return Object.assign({}, state, {
            length: _length
         });
      }

      case actionTypes.SET_SELECTED_HEIGHT:
         _height = action.height;
      {
         return Object.assign({}, state, {
            height: _height
         });
      }

      case actionTypes.SET_SELECTED_DIAMETER:
         _diameter = action.diameter;
      {
         return Object.assign({}, state, {
            diameter: _diameter
         });
      }

      case actionTypes.SELECTED_SORT_VALUE:
         _sortValue = action.sortValue;
         if (_sortValue === "Preis absteigend") {
            _sortValueJson = {sortDir: "DESC", sortField: "minPrice"};
         } else if (_sortValue === "Preis aufsteigend") {
            _sortValueJson = {sortDir: "ASC", sortField: "minPrice"};
         }
      {
         return Object.assign({}, state, {
            sortValue: _sortValue,
            sortValueJson: _sortValueJson
         });
      }
      case actionTypes.SET_ROUTE_CHANGED_FROM:
         _from = action.from;
      {
         return Object.assign({}, state, {
            from: _from
         });
      }
      case actionTypes.SET_ROUTE_CHANGED_TO:
         _to = action.to;
      {
         return Object.assign({}, state, {
            to: _to
         });
      }
      case actionTypes.FILTER_SELECTIONS:
         _filter = !state.filter;
      {
         return Object.assign({}, state, {
            filter: _filter
         });
      }
      case actionTypes.SET_FILTER_SELECTIONS:
         _id = action.id;
         if (action.id === "BRAND") {
            let newValues = [];
            _bValues = action.value;
            if (_bValues) {
               for (let i = 0; i <= _bValues.length - 1; i++) {
                  newValues.push(_bValues[i].label);
               }
               _brandValues = newValues;
            }
            else _brandValues = newValues = null;
            _filterSelections.forEach(element => {
               if (element.id === action.id) {
                  element.values = newValues;
               }
            });
         } else if (action.id === "property") {
            if (action.value === "runflat") {
               if (_vals.length !== 0) {
                  if (!_vals.includes(action.value)) {
                     _vals.push(action.value);
                     _filterSelections.forEach(element => {
                        if (element.id === "RFT") {
                           element.values = ["true"];
                        }
                     });
                  } else {
                     _vals.splice(_vals.indexOf(action.value), 1);
                     _filterSelections.forEach(element => {
                        if (element.id === "RFT") {
                           element.values = ["false"];
                        }
                     });
                  }
               } else {
                  _vals.push(action.value);
                  _filterSelections.forEach(element => {
                     if (element.id === "RFT") {
                        element.values = ["true"];
                     }
                  });
               }
            } else if (action.value === "dotDemo") {
               if (_dotValues.length !== 0) {
                  if (!_dotValues.includes(action.value)) {
                     _dotValues.push(action.value);
                     _filterSelections.forEach(element => {
                        if (element.id === "DOT_DEMO") {
                           element.values = ["true"];
                        }
                     });
                  } else {
                     _dotValues.splice(_dotValues.indexOf(action.value), 1);
                     _filterSelections.forEach(element => {
                        if (element.id === "DOT_DEMO") {
                           element.values = ["false"];
                        }
                     });
                  }
               } else {
                  _dotValues.push(action.value);
                  _filterSelections.forEach(element => {
                     if (element.id === "DOT_DEMO") {
                        element.values = ["true"];
                     }
                  });
               }
            }
         } else if (action.id === "noise_emission") {
            _nEValues = action.value;
            _filterSelections.forEach(element => {
               if (element.id === "NOISE_EMISSION") {
                  element.values = _nEValues;
               }
            });
         } else if (action.id === "wet_grip") {
            _wGValues = action.value;
            if (_wGValues[0] === 1) {
               _finalWGValues[0] = "A";
            }
            if (_wGValues[0] === 2) {
               _finalWGValues[0] = "B";
            }
            if (_wGValues[0] === 3) {
               _finalWGValues[0] = "C";
            }
            if (_wGValues[0] === 4) {
               _finalWGValues[0] = "D";
            }
            if (_wGValues[0] === 5) {
               _finalWGValues[0] = "E";
            }
            if (_wGValues[0] === 6) {
               _finalWGValues[0] = "F";
            }
            if (_wGValues[1] === 1) {
               _finalWGValues[1] = "A";
            }
            if (_wGValues[1] === 2) {
               _finalWGValues[1] = "B";
            }
            if (_wGValues[1] === 3) {
               _finalWGValues[1] = "C";
            }
            if (_wGValues[1] === 4) {
               _finalWGValues[1] = "D";
            }
            if (_wGValues[1] === 5) {
               _finalWGValues[1] = "E";
            }
            if (_wGValues[1] === 6) {
               _finalWGValues[1] = "F";
            }
            _filterSelections.forEach(element => {
               if (element.id === "WET_GRIP") {
                  element.values = _finalWGValues;
               }
            });
         } else if (action.id === "rolling_resistance") {
            _rRValues = action.value;
            if (_rRValues[0] === 1) {
               _finalRRValues[0] = "A";
            }
            if (_rRValues[0] === 2) {
               _finalRRValues[0] = "B";
            }
            if (_rRValues[0] === 3) {
               _finalRRValues[0] = "C";
            }
            if (_rRValues[0] === 4) {
               _finalRRValues[0] = "D";
            }
            if (_rRValues[0] === 5) {
               _finalRRValues[0] = "E";
            }
            if (_rRValues[0] === 6) {
               _finalRRValues[0] = "F";
            }
            if (_rRValues[0] === 7) {
               _finalRRValues[0] = "G";
            }
            if (_rRValues[1] === 1) {
               _finalRRValues[1] = "A";
            }
            if (_rRValues[1] === 2) {
               _finalRRValues[1] = "B";
            }
            if (_rRValues[1] === 3) {
               _finalRRValues[1] = "C";
            }
            if (_rRValues[1] === 4) {
               _finalRRValues[1] = "D";
            }
            if (_rRValues[1] === 5) {
               _finalRRValues[1] = "E";
            }
            if (_rRValues[1] === 6) {
               _finalRRValues[1] = "F";
            }
            if (_rRValues[1] === 7) {
               _finalRRValues[1] = "G";
            }
            _filterSelections.forEach(element => {
               if (element.id === "ROLLING_RESISTANCE") {
                  element.values = _finalRRValues;
               }
            });
         }
         let brandSelection = state.bValues;
      {
         return Object.assign({}, state, {
            id: _id,
            // temp brand
            values: _values,
            // selected brand values
            brandValues: _brandValues,
            // brand values with label and value
            bValues: _bValues,
            // test last bValues
            lastBValues: _bValues && (_bValues.length !== 0) ? _bValues : brandSelection,
            // rft
            vals: _vals,
            // dot/demo
            dotValues: _dotValues,
            nEValues: _nEValues,
            wGValues: _wGValues,
            rRValues: _rRValues,
            finalWGValues: _finalWGValues,
            finalRRValues: _finalRRValues,
            filterSelections: _filterSelections
         });
      }

      case actionTypes.RESET_FILTER_SELECTIONS:
         _filterSelections = [
            {id: "BRAND", values: []},
            {id: "DOT_DEMO", values: []},
            {id: "RFT", values: []},
            {id: "NOISE_EMISSION", values: []},
            {id: "WET_GRIP", values: ["A", "F"]},
            {id: "ROLLING_RESISTANCE", values: ["A", "G"]}
         ];
         _id = "";
         _values = [];
         _vals = [];
         _nEValues = [66, 75];
         _wGValues = [1, 6];
         _rRValues = [1, 7];
         _finalWGValues = ["A", "F"];
         _finalRRValues = ["A", "G"];
         _brandValues = [];
         _bValues = [];
         _dotValues = [];

         return Object.assign({}, state, {
            filterSelections: _filterSelections,
            values: _values,
            id: _id,
            vals: _vals,
            nEValues: _nEValues,
            wGValues: _wGValues,
            rRValues: _rRValues,
            finalWGValues: _finalWGValues,
            finalRRValues: _finalRRValues,
            brandValues: _brandValues,
            bValues: _bValues,
            dotValues: _dotValues
         });

      case actionTypes.COMPUTED_URL_VALUE:
         let oldUrl = state.url;
         _url = action.url;
      {
         return Object.assign({}, state, {
            url: _url
         });
      }

      case actionTypes.RESET_SEARCH_FLAG:
         _changeFlag = false;
         return Object.assign({}, state, {
            changeFlag: _changeFlag
         });

      case actionTypes.FLAG_CHANGED:
         _changeFlag = true;
         return Object.assign({}, state, {
            changeFlag: _changeFlag
         });
   }
   return state;
};

export default reducer;
