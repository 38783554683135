import React from 'react';
import ShoppingCartTyres from '../components/ShoppingCartTyres';
import { connect } from 'react-redux';
import TotalPrice from '../../../shared/priceAmountCaption/TotalPrice';

class ShoppingCartFilled extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            totalPrice: this.props.totalPrice,
            sctyres: this.props.sctyres
        };
    }

    render() {
        return (
            <div className="wrapper-sc-filled">
                <div className="row separator">
                    <div className="d-none d-sm-block col-md-3 col-xl-3"/>
                    <div className="d-none d-md-block col-8 col-sm-4 col-md-3 col-xl-3">
                        <h4>Artikel</h4>
                    </div>
                    <div className="col-2 d-none d-md-block col-sm-1 col-md-2 col-xl-2 text-right">
                        <h4>Stückpreis</h4>
                    </div>
                    <div className="col-2 d-none d-md-block col-sm-2 col-md-2 col-xl-2 text-center">
                        <h4>Anzahl</h4>
                    </div>
                    <div className="col-4 d-none d-md-block col-sm-4 col-md-2 col-xl-2 text-right">
                        <h4>Summe</h4>
                    </div>
                </div>
                <ShoppingCartTyres
                    shoppingCartTyres={ this.props.sctyres }
                    removeButtonHandler={ this.props.removeButtonHandler }
                    amountSelectionHandler={ this.props.amountSelectionHandler }
                    deletable={ true }
                    key={ new Date() }
                    visualizationType="shoppingcart"
                />
                <div className="row spacing-top">
                    <div className="col-8 col-sm-7 col-md-6 col-xl-6">
                        <h4 className="total-price"> Ihr Gesamtpreis (inkl. Mwst.)</h4>
                    </div>
                    <div className="col-4 col-sm-5 col-md-6 col-xl-6">
                        <TotalPrice/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        totalPrice: state.shoppingcart.totalPrice,
        sctyres: state.shoppingcart.shoppingCartTyres
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect( mapStateToProps, mapDispatchToProps )( ShoppingCartFilled );
