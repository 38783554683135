import * as actionTypes from "./actionTypes";

export const setTermsOfUse = () => {
  return {
    type: actionTypes.SET_TERMS_OF_USE,
    termsOfUse: true
  };
};

export const resetTermsOfUse = () => {
  return {
    type: actionTypes.RESET_TERMS_OF_USE,
    termsOfUse: false
  };
};

export const setDataProtection = () => {
  return {
    type: actionTypes.SET_DATA_PROTECTION,
    dataProtection: true
  };
};

export const resetDataProtection = () => {
  return {
    type: actionTypes.RESET_DATA_PROTECTION,
    dataProtection: false
  };
};
