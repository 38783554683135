import Home from "./components/pages/Home/Home";
import Contact from "./components/pages/Contact/Contact";
import TyreDetail from "./components/pages/TyreDetails/TyreDetail";
import Imprint from "./components/pages/Imprint/Imprint";
import PrivacyStatement from "./components/pages/PrivacyStatement/PrivacyStatement";
import TermsOfUse from "./components/pages/TermsOfUse/TermsOfUse";
import ShoppingCart from "./components/pages/ShoppingCart/ShoppingCart";
import SearchResult from "./components/pages/SearchResult/SearchResult";
import News from "./components/pages/News/News";
import About from "./components/pages/About/About";
import CheckoutProcess from "./components/pages/ShoppingCart/components/checkoutProcess/CheckoutProcess";
import CheckoutConfirmed from "./components/pages/ShoppingCart/components/checkoutProcess/CheckoutConfirmed";
import CheckoutFailed from "./components/pages/ShoppingCart/components/checkoutProcess/CheckoutFailed";
import UnresolvedHostname from './components/shared/error/UnresolvedHostname';
import Rimconfigurator from "../modules/Rimconfigurator/pages/carSelection/CarSelection";
import NotFoundPage from "./components/layout/NotFoundPage";
import SelectRim from '../modules/Rimconfigurator/pages/rims/SelectRim';
import noRimsFound from "./rimconfigurator/components/layout/noRimsFound";


export default [
   {
      path: "/",
      component: Home,
      exact: true
   },
   {
      path: "/tyre/:id",
      component: TyreDetail,
      exact: true
   },
   {
      path: "/searchResult/:searchString",
      component: SearchResult,
      exact: true
   },
   {
      path: "/shoppingcart",
      component: ShoppingCart,
      exact: true
   },
   {
      path: "/contact",
      component: Contact,
      exact: true
   },
   {
      path: "/privacystatement",
      component: PrivacyStatement,
      exact: true
   },
   {
      path: "/imprint",
      component: Imprint,
      exact: true
   },
   {
      path: "/termsofuse",
      component: TermsOfUse,
      exact: true
   },
   {
      path: "/news",
      component: News,
      exact: true
   },
   {
      path: "/about",
      component: About,
      exact: true
   },
   {
      path: "/checkoutprocess",
      component: CheckoutProcess,
      exact: false
   },
   {
      path: "/BranchChecker",
      component: CheckoutProcess,
      exact: false
   },
   {
      path: "/BillingAddress",
      component: CheckoutProcess,
      exact: false
   },
   {
      path: "/CheckoutConfirmation",
      component: CheckoutProcess,
      exact: false
   },
   {
      path: "/CheckoutConfirmed",
      component: CheckoutConfirmed,
      exact: false
   },
   {
      path: "/CheckoutFailed",
      component: CheckoutFailed,
      exact: false
   },
   {
      path: "/unresolvedHostname",
      component: UnresolvedHostname,
      exact: true
   },
   {
      path: "/rimconfigurator/manualSelect/:manufacturerId?/:modelId?/:typesId?",
      component: Rimconfigurator,
      exact: false
   },
   {
      path: "/rimconfigurator/",
      component: Rimconfigurator,
      exact: true
   },
   {
      path: "/rimconfigurator/car/:id/:rimId?",
      component: SelectRim,
      exact: false
   },
   {
      path: "/rimconfigurator/noRimsFound",
      component: noRimsFound,
      exact: true
   },
   {
      path: "/rimconfigurator/car/*",
      component: noRimsFound,
      exact: true
   },
   {
      path: "/*",
      component: NotFoundPage
   },
];
