import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as actionCreators from '../../../../redux-store/actions/index';

import ShoppingCartWrapper from './components/ShoppingCartWrapper';

class ShoppingCart extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            tyres: this.props.tyres
        };
    }

    componentDidMount() {
        this.props.onSetNavigation( '/shoppingcart' );
        this.props.onResetTermsOfUse();
        this.props.onResetDataProtection();
        this.onBackButtonEvent = e => {
            e.preventDefault();
            this.props.history.push( this.props.url );
            this.props.onSetNavigation( this.props.url );
        };
    }

    componentDidUpdate() {
        window.onpopstate = this.onBackButtonEvent;
    }

    head() {
        return (
            <Helmet>
                <title>Shoppingcart</title>
                <meta name="description"
                      content="This is a proof of concept for TyreDetail SSR"
                />
            </Helmet>
        );
    }

    render() {
        return (
            <div className="container">
                <div className="shoppingcart-wrapper">
                    { this.head() }
                    <ShoppingCartWrapper/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        tyres: state.shoppingcart.shoppingCartTyres,
        url: state.routeChanged.url
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRemoveButtonHandler: idxx => dispatch( actionCreators.removeCartTire( idxx ) ),
        onSetNavigation: location => dispatch( actionCreators.setNavigation( location ) ),
        onResetTermsOfUse: () => dispatch( actionCreators.resetTermsOfUse() ),
        onResetDataProtection: () => dispatch( actionCreators.resetDataProtection() )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( ShoppingCart );
