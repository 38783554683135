import * as actionTypes from '../actions/actionTypes';

const initialState = {
    selectedTyre: {},
    isLoading: false,
    error: null,
};

const reducer = ( state = initialState, action ) => {
    let _selectedTyre = state.selectedTyre;
    let _error = state.error;
    let _isLoading = state.isLoading;

    switch (action.type) {
        
        case actionTypes.STORE_selectedTyre:
        _isLoading = false
         _selectedTyre = action.selectedTyre;
        return {
            selectedTyre: _selectedTyre,
            isLoading: _isLoading,
            error: null
        }
        case actionTypes.LOAD_selectedTyre:
        _isLoading = true
        return {
            isLoading: _isLoading
        };

        case actionTypes.LOAD_selectedTyre_ERROR:
            _error = action.error;
            _isLoading = false;
            return {
                error: _error,
                isLoading: _isLoading,
                selectedTyre: {}

            }

        default: break;
    }
    return state;
}

export default reducer;
