import React from 'react';
import Rim from './rim/Rim';
import {connect} from 'react-redux';

let visLeft;
let visRight;
let firstRim;

class Rims extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         firstRim: 0,
         id: null,
         activeRimIndex: null,
         carId: this.props.carId,
         preview: null,
         translateWidth: 0,
         wrapperClass: '',
         chosenRim: '',
         filterSelections: null,
         enableSwitch: this.props.enableSwitch
      };
      this.myRef = React.createRef();
      this.setRimActive = params => {
         this.props.rims.forEach((rim) => {
            rim.isSelected = false;
         });
         params.isSelected = true;
         this.setState({chosenRim: params.id});
      };
   }

   componentDidMount() {
      if ('ontouchstart' in document.documentElement) {
         visLeft = false;
         visRight = false;
         this.setState({wrapperClass: ' touch'});
      } else {
         visLeft = this.state.firstRim === 0 ? false : true;
         visRight = this.state.firstRim === this.state.pages ? false : true;
      }
      visLeft = this.state.firstRim === 0 ? false : true;
      visRight = this.state.firstRim === this.state.pages ? false : true;
   }

   /*
       first (left) rim in side scroll
    */
   setFirstRim(index) {
      this.setState({firstRim: index});
   }

   /*
       store active rim possition
    */
   setActiveRim(index) {
      this.setState({activeRimIndex: index});
   }

   /*
       set Rim (id)
       show Details
    */
   setRim(rim, rimSizeId, preview) {
      let sizeArray = [];
      for (let _rim of rim.items) {
         sizeArray.push(_rim.size);
      }
      if (!sizeArray.includes(parseInt(this.props.rimSize)) && this.props.rimSize != rim.items[0].size) {
         this.props.rimSizeCallBack(rim.items[0].size);
      }
      this.setState({
         id: preview ? null : rim.id,
         preview: preview
      }, this.previewRim(rim, rimSizeId, preview));
   }

   /*
       handle call back to the wrapper component
       used to change the car image
    */
   previewRim(rim, rimSizeId, preview) {
      this.props.selectRim(rim, rimSizeId, preview);
   }

   /*
       reset state to default
       used to switch back from detail view
    */
   back() {
      this.setState({
         id: null,
         activeRimIndex: null,
         preview: false
      });
   }

   goToDetail(index, rim, rimSizeId, preview) {
      this.setRim(rim, rimSizeId, preview);
      this.setActiveRim(index);
   }

   componentDidUpdate(prevProps, prevState) {
      let _translateWidth = 0;
      if (this.myRef.current && this.myRef.current.childNodes[0]) {
         let rimsPerPage = Math.floor((window.outerWidth / 350)) - 1;
         if (prevProps.isFavoriteView !== this.props.isFavoriteView) {
            this.setState({translateWidth: _translateWidth});
         } else if (this.state.firstRim != prevState.firstRim) {
            //EINFACH NICHT ANFASSEN
            _translateWidth = this.myRef.current.scrollWidth / this.props.loadPage * rimsPerPage;
            _translateWidth = _translateWidth * this.state.firstRim;
            if (this.state.firstRim !== 0) {
               this.setState({translateWidth: _translateWidth + 300});
            } else {
               this.setState({translateWidth: _translateWidth});
            }
         }
         let _pages = Math.ceil(this.props.loadPage / rimsPerPage) - 1;
         if ((_pages === this.state.firstRim) && (this.state.firstRim !== prevState.firstRim)) {
            let loadingRims = (1 + rimsPerPage * 4);
            let loadingRimsDifference = 0;
            if (loadingRims > this.props.totalCount) {
               loadingRimsDifference = loadingRims - this.props.totalCount;
               loadingRims = loadingRims - loadingRimsDifference - 1;
            }
            this.props.loadMore(loadingRims);
            this.setState({pages: _pages});
         } else if (_pages != this.state.pages && this.props.loadingRims) {
            this.setState({pages: _pages});
         }
      }
      if (this.props.totalCount != prevProps.totalCount) {
         this.setState({firstRim: 0});
      }
      //clear if parent sent no rimId ( check filtercallback )
      if (prevProps.rimId != this.props.rimId && this.props.rimId === undefined) {
         this.back();
      }
      //checks if props has rimId ( used when link has a rim )
      if (!this.state.preview && this.props.rimId && this.props.rims) {
         let rim = this.props.rims;
         for (let rid in rim) {
            if (rim[rid].id === this.props.rimId && this.state.activeRimIndex != rid) {
               this.setRim(rim[rid], rim[rid].items[0].rimId, false);
               this.setActiveRim(rid);
            }
         }
      }
      if ('ontouchstart' in document.documentElement) {
         visLeft = false;
         visRight = false;
      } else {
         visLeft = (this.state.firstRim === 0) ? false : true;
         visRight = this.state.firstRim === this.state.pages + 1 ? false : true;
         if (this.props.rims.length >= this.props.totalCount) {
            visRight = (this.state.firstRim + 1 === this.state.pages) ? false : true;
         }
      }
      if (this.props.isFavoriteView) {
         let rims = this.props.rims;
         rims = rims.filter(rim => rim.retailPrice);
         rims = rims.filter(rim => (rim.items.length > 0));
         let favRims = this.props.favRims[this.props.carId];
         if ((rims[rims.length - 1] !== prevProps.rims[prevProps.rims.length - 1]) && (favRims.length === 0) && this.props.filterSelections) {
            let isEmpty = true;
            for (let selection in this.props.filterSelections) {
               if (this.props.filterSelections[selection].length > 0) {
                  isEmpty = false;
               }
            }
            if (this.props.isFavoriteView && isEmpty) {
               this.props.favRimCallBack(false, false);
            }
         }
      }
   }

   scrollHandler(e) {
      if (window.outerWidth >= (this.myRef.current.scrollWidth - e.currentTarget.scrollLeft - 100)) {
         this.props.loadMore(20);
      }
   }

   render() {
      let rims = (this.props.rims ? this.props.rims : []);
      let favRims = this.props.favRims[this.props.carId];
      if (rims[0].items) {
         rims = rims.map(rim => {
            rim.items = rim.items.filter(item => item.retailPrice);
            if (!rim.retailPrice) {
               for (let ii = 0; ii < rim.items.length; ii++) {
                  if (rim.items[ii].retailPrice) {
                     rim.retailPrice = rim.items[ii].retailPrice;
                     ii += rim.items.length;
                  }
               }
            }
            return rim;
         });
         rims = rims.filter(rim => rim.retailPrice);
         rims = rims.filter(rim => (rim.items.length > 0));

      }
      if (this.props.favRims && this.props.isFavoriteView && favRims.length !== 0) {
         rims = favRims;
      }

      let id = this.state.id;
      firstRim = this.state.firstRim;
      let activeRimIndex = this.state.activeRimIndex;
      let details = id != null;
      return (
         <div>
            <div className={'sideColumn' + (this.state.id ? ' container' : '') + this.state.wrapperClass}
                 onScroll={this.scrollHandler.bind(this)}>
               <div className={'sideSlider'}>
                  <div
                     className={this.props.loadingRims ? 'd-none' : (visLeft && !details) ? 'd-none d-md-block transparent-container left-arrow' : 'd-none'}
                     onClick={() => this.setFirstRim(firstRim - 1)}>
                  </div>
                  <div
                     className={'loading-circle' + (this.props.loadingRims || this.props.filterLoader ? "" : " d-none")}/>
                  <div className={'rimWrapper'} ref={this.myRef}
                       style={{
                          'width': id ? '100%' : '',
                          'transform': !details ? `translateX(-${this.state.translateWidth}px` : `translateX(-100%)`
                       }}>
                     {rims.map((rim, index) => (
                        <Rim key={rim.id + index} loadingRims={this.props.loadingRims}
                             totalCount={this.props.totalCount}
                             rimSizeCallBack={this.props.rimSizeCallBack}
                             rim={rim} filterSelections={this.props.filterSelections}
                             rimSize={this.props.rimSize}
                             setRim={this.setRim.bind(this, rim, rim.items[0].rimId, true)}
                             selectedRim={this.props.selectedRim}
                             index={index}
                             goToDetail={this.goToDetail.bind(this, index, rim, rim.items[0].rimId, false)}
                             carId={this.props.carId}
                             isDetailView={this.props.isDetailView}
                             isFavoriteView={this.props.isFavoriteView}
                             activeRim={id} pointsCodeHandler={this.state.data} car={this.props.car}
                             onChildSelected={this.setRimActive}
                             carImage={this.props.carImage} base64img={this.props.base64img}
                        />
                     ))}
                  </div>
                  <div
                     className={this.props.loadingRims ? 'd-none' : (visRight && !details) ? 'd-none d-md-block transparent-container right-arrow' : 'd-none'}
                     onClick={() => this.setFirstRim(firstRim + 1)}>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      favRims: state.favRims
   };
};

const mapDispatchToProps = dispatch => {
   return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Rims);
