import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../redux-store/actions/index';
import Address from '../../shared/address/Address';
import MapContainer from '../../shared/Maps/Maps';

class Contact extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            passedvalues: {
                counter: 0,
                address: ''
            },
            mapData: 0,
            mapReloaded: false
        };
    }

    componentDidMount() {
        this.props.onResetTermsOfUse();
        this.props.onResetDataProtection();
        if (this.props && this.props.callBackFromParent) {
            this.props.callBackFromParent( this.state );
        }
        this.props.onSetNavigation( '/contact' );
    }

    fillData( dataFromChild ) {
        this.setState( { passedvalues: dataFromChild } );
    }

    myCallback( dataFromChild ) {
        let _state = this.state;
        _state.passedvalues = dataFromChild;
        this.setState( _state);
        if (this.props && this.props.callBackFromParent) {
            this.props.callBackFromParent( _state );
        }
    }

    mapCallback( dataFromChild ) {
        this.setState( { mapData: dataFromChild } );
    }

    navigationCallBack( data ) {
        this.props.navigationCallBack();
    }

    mapHandler() {
        return (
            <div className="MapWrapper">
                <MapContainer
                    callBackFromParent={ this.mapCallback.bind( this ) }
                    firmData={ this.props.firmData }
                    data={ this.state.passedvalues }
                />
            </div>
        );
    }

    render() {
        return (
            <div className="container">
                <div className="contact-wrapper">
                    { this.props.history && <h2>Kontakt</h2> }
                    <div className="row min-height-row">
                        <div className="col-12 col-md-6 order-md-last">
                            <Address
                                firmData={ this.props.firmData }
                                passedValues={ this.state.passedvalues }
                                callBackFromParent={ this.myCallback.bind( this ) }
                                mapCounter={ this.state.mapData }
                                isCheckout={ this.props.callBackFromParent ? true : false }
                                navigationCallBack={ this.navigationCallBack.bind( this ) }
                            />
                        </div>
                        <div className="col-11 d-none d-md-block col-md-6 order-md-first">{ this.mapHandler() }</div>
                    </div>
                    <Helmet>
                        <title>Kontakt</title>
                        <meta name="description"
                              content="This is a proof of concept for React SSR"
                        />
                    </Helmet>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ( {
    firmData: state.firmData,
    tyres: state.tyres
} );

const mapDispatchToProps = {
    onSetNavigation: location => actionCreators.setNavigation( location ),
    onResetTermsOfUse: () => actionCreators.resetTermsOfUse(),
    onResetDataProtection: () => actionCreators.resetDataProtection()
};
export default connect( mapStateToProps, mapDispatchToProps )( Contact );
