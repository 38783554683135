import React from 'react';
import Contact from '../../../Contact/Contact';

import { connect } from 'react-redux';
import * as actionCreators from '../../../../../../redux-store/actions/index';

class BranchChecker extends React.Component {
    constructor( props ) {
        super( props );
        // initial state
        this.state = {
            tyres: this.props.userdata.tyres,
            userdata: this.props.userdata.userdata,
            passedvalues: {
                address: [],
                counter: 0
            },
            steps: 'AssemblyDate',
            step: 2
        };
        this.newCallback = ( dataFromChild ) => {
            this.setState( { passedvalues: dataFromChild } );
        };
        this.navigationCallBack = ( data ) => {
            this.props.callBackFromParent( this.state );
        };
    }

    componentDidMount() {
        this.props.onResetTermsOfUse();
        this.props.onResetDataProtection();
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-md-6 order-md-last">
                        <div className="row">
                            <div className="col-12">
                                <h2 className={"contact-title"}>Wählen Sie Ihre Filiale aus</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-none d-md-block order-md-first">
                    </div>
                </div>
                <Contact callBackFromParent={ this.newCallback }
                         userdata={ this.props.userdata }
                         navigationCallBack={ this.navigationCallBack }
                />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        onResetTermsOfUse: () => dispatch( actionCreators.resetTermsOfUse() ),
        onResetDataProtection: () => dispatch( actionCreators.resetDataProtection() )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( BranchChecker );
