import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

let title = [];
let body = [];

class Imprint extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            firmData: this.props.firmData
        };
    }

    imprintHeader( num ) {
        if (this.state.firmData[num]) {
            let i = this.state.firmData[num].AllgemeineDaten;
            return (
                <div>
                    <h2> { i.Name } </h2>
                    { i.Strasse }
                    <br/>
                    { i.Plz } { i.Ort } <br/>
                    <br/>
                </div>
            );
        }
    }

    imprintComplementary( num ) {
        if (this.state.firmData[num]) {
            let i = this.state.firmData[num].AllgemeineDaten;
            let x = i.Impressum;
            if (i.Impressum && x.Vertretungsberechtigt) {
                return (
                    <div>
                        <strong>{ x.Vertretungsberechtigt }</strong> <br/>
                        { x.Komplementaer } <br/>
                        <br/>
                    </div>
                );
            }
        }
    }

    imprintSubstitutePerson( num ) {
        if (this.state.firmData[num]) {
            let i = this.state.firmData[num].AllgemeineDaten;
            let x = i.Impressum;
            if (i.Impressum && x.VertretungsberechtigtePersonen) {
                return (
                    <div>
                        <strong>{ x.VertretungsberechtigtePersonen }</strong> <br/>
                        { x.Ansprechpartner1 } <br/>
                        { x.Ansprechpartner2 }
                        { x.Ansprechpartner3 }
                        { x.Ansprechpartner4 }
                        <br/>
                    </div>
                );
            }
        }
    }

    imprintJurisdicision( num ) {
        if (this.state.firmData[num]) {
            let i = this.state.firmData[num].AllgemeineDaten;
            let x = i.Impressum;
            if (i.Impressum && x.Gerichtsstand) {
                return (
                    <div>
                        <strong>Gerichtsstand</strong> <br/>
                        { x.Gerichtsstand } <br/>
                        <br/>
                    </div>
                );
            }
        }
    }

    imprintContact( num ) {
        if (this.state.firmData[num]) {
            let i = this.state.firmData[num].AllgemeineDaten;
            if (i) {
                return (
                    <div>
                        <strong>Kontakt</strong> <br/>
                        Telefon: { i.Telefon } <br/>
                        Fax: { i.Fax } <br/>
                        Mail: { i.Email } <br/>
                        <br/>
                    </div>
                );
            }
        }
    }

    imprintEnding( num ) {
        if (this.state.firmData[num]) {
            let i = this.state.firmData[num].AllgemeineDaten;
            if (i) {
                return (
                    <div className="imprint-ending" >
                        <hr />
                        <p>Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind
                            wir nicht verpflichtet. Wir nehmen an einem Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle auch nicht teil.</p>
                        <p>Verbraucherinformation gemäß Verordnung (EU) Nr.524/2013:<br />An dieser Stelle stellen wir
                            Ihnen den <a
                                href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=DE"
                                target="_blank" >Link</a> zur OS-Plattform (Online
                            Streitbeilegungsplattform) der EU-Kommission zur Verfügung.<br />Unsere E-Mailadresse lautet:
                            {i.Mail}</p>
                        <p>&nbsp;</p>
                    </div>
                );
            }
        }
    }

    imprintHeadquarters( num ) {
        if (this.state.firmData[num]) {
            let i = this.state.firmData[num].AllgemeineDaten;
            if (i.Firmensitz) {
                return (
                    <div>
                        <strong>Firmensitz</strong> <br/>
                        { i.Firmensitz } <br/>
                        <br/>
                    </div>
                );
            }
        }
    }

    imprintDistrictCourt( num ) {
        if (this.state.firmData[num]) {
            let i = this.state.firmData[num].AllgemeineDaten;
            let x = i.Impressum;
            if (x) {
                return (
                    <div>
                        <strong>Handelsregisternummern</strong> <br/>
                        { x.AmtgerichtHRA } { x.HRA } <br/>
                        { x.AmtgerichtHRB } { x.HRB } <br/>
                        <br/>
                    </div>
                );
            }
        }
    }

    render() {
        return (
            <div className="container">
                <h1>
                    Impressum
                    <br/>
                    <small>Angaben gemäß § 5 TMG</small>
                </h1>
                { this.imprintHeader( 0 ) }
                { this.imprintComplementary( 0 ) }
                { this.imprintSubstitutePerson( 0 ) }
                { this.imprintJurisdicision( 0 ) }
                { this.imprintContact( 0 ) }
                { this.imprintHeadquarters( 0 ) }
                { this.imprintDistrictCourt( 0 ) }
                { this.imprintHeader( 1 ) }
                { this.imprintComplementary( 1 ) }
                { this.imprintSubstitutePerson( 1 ) }
                { this.imprintJurisdicision( 1 ) }
                { this.imprintContact( 1 ) }
                { this.imprintHeadquarters( 1 ) }
                { this.imprintDistrictCourt( 1 ) }
                { this.imprintHeader( 2 ) }
                { this.imprintComplementary( 2 ) }
                { this.imprintSubstitutePerson( 2 ) }
                { this.imprintJurisdicision( 2 ) }
                { this.imprintContact( 2 ) }
                { this.imprintHeadquarters( 2 ) }
                { this.imprintDistrictCourt( 2 ) }
                { this.imprintEnding(0)}
                <Helmet>
                    <title>Impressum</title>
                </Helmet>
            </div>
        );
    }
}

const mapStateToProps = state => ( {
    firmData: state.firmData
} );

const mapDispatchToProps = {};

export default connect( mapStateToProps, mapDispatchToProps )( Imprint );
