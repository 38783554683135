import * as actionTypes from "../actions/actionTypes";

const initialState = {
  orders: [],
  order: {},
  sent: false,
  error: {},
  status: null,
  statusText: ""
};

const reducer = (state, action) => {
  state = state || initialState;
  let _orders = state.orders;
  let _order = state.order;
  let _sent = state.sent;
  let _error = state.error;
  let _status = state.status;
  let _statusText = state.statusText;
  switch (action.type) {
    case actionTypes.STORE_ORDER:
      _order = action.order;
      _error = {};
      {
        return Object.assign({}, state, {
          order: _order
        });
      }

    case actionTypes.ORDER_SENT:
      _sent = action.sent;
      _error = {};
      (_status = null), (_statusText = "");
      {
        return Object.assign({}, state, {
          sent: _sent,
          error: _error,
          status: _status,
          statusText: _statusText
        });
      }

    case actionTypes.RESET_SENT:
      _sent = action.sent;
      {
        return Object.assign({}, state, {
          sent: _sent
        });
      }

    case actionTypes.SEND_ORDER_ERROR:
      _status = action.res;
      _statusText = action.res;
      _error.status = _status;
      _error.statusText = _statusText;
      _order = {};
      {
        return Object.assign({}, state, {
          status: _status,
          statusText: _statusText,
          error: _error,
          sent: false,
          order: _order
        });
      }
  }
  return state;
};

export default reducer;
