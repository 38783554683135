import * as actionTypes from './actionTypes';

export const fetchModels = option => {
    return {
        type: actionTypes.SET_NAVIGATION,
        location: loc
    };
};

export const test = value => {
    return {
        type: actionTypes.TEST
    };
};


