import React from 'react';

let i;
let arr = [];
const data = {
    address: [],
    counter: 0
};
let buff = -1;

class AddressFooter extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            openingTimesCls: null,
            counter: '0'
        };
    }

    openingTimesHider( e ) {
        const newstate = this.state;
        newstate.counter = e.currentTarget.id;
        data.counter = e.currentTarget.id;
        this.setState( newstate );
    }

    contactCardsActiveHandler( ii ) {
        let clsnm = 'contact-cards';
        if (buff == ii) {
            clsnm = 'contact-cards active';
        }
        return clsnm;
    }

    contactPageBuilder() {
        arr = [];
        if (this.props && this.props.firmData && this.props.firmData[0] && this.props.firmData[0].AllgemeineDaten) {
            let ii = 0;
            let keys = 1;
            let showDetails = false;

            if (this.props && this.props.hauptsitz !== true) {
                keys = Object.keys( this.props.firmData ).length;
            }
            while (ii < keys) {
                let Clss = 'hidden';
                if (this.state.counter == ii && this.props.openingTimesCount !== 'hidden') {
                    Clss = 'visible';
                }
                i = this.props.firmData[ii].AllgemeineDaten;
                data.address[ii] = ( `${ i.Strasse } , ${ i.Plz } , ${ i.Ort } , ${ i.Land }` );
                arr[ii] = (
                    <div
                        className={ this.contactCardsActiveHandler( ii ) }
                        key={ ii }
                        id={ ii }
                        onClick={ this.openingTimesHider.bind( this ) }
                    >
                        <div>

                            <h2>{ i.Name }</h2>
                            <div className="row">
                                <div className="col-3">
                                    <span className="boldified">   Adresse: </span>
                                </div>
                                <div className="col-9">
                                    { i.Strasse }
                                    <br/>
                                    { i.Plz } { i.Ort }
                                    { ( () => {
                                        if (i.Land === 'DE') {
                                            return <div> Deutschland </div>;
                                        }
                                    } )() }
                                </div>
                            </div>
                            { showDetails ?
                                <div className="row">
                                    <div className="col-3">
                                        <span className="boldified"> Telefon: </span>
                                    </div>
                                    <div className="col-9">
                                        <a className="a-no-before" href={ `tel:${ i.Telefon }` }> { i.Telefon } </a>
                                    </div>

                                    <div className="col-3">
                                        <span className="boldified"> Email: </span>
                                    </div>
                                    <div className="col-9">
                                        <a className="a-no-before" href={ `mailto:${ i.Email }` }> { i.Email }</a>
                                    </div>
                                </div>
                                :
                                <div></div>
                            }


                        </div>
                    </div>
                );
                ii++;
            }
            buff = this.state.counter;
            return arr;
        }
        return ( <span>Konnte keine Händlerdaten finden</span> );
    }

    render() {
        if (this.props && this.props.callBackFromParent) {
            if (this.state.counter !== buff) {
                this.props.callBackFromParent( data );
            }
        }
        return (
            <div>
                { this.contactPageBuilder() }
            </div>
        );
    }
}

export default AddressFooter;
