import React from 'react';
import Helmet from 'react-helmet';

const TermsOfUse = () => (
    <div className="container">
        <div className="tos-wrapper">
            <h1>Datenschutzerklärung</h1>
            <h2>I. Allgemein</h2>
            <h2>1. Information über die Erhebung personenbezogener Daten</h2>
            <p>
                Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei
                Nutzung unserer Website. Personenbezogene Daten sind alle Daten, die auf
                Sie persönlich beziehbar sind, z. B. Name, Adresse, E-Mail-Adressen,
                Nutzerverhalten.
            </p>
            <p>
                Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO)
                ist point S Deutschland GmbH, Röhrstr. 7, D-64372 Ober-Ramstadt,
                Deutschland, Telefon: 49(0)6154-639 0, E-Mail: info@point-s.de, Website:
                https://www.point-s.de (siehe unser Impressum). Unseren
                Datenschutzbeauftragten erreichen Sie unter datenschutz@point-s.de oder
                unserer Postadresse mit dem Zusatz „der Datenschutzbeauftragte".
            </p>
            <p>
                Die Nutzung unserer Websitenfunktionen ist grundsätzlich ohne die
                Verarbeitung personenbezogener Daten möglich. Zu den uns technisch von
                ihnen übermittelten (personenbezogenen) Daten beachten Sie bitte weiter
                unten die entsprechenden Ausführungen. Falls wir für einzelne Funktionen
                unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre
                Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im
                Detail über die jeweiligen Vorgänge informieren. Abschließend nennen wir
                auch die festgelegten Kriterien der Speicherdauer.
            </p>
            <h2>2. Zwecke und Rechtsgrundlagen der Verarbeitung</h2>
            <p>
                Soweit nicht anders beschrieben oder konkretisiert, ist der Zweck unserer
                Datenverarbeitungstätigkeiten die Verfolgung eigener Geschäftszwecke. Wir
                nutzen unterschiedliche Rechtsgrundlagen für die Datenverarbeitung.
            </p>
            <ul>
                <li>
                    Erteilen Sie uns eine Einwilligung für bestimmte Verarbeitungsvorgänge
                    personenbezogener Daten, ist Art. 6 I lit. a DSGVO die Rechtsgrundlage
                    (nachfolgend auch nur als "Einwilligung" bezeichnet).
                </li>
                <li>
                    Ist die Verarbeitung personenbezogener Daten zur Anbahnung oder
                    Erfüllung eines Vertrags erforderlich, dessen (mögliche) Vertragspartei
                    die betroffene Person ist, z.B. wenn Sie bei uns Produktanfragen stellen
                    und/oder Waren bestellen und die Datenverarbeitung für die Lieferung der
                    Waren notwendig ist, ist Art. 6 I lit. b DSGVO die Rechtsgrundlage
                    (nachfolgend auch nur als "Vertragserfüllung" bezeichnet).
                </li>
                <li>
                    Ist die Verarbeitung personenbezogener Daten zur Erfüllung einer
                    rechtlichen Verpflichtung erforderlich, z.B. zur Erfüllung steuerlicher
                    Archivierungspflichten, ist Art. 6 I lit. c DSGVO die Rechtsgrundlage.
                </li>
                <li>
                    Ist die Verarbeitung personenbezogener Daten zum Schutz lebenswichtiger
                    Interessen der betroffenen Person oder einer anderen natürlichen Person
                    erforderlich, z.B. wenn ein Besucher eines unserer Lager sich verletzen
                    würde und seine Daten an einen Arzt und/oder Krankenhaus weitergegeben
                    werden müssten, ist Art. 6 I lit. d DSGVO die Rechtsgrundlage.
                </li>
                <li>
                    Die Verarbeitung personenbezogener Daten kann nach Art. 6 I lit. f DSGVO
                    datenschutzrechtlich zulässig sein, wenn sie zur Wahrung eines
                    berechtigten Interesses unseres Unternehmens oder eines Dritten
                    erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten
                    des Betroffenen nicht überwiegen (nachfolgend auch nur als
                    "Interessenabwägung" bezeichnet). Wir betrachten die Durchführung
                    unserer Geschäftstätigkeit zugunsten der Sicherung der Arbeitsplätze
                    unserer Mitarbeiter und sowie zum Wohlergehen Anteilseigner als unser
                    grundsätzliches berechtigtes Interesse. Dies deckt sich auch mit denen
                    durch den Europäischen Gesetzgeber ausdrücklich beschriebenen
                    berechtigten Interessen von Unternehmen. So kann ein berechtigtes
                    Interesse angenommen, wenn die betroffene Person und das Unternehmen
                    sich in einem Kundenverhältnis befinden (Erwägungsgrund 47 Satz 2 der
                    DSGVO) oder personenbezogene Daten zu Zwecken der Direktwerbung
                    verarbeitet werden.
                </li>
            </ul>
            <h2>3. Ihre Rechte</h2>
            <p>
                Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden
                personenbezogenen Daten:
            </p>
            <ul>
                <li>Recht auf Auskunft,</li>
                <li>Recht auf Berichtigung oder Löschung,</li>
                <li>Recht auf Einschränkung der Verarbeitung,</li>
                <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                <li>Recht auf Datenübertragbarkeit.</li>
            </ul>
            <p>
                Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde
                über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu
                beschweren. Ihre Rechte sind im 3. Kapitel der DSGVO geregelt.
            </p>
            <h2>
                4. Widerruf Ihrer Einwilligung oder Widerspruch gegen die Verarbeitung
                Ihrer Daten
            </h2>
            <p>
                Falls Sie uns eine Einwilligung zur Verarbeitung Ihrer Daten erteilt
                haben, können Sie diese jederzeit widerrufen. Nach Eingang Ihres Widerrufs
                werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten,
                Verarbeitungen vor Eingang Ihres Widerrufs bleiben jedoch rechtmäßig.
                Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die
                Interessenabwägung (Art. 6 Abs. 1 lit. f DSGVO) stützen, können Sie
                Widerspruch gegen die Verarbeitung einlegen. Bei Ausübung eines solchen
                Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre
                personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollen.
                Im Falle Ihres Widerspruchs werden wir Ihre Daten nicht mehr weiter
                verarbeiten, es sei denn, es bestehen zwingende schutzwürdige Gründe für
                die Verarbeitung oder die Verarbeitung dient der Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen.
            </p>
            <p>
                Selbstverständlich können Sie der Verarbeitung Ihrer personenbezogenen
                Daten für Zwecke der Werbung und Datenanalyse jederzeit widersprechen.
            </p>
            <p>
                Über Ihren Widerruf bzw. Widerspruch können Sie uns unter den in Ziff. 1
                genannte Kontaktdaten informieren.
            </p>
            <h2>
                5. Empfänger und Kategorien von Empfängern Ihrer personenbezogenen Daten
            </h2>
            <p>
                Informationen über unsere Kunden sind wichtig für uns und helfen uns,
                unser Angebot zu optimieren. Wir geben die Informationen, die wir
                erhalten, ausschließlich in dem im folgenden beschriebenen Umfang an
                Dritte weiter:
            </p>
            <p>
                Verbundene Unternehmen: point S Deutschland GmbH gibt personenbezogene
                Daten (und Nutzerdaten) zum Zwecke der Auftragsabwicklung an verbundene
                Unternehmen der point S Deutschland GmbH und deren Tochtergesellschaften
                weiter.
                <br/>
                Dienstleister: Wir beauftragen andere Unternehmen und Einzelpersonen mit
                der Erfüllung von Aufgaben für uns. Beispiele sind u. a. Sendung von
                Briefen oder E-Mails, Pflege unserer Kundenlisten, Analyse uns‚erer
                Datenbanken, Werbemaßnahmen sowie Kundenservice. Diese Dienstleister haben
                Zugang zu persönlichen Informationen, soweit sie diese zur Erfüllung ihrer
                Aufgaben benötigen. Sie dürfen diese jedoch nicht zu anderen Zwecken
                verwenden.
            </p>
            <h2>6. Kriterien für die Speicherung personenbezogener Daten</h2>
            <p>
                Wir verarbeiten personenbezogene Daten gemäß den in dieser Erklärung
                genannten Rechtsgrundlagen und speichern personenbezogene Daten. Sofern
                die Daten routinemäßig nicht mehr zur Vertragsanbahnung oder
                Vertragserfüllung benötigt werden, erfolgt eine Löschung gemäß der
                jeweiligen gesetzlichen Aufbewahrungsfrist.
            </p>
            <h2>7. Änderung der Datenschutzerklärung</h2>
            <p>
                Aus gesetzlichen und/oder betriebsorganisatorischen Gründen können
                zukünftig, auch kurzfristig, Änderungen bzw. Anpassungen unserer
                Datenschutzerklärung erforderlich werden. Bitte beachten Sie die jeweils
                aktuelle Version unserer Datenschutzerklärung.
            </p>
            <h2>II. Datenverarbeitungen bei einzelnen Nutzungsarten</h2>
            <h2>1. Erhebung personenbezogener Daten bei Besuch unserer Website</h2>
            <p>
                Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich
                nicht registrieren oder uns anderweitig Informationen übermitteln, erheben
                wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server
                übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die
                folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere
                Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten:
            </p>
            <ul>
                <li>IP-Adresse</li>
                <li>Datum und Uhrzeit der Anfrage</li>
                <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                <li>Inhalt der Anforderung (konkrete Seite)</li>
                <li>Zugriffsstatus/HTTP-Statuscode</li>
                <li>jeweils übertragene Datenmenge</li>
                <li>Website, von der die Anforderung kommt</li>
                <li>Browser</li>
                <li>Betriebssystem und dessen Oberfläche</li>
                <li>Sprache und Version der Browsersoftware.</li>
            </ul>
            Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer
            Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich
            um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten
            Browser zugeordnet gespeichert werden und durch welche der Stelle, die den
            Cookie setzt (hier durch uns), bestimmte Informationen zufließen. Cookies
            können keine Programme ausführen oder Viren auf Ihren Computer übertragen.
            Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und
            effektiver zu machen. Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs.
            1 S. 1 lit. f DSGVO ("Interessenabwägung").
            <h2>2. Kontaktaufnahme</h2>
            <p>
                Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular
                werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr
                Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu
                beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir,
                nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die
                Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
            </p>
            <p>
                Sollten Sie bei uns Kunde sein und haben z.B. Fragen oder Beschwerden zu
                Ihrer Bestellung, ist die Rechtsgrundlage der Datenverarbeitung ist Art. 6
                Abs. 1 S. 1 lit. b DSGVO ("Vertragserfüllung"). Sollten Sie kein Kunde bei
                uns sein, ist die Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO
                ("Interessenabwägung").
            </p>
            <h2>3. Weitere Funktionen und Angebote unserer Website</h2>
            <p>
                Neben der rein informatorischen Nutzung unserer Website bieten wir
                verschiedene Leistungen an, die Sie bei Interesse nutzen können. Dazu
                müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir
                zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor
                genannten Grundsätze zur Datenverarbeitung gelten.
            </p>
            <p>
                Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer
                Dienstleister. Diese wurden von uns sorgfältig ausgewählt und beauftragt,
                sind an unsere Weisungen gebunden.
            </p>
            <p>
                Personenbezogene Daten werden nur an Dritte nur übermittelt, sofern eine
                Notwendigkeit im Rahmen der Vertragsabwicklung besteht. Eine weitergehende
                Übermittlung der Daten findet nicht statt bzw. nur dann, wenn Sie dieser
                ausdrücklich zugestimmt haben. Nähere Informationen hierzu erhalten Sie
                bei Angabe Ihrer personenbezogenen Daten oder untenstehend in der
                Beschreibung des Angebotes.
            </p>
            <p>
                Soweit unsere Dienstleister oder Partner ihren Sitz in einem Staat
                außerhalb des Europäischen Wirtschaftsraumen (EWR) haben, informieren wir
                Sie über die Folgen dieses Umstands in der Beschreibung des Angebotes.
            </p>
            <h2>4. Einsatz von Cookies</h2>
            <p>
                Diese Website nutzt folgende Arten von Cookies, deren Umfang und
                Funktionsweise im Folgenden erläutert werden:
            </p>
            <p>Transiente Cookies (dazu b)</p>
            <p>Persistente Cookies (dazu c).</p>
            <p>
                Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser
                schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern
                eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres
                Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner
                wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die
                Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser
                schließen.
            </p>
            <p>
                Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer
                gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die
                Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
            </p>
            <p>
                <b>
                    Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen
                    konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen
                    Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht
                    alle Funktionen dieser Website nutzen können.
                </b>
            </p>
            <p>
                Wir setzen zudem Cookies ein, um Sie für Folgebesuche identifizieren zu
                können, falls Sie über einen Account bei uns verfügen oder um die
                Wirksamkeit von Marketingmaßnahmen beurteilen zu können, z.B. wenn Sie
                über eine Website von einem speziellen Partner oder über eine spezielle
                Werbeanzeige zu uns kommen. Diese Cookies werden bis zu 30 Tage
                gespeichert.
            </p>
            <p>
                Die genutzten Flash-Cookies werden nicht durch Ihren Browser erfasst,
                sondern durch Ihr Flash-Plug-in. Weiterhin nutzen wir HTML5 storage
                objects, die auf Ihrem Endgerät abgelegt werden. Diese Objekte speichern
                die erforderlichen Daten unabhängig von Ihrem verwendeten Browser und
                haben kein automatisches Ablaufdatum. Wenn Sie keine Verarbeitung der
                Flash-Cookies wünschen, müssen Sie ein entsprechendes Add-On installieren,
                z. B. https://addons.mozilla.org/de/firefox/addon/duckduckgo-for-firefox
                oder das Adobe-Flash-Killer-Cookie für Google Chrome. Die Nutzung von
                HTML5 storage objects können Sie verhindern, indem Sie in Ihrem Browser
                den privaten Modus einsetzen. Zudem empfehlen wir, regelmäßig Ihre Cookies
                und den Browser-Verlauf manuell zu löschen.
            </p>
            <h2>5. Nutzung unseres Webshops</h2>
            <p>
                Wenn Sie in unserem Webshop bestellen möchten, ist es für den
                Vertragsabschluss erforderlich, dass Sie Ihre persönlichen Daten (Vor- und
                Nachname, Anschrift, E-Mail-Adresse, Geburtsdatum, Telefonnummer) angeben,
                die wir für die Abwicklung Ihrer Bestellung benötigen. Für die Abwicklung
                der Verträge notwendige Pflichtangaben sind gesondert markiert, weitere
                Angaben sind freiwillig. Die von Ihnen angegebenen Daten verarbeiten wir
                zur Abwicklung Ihrer Bestellung. Rechtsgrundlage hierfür ist Art. 6 Abs. 1
                S. 1 lit. b DSGVO ("Vertragserfüllung").
            </p>
            <p>
                Sie können freiwillig ein Kundenkonto anlegen, durch das wir Ihre
                nachfolgenden Daten für weitere Einkäufe, Ihre Information zu vergangenen
                und laufenden Bestellungen und Ihre Interaktion mit reifenwechsel.de
                speichern können. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1
                lit. a DSGVO („Einwilligung“).
            </p>
            <p>
                Zur Verhinderung unberechtigter Zugriffe Dritter auf Ihre persönlichen
                Daten, insbesondere Finanzdaten, werden Bestellvorgänge verschlüsselt.
            </p>
            <h2>6. Einsatz von Google Maps</h2>
            <p>
                Diese Webseite verwendet Google Maps, um geographische Informationen
                visuell darzustellen. Bei der Nutzung von Google Maps werden von Google
                auch Daten über die Nutzung der Kartenfunktion durch Besucher erhoben,
                verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung
                durch Google können Sie den Google Datenschutzhinweisen entnehmen.
            </p>
            <h2>7. Einsatz von Social-Media</h2>
            <p>
                Wir setzen derzeit folgende Social-Media-Plug-ins ein: Facebook,. Wir
                nutzen dabei die sog. Zwei-Klick-Lösung. Das heißt, wenn Sie unsere Seite
                besuchen, werden zunächst grundsätzlich keine personenbezogenen Daten an
                die Anbieter der Plug-ins weitergegeben. Den Anbieter des Plug-ins
                erkennen an dem jeweiligen Logo. Wir eröffnen Ihnen die Möglichkeit, über
                den Button direkt mit dem Anbieter des Plug-ins zu kommunizieren. Nur wenn
                Sie auf das markierte Feld klicken und es dadurch aktivieren, erhält der
                Plug-in-Anbieter die Information, dass Sie die entsprechende Website
                unseres Online-Angebots aufgerufen haben. Zudem werden die unter dieser
                Erklärung genannten Daten übermittelt. Im Fall von Facebook wird nach
                Angaben der jeweiligen Anbieter in Deutschland die IP-Adresse sofort nach
                Erhebung anonymisiert. Durch die Aktivierung des Plug-ins werden also
                personenbezogene Daten von Ihnen an den jeweiligen Plug-in-Anbieter
                übermittelt und dort (bei US-amerikanischen Anbietern in den USA)
                gespeichert. Da der Plug-in-Anbieter die Datenerhebung insbesondere über
                Cookies vornimmt, empfehlen wir Ihnen, vor dem Klick auf den ausgegrauten
                Kasten über die Sicherheitseinstellungen Ihres Browsers alle Cookies zu
                löschen.
            </p>
            <p>
                Wir haben weder Einfluss auf die erhobenen Daten und
                Datenverarbeitungsvorgänge, noch sind uns der volle Umfang der
                Datenerhebung, die Zwecke der Verarbeitung, die Speicherfristen bekannt.
                Auch zur Löschung der erhobenen Daten durch den Plug-in-Anbieter liegen
                uns keine Informationen vor.
            </p>
            <p>
                Der Plug-in-Anbieter speichert die über Sie erhobenen Daten als
                Nutzungsprofile und nutzt diese für Zwecke der Werbung, Marktforschung
                und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche
                Auswertung erfolgt insbesondere (auch für nicht eingeloggte Nutzer) zur
                Darstellung von bedarfsgerechter Werbung und um andere Nutzer des sozialen
                Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen
                steht ein Widerspruchsrecht gegen die Bildung dieser Nutzerprofile zu,
                wobei Sie sich zur Ausübung dessen an den jeweiligen Plug-in-Anbieter
                wenden müssen. Über die Plug-ins bietet wir Ihnen die Möglichkeit, mit den
                sozialen Netzwerken und anderen Nutzern zu interagieren, so dass wir unser
                Angebot verbessern und für Sie als Nutzer interessanter ausgestalten
                können. Rechtsgrundlage für die Nutzung der Plug-ins ist Art. 6 Abs. 1 S.
                1 lit. f DSGVO ("Interessenabwägung").
            </p>
            <p>
                Die Datenweitergabe erfolgt unabhängig davon, ob Sie ein Konto bei dem
                Plug-in-Anbieter besitzen und dort eingeloggt sind. Wenn Sie bei dem
                Plug-in-Anbieter eingeloggt sind, werden Ihre bei uns erhobenen Daten
                direkt Ihrem beim Plug-in-Anbieter bestehenden Konto zugeordnet. Wenn Sie
                den aktivierten Button betätigen und z. B. die Seite verlinken, speichert
                der Plug-in-Anbieter auch diese Information in Ihrem Nutzerkonto und teilt
                sie Ihren Kontakten öffentlich mit. Wir empfehlen Ihnen, sich nach Nutzung
                eines sozialen Netzwerks regelmäßig auszuloggen, insbesondere jedoch vor
                Aktivierung des Buttons, da Sie so eine Zuordnung zu Ihrem Profil bei dem
                Plug-in-Anbieter vermeiden können.
            </p>
            <p>
                Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer
                Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den im Folgenden
                mitgeteilten Datenschutzerklärungen dieser Anbieter. Dort erhalten Sie
                auch weitere Informationen zu Ihren diesbezüglichen Rechten und
                Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre.
            </p>
            <p>
                Adressen der jeweiligen Plug-in-Anbieter und URL mit deren
                Datenschutzhinweisen:
            </p>
            Facebook Inc., 1601 S California Ave, Palo Alto, California 94304, USA;
            <a href="http://www.facebook.com/policy.php">
                http://www.facebook.com/policy.php
            </a>
            ; weitere Informationen zur Datenerhebung:
            <a href="http://www.facebook.com/help/186325668085084">
                http://www.facebook.com/help/186325668085084
            </a>
            ,
            <a href="http://www.facebook.com/about/privacy/your-info-on-other#applications">
                http://www.facebook.com/about/privacy/your-info-on-other#applications
            </a>{ ' ' }
            sowie
            <a href="http://www.facebook.com/about/privacy/your-info#everyoneinfo.">
                http://www.facebook.com/about/privacy/your-info#everyoneinfo.
            </a>{ ' ' }
            Facebook hat sich dem EU-US-Privacy-Shield unterworfen,
            https://www.privacyshield.gov/EU-US-Framework. Google Inc., 1600
            Amphitheater Parkway, Mountainview, California 94043, USA;
            <a href="https://www.google.com/policies/privacy/partners/?hl=de">
                https://www.google.com/policies/privacy/partners/?hl=de"
            </a>{ ' ' }
            Google hat sich dem EU-US-Privacy-Shield unterworfen,
            https://www.privacyshield.gov/EU-US-Framework.
            <p>Stand Mai 2018</p>
        </div>
        <Helmet>
            <title>Nutzungsbedingungen</title>
            <meta
                name="description"
                content="This is a proof of concept for React SSR"
            />
        </Helmet>
    </div>
);

export default TermsOfUse;
