import React from 'react';
import Helmet from 'react-helmet';

const About = () => (
    <div className="about-wrapper">
        <h2>This is the About page</h2>
        <Helmet>
            <title>About Page</title>
            <div className="container">
            <meta
                name="description"
                content="This is a proof of concept for React SSR"
            />
            </div>
        </Helmet>
    </div>
);

export default About;
