// react
import React from 'react';
import moment from 'moment';
// redux
import { connect } from 'react-redux';
import * as actionCreators from '../../../../../../redux-store/actions';

import ShoppingCart from '../../ShoppingCart';
import AssemblyDate from './AssemblyDate';
import BillingAddress from './BillingAddress';
import CheckoutConfirmation from './CheckoutConfirmation';
import BranchChecker from './BranchChecker';
import CheckoutConfirmed from './CheckoutConfirmed';
import CheckoutFailed from './CheckoutFailed';
import axios from 'axios';

let passedvalues = '';

import { postOrder, postOrderSSR } from ' ../../../redux-store/actions/order';
import Breadcrumb from '../../../../shared/breadcrumb/Breadcrumb';

class CheckoutProcess extends React.Component {
    constructor( props ) {
        super( props );
        // initial state
        this.state = {
            tyres: this.props.tyres,
            userdata: {
                dates: [],
                fields: []
            },
            steps: '',
            step: 1,
            passedvalues: ''
        };
        this.myCallback = dataFromChild => {
            this.setState( dataFromChild );
        };

        this.changeStateByBreadcrumb = dataFromChild => {
            this.setState( {
                step: dataFromChild.step,
                steps: dataFromChild.steps
            } );
        };

        this.myLastCallback = dataFromChild => {
            this.setState( dataFromChild );
            this.postOrder( dataFromChild );
        };

        this.siteChecker = props => {
            let buffer;
            if (this.state.passedvalues !== '') {
                switch (this.state.steps) {
                    case 'BranchChecker':
                        buffer = (
                            <BranchChecker
                                props={ props }
                                userdata={ this.state }
                                firmData={ this.props.firmData }
                                callBackFromParent={ this.myCallback }
                            />
                        );
                        break;
                    case 'AssemblyDate':
                        buffer = (
                            <AssemblyDate
                                props={ props }
                                userdata={ this.state }
                                callBackFromParent={ this.myCallback }
                            />
                        );
                        break;
                    case 'BillingAddress':
                        buffer = (
                            <BillingAddress
                                props={ props }
                                userdata={ this.state }
                                callBackFromParent={ this.myCallback }
                            />
                        );
                        break;
                    case 'CheckoutConfirmation':
                        buffer = (
                            <CheckoutConfirmation
                                props={ props }
                                userdata={ this.state }
                                callBackFromParent={ this.myLastCallback }
                            />
                        );
                        break;

                    case 'CheckoutConfirmed':
                        buffer = <CheckoutConfirmed props={ props } userdata={ this.state }/>;
                        break;

                    case 'CheckoutFailed':
                        buffer = (
                            <CheckoutFailed
                                props={ props }
                                userdata={ this.state }
                                callBackFromParent={ this.myCallback }
                            />
                        );
                        break;
                    default:
                        buffer = false;
                }
            } else {
                buffer = (
                    <BranchChecker
                        props={ props }
                        userdata={ this.state }
                        firmData={ this.props.firmData }
                        callBackFromParent={ this.myCallback }
                    />
                );
            }
            return buffer;
        };
    }

    componentDidMount() {
        if (this.props.tyres.length === 0) {
            this.props.history.push( '/shoppingcart' );
        }
    }

    // post order
    postOrder( data ) {
        let requestUrl = '/api/postOrder';
        let requestBody = {};
        let order = {};
        let proposedDates = [];
        let chosenDates = [];
        let termine = [];
        let chosenTimes = [];
        let proposedDate1 = '';
        let proposedDate2 = '';
        let proposedDate3 = '';
        let comment = '';
        let name = '';
        let surname = '';
        let email = '';
        let phone = '';
        let mobile = '';
        let firm = '';
        // address Object
        let street = '';
        let housenumber = '';
        let zip = '';
        let city = '';
        let address = {};
        // end address object
        // Anfrage an eios geht momentan nicht ohne "vehicle":{}
        let vehicle = {};
        order = {
            ...order,
            vehicle
        };
        if (data) {
            // start chosen dates
            let termin1 = null;
            let termin2 = null;
            let termin3 = null;

            for (let i = 0; i <= 2; i++) {
                if (data.userdata.dates.length !== 0 && data.userdata.dates[i]) {
                    proposedDates[i] = data.userdata.dates[i];
                    chosenDates[i] = proposedDates[i].chosenDate;
                    let day = chosenDates[i].split( '.' )[0];
                    let month = chosenDates[i].split( '.' )[1];
                    let year = chosenDates[i].split( '.' )[2];
                    chosenTimes[i] = proposedDates[i].chosenTime;
                    termine[i] = day + '-' + month + '-' + year;
                    proposedDates[i] = termine[i] + ' ' + chosenTimes[i];
                }
            }
            let proposedDate1 = proposedDates[0];
            let proposedDate2 = proposedDates[1];
            let proposedDate3 = proposedDates[2];
            order = {

                ...order,

                proposedDate1

            };
            order = {

                ...order,

                proposedDate2

            };
            order = {

                ...order,

                proposedDate3

            };
            if (data.userdata.fields.vendormessage) {
                let comment = data.userdata.fields.vendormessage;
                order = {
                    ...order,
                    comment
                };
            }
            // end chosen dates
            if (data.userdata.fields) {
                // comment
                if (data.userdata.fields.vendormessage) {
                    comment = data.userdata.fields.vendormessage;
                }
                // name
                if (data.userdata.fields.firstname) {
                    name = data.userdata.fields.firstname;
                    order = {
                        ...order,
                        name
                    };
                }
                // surname
                if (data.userdata.fields.name) {
                    surname = data.userdata.fields.name;
                    order = {
                        ...order,
                        surname
                    };
                }
                // email
                if (data.userdata.fields.email) {
                    email = data.userdata.fields.email;
                    order = {
                        ...order,
                        email
                    };
                }
                // phone
                if (data.userdata.fields.phone) {
                    phone = data.userdata.fields.phone;
                    order = {
                        ...order,
                        phone
                    };
                }

                // address
                if (data.userdata.fields.adress) {
                    street = data.userdata.fields.adress;
                    address = {
                        ...address,
                        street
                    };
                    housenumber = street.replace( /\D/g, '' );
                    address = {
                        ...address,
                        housenumber
                    };
                }
                if (data.userdata.fields.zipcode) {
                    zip = data.userdata.fields.zipcode;
                    address = {
                        ...address,
                        zip
                    };
                }
                if (data.userdata.fields.city) {
                    city = data.userdata.fields.city;
                    address = {
                        ...address,
                        city
                    };
                }
                order = {
                    ...order,
                    address
                };
            }

            // start positions
            let positions = [];
            if (data.tyres) {
                let type = 'PRODUCTID';
                let vat = 0.19;
                data.tyres.forEach( selectedTyre => {
                    let pos = {};
                    let servicepos = {};
                    let position = String( selectedTyre.productId );
                    let quantity = String( selectedTyre.amount );
                    let price = selectedTyre.price;
                    pos.type = 'PRODUCTID';
                    pos.vat = vat;
                    pos.position = position;
                    pos.quantity = quantity;
                    pos.price = price;
                    servicepos.type = "SERVICE";
                    servicepos.position = "MONTAGE";
                    servicepos.quantity = quantity;
                    servicepos.price =  selectedTyre.assemblyCosts ? selectedTyre.assemblyCosts : 0;
                    servicepos.vat = vat;
                    positions.push( pos );
                    positions.push (servicepos);
                } );
                order = {
                    ...order,
                    positions
                };
            }
            // end positions
            order = JSON.stringify( order );
            requestBody = order;
            axios
                .post( requestUrl, requestBody, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                } )
                .then( response => {
                    if (response.status === 200 && response.data.status === 'OK') {
                        console.log( 'order sent successfully' );
                        this.props.onResetSc();
                    } else {
                        var err = new Error( response.statusText );
                        err.code = response.status;
                        this.props.history.push( '/CheckoutFailed' );
                        throw err;
                    }
                } )
                .catch( err => {
                    console.log( 'error', err );
                    this.props.history.push( '/CheckoutFailed' );
                } );
        }
    }

    render() {
        return <div>
            <Breadcrumb step={ this.state.step } callBackFromParent={ this.changeStateByBreadcrumb }/>
            { this.siteChecker( this.props ) }
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        tyres: state.shoppingcart.shoppingCartTyres,
        firmData: state.firmData,
        sent: state.order.sent,
        timestamp: state.shoppingcart.timestamp
    };
};

const mapDispatchToProps = {
    onRemoveButtonHandler: idxx => actionCreators.removeCartTire( idxx ),
    onResetSc: () => actionCreators.resetSc(),
    onResetSent: () => actionCreators.orderSent()
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( CheckoutProcess );
