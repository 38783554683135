import * as actionTypes from "../actions/actionTypes";

const initialState = {
  shoppingCartTyres: [],
  amountSelectionChanged: null,
  totalCount: 0,
  totalPrice: 0,
  buttonState: null,
  timestamp: null,
  assemblyCost: 0,
  assemblyCosts: []
};

const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case actionTypes.SET_SELECTED_CART_selectedTyre:
      let _shoppingCartTyres = state.shoppingCartTyres.slice(0);
      let arr = [];

      if (_shoppingCartTyres.length == 0) {
        let _tire = {};
        _tire.image = action.selectedTyre.image;
        (_tire.producer = action.selectedTyre.producer);
        (_tire.name = action.selectedTyre.name);
        _tire.assemblyCostSteel = action.selectedTyre.response.assemblyCostSteel;
        _tire.assemblyCostAlloy = action.selectedTyre.response.assemblyCostAlloy;
        _tire.assemblyCostRft = action.selectedTyre.response.assemblyCostRft;
        (_tire.price = action.selectedTyre.B2CPrice);
        (_tire.productId = action.selectedTyre.productId);
        (_tire.tread = action.selectedTyre.tread);
        (_tire.description = action.selectedTyre.description);
        _tire.amount = action.amount;
        _tire.season = action.selectedTyre.seasons;
        _tire.computedAmount = action.amount;
        _tire.image = action.selectedTyre.productImageUrl;
        _tire.brand = action.selectedTyre.brand;
        _tire.tyreLabel = action.selectedTyre.tyreLabel;
        _tire.vehicleType = action.selectedTyre.vehicleTypes;
        _shoppingCartTyres.push(_tire);
      } else {
        _shoppingCartTyres.forEach(element => {
          arr.push(element.productId);
        });
        if (arr.includes(action.selectedTyre.productId)) {
          _shoppingCartTyres.forEach(element => {
            if (element.productId == action.selectedTyre.productId) {
              element.amount = action.amount;
            }
          });
        } else {
          let _tire = {};
          _tire.image = action.selectedTyre.image;
          (_tire.producer = action.selectedTyre.producer);
          (_tire.name = action.selectedTyre.name);
          (_tire.tread = action.selectedTyre.tread);
          (_tire.description = action.selectedTyre.description);
          (_tire.price = action.selectedTyre.B2CPrice);
          (_tire.productId = action.selectedTyre.productId);
          (_tire.image = action.selectedTyre.productImageUrl);
          (_tire.amount = action.amount);
          _tire.assemblyCostSteel = action.selectedTyre.response.assemblyCostSteel;
          _tire.assemblyCostAlloy = action.selectedTyre.response.assemblyCostAlloy;
          _tire.assemblyCostRft = action.selectedTyre.response.assemblyCostRft;
          _tire.season = action.selectedTyre.seasons;
          _tire.brand = action.selectedTyre.brand;
          _tire.computedAmount = action.amount;
          _tire.tyreLabel = action.selectedTyre.tyreLabel;
          _tire.vehicleType = action.selectedTyre.vehicleTypes;
          _shoppingCartTyres.push(_tire);
        }
      }
      return Object.assign({}, state, {
        shoppingCartTyres: _shoppingCartTyres
      });

    case actionTypes.SET_SELECTED_AMOUNT:
      let _scTyres = state.shoppingCartTyres.slice(0);
      let _arr = [];
      _scTyres.forEach(element => {
        _arr.push(element.productId);
      });

      if (_arr.includes(action.tyreId)) {
        _scTyres.forEach(element => {
          if (element.productId == action.tyreId) {
            element.computedAmount = action.computedAmount;
          }
        });
      }

      return Object.assign({}, state, {
        shoppingCartTyres: _scTyres
      });


    case actionTypes.CREATE_TIME_STAMP:
      let _timestamp = new Date();
      _timestamp = _timestamp.setMinutes(_timestamp.getMinutes() + 24);
      return Object.assign({}, state, {
        timestamp: _timestamp
      });

    case actionTypes.SET_SHOPPING_CART_TIRE_CHANGED:
      let tyres = state.shoppingCartTyres;
      tyres.forEach(function(tyre) {
        if (tyre.productId === action.selectedTyre.productId) {
          tyre = action.selectedTyre;
          // if (selectedTyre.assemblyCosts === 'Runflat') {
          //   selectedTyre.assemblyCost = selectedTyre.assemblyCostRft;
          // }
        }
      });
      return Object.assign({}, state, {
        shoppingCartTyres: tyres
      });

    case actionTypes.SET_SHOPPING_CART_TIRE_SELECTED_AMOUNT:
      let __shoppingCartTyres = state.shoppingCartTyres;
      __shoppingCartTyres.forEach(function(element) {
        if (element.productId === action.tyreId) {
          element.amount = action.amount;
          element.computedAmount = action.amount;
        }
      });
      return Object.assign({}, state, {
        shoppingCartTyres: __shoppingCartTyres
      });

    case actionTypes.DELETE_CART_TIRE:
      let updatedResults = state.shoppingCartTyres.slice(0);
      let indx = action.cartTireIndex;
      if (indx >= 0) {
        updatedResults.splice(indx, 1);
        return Object.assign({}, state, {
          shoppingCartTyres: updatedResults
        });
      }

    case actionTypes.AMOUNT_SELECTION_CHANGED:
      let _amountSelectionChanged = state.amountSelectionChanged;
      _amountSelectionChanged = action.amountSelectionChanged;
      return Object.assign({}, state, {
        amountSelectionChanged: _amountSelectionChanged
      });

    case actionTypes.SAVE_BUTTON_STATE:
      let _buttonState = state.buttonState;
      _buttonState = action.buttonState;
      return Object.assign({}, state, {
        buttonState: _buttonState
      });

    case actionTypes.RESET_SC:
      let scTyres = state.shoppingCartTyres;
      let total_Price = state.totalPrice;
      let total_Count = state.totalCount;
      let time_Stamp = state.timestamp;
      let assembly_Cost = state.assemblyCost;
      scTyres = [];
      total_Price = 0;
      total_Count = 0;
      time_Stamp = null;
      return Object.assign({}, state, {
        totalCount: total_Count,
        totalPrice: total_Price,
        shoppingCartTyres: scTyres,
        timestamp: time_Stamp,
        assemblyCost: assembly_Cost
      });

    case actionTypes.AMOUNT_COUNT:
      let _totalCount = state.totalCount;
      let count = 0;
      state.shoppingCartTyres.map(tire => {
        count = parseInt(count) + parseInt(tire.amount);
        _totalCount = count;
      });
      if (state.shoppingCartTyres.length === 0) {
        _totalCount = 0;
      }
      return Object.assign({}, state, {
        totalCount: _totalCount
      });
    case actionTypes.SELECTED_ASSEMBLY_COST:
      let ___shoppingCartTyres = state.shoppingCartTyres;
      ___shoppingCartTyres.forEach(function(element) {
        if (element.productId === action.tireId) {
          element.assemblyCosts = action.assemblyCosts;
          element.selectedAssemblyCosts = action.selectedAssemblyCosts;
        }
      });
      return Object.assign({}, state, {
        shoppingCartTyres: ___shoppingCartTyres
      });
    case actionTypes.ASSEMBLY_COSTS:
      let _assemblyCosts = state.assemblyCosts;
      _assemblyCosts = action.assemblyCosts;
      return Object.assign({}, state, {
        assemblyCosts: _assemblyCosts
      });
    case actionTypes.UPDATE_TOTAL_PRICE:
      let _totalPrice = state.totalPrice;
      let total = null;
      let totalAssemblyCosts = null;
      state.shoppingCartTyres.map(tire => {
        total += tire.price * tire.amount;
        if (tire.assemblyType) {
          if (tire.assemblyType === 'Stahlfelge') {
            tire.assemblyCosts = tire.assemblyCostSteel
          } else if (tire.assemblyType === 'Runflat') {
            tire.assemblyCosts = tire.assemblyCostRft
          } else if (tire.assemblyType === 'Leichtmetall') {
            tire.assemblyCosts = tire.assemblyCostAlloy
          } else {
            tire.assemblyCosts = 0
          }
          totalAssemblyCosts = tire.assemblyCosts * tire.amount;
          total += totalAssemblyCosts;
        }
      });
      _totalPrice = total;
      if (state.shoppingCartTyres.length === 0) {
        _totalPrice = 0;
      }
      return Object.assign({}, state, {
        totalPrice: _totalPrice
      });


  }
  return state;
};

export default reducer;
