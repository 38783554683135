import * as actionTypes from "./actionTypes";

export const setTireFitting = () => {
  return {
    type: actionTypes.SET_TIRE_FITTING,
    tireFitting: true
  };
};

export const resetTireFitting = () => {
  return {
    type: actionTypes.RESET_TIRE_FITTING,
    tireFitting: false
  };
};

export const setWheelAlignment = () => {
  return {
    type: actionTypes.SET_WHEEL_ALIGNMENT,
    wheelAlignment: true
  };
};

export const resetWheelAlignment = () => {
  return {
    type: actionTypes.RESET_WHEEL_ALIGNMENT,
    wheelAlignment: false
  };
};
