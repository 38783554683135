import React from 'react';
import {connect} from 'react-redux';
import {carDetailAction, carDetailActionSSR} from '../../../../../redux-store/actions/carDetail';
import {getCarModels} from '../../../../../redux-store/api';
import {Link, Redirect} from 'react-router-dom';
import Dropdown from '../../shared/dropDown/DropDown';

let scrollbar;
let result;

class CarDetails extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         visible: false,
         models: '',
         filteredModels: [],
         initialModels: [],
         loading: false,
         scrollBarClass: '',
         selectedCarDetails: null,
         ps: 'PS',
         fuel: 'Kraftstoff',
         year: 'Baujahr',
         redirect: false,
      };
      this.domElement = React.createRef();

      this.filterForPS = this.filterForPS.bind(this);
      this.filterForFuel = this.filterForFuel.bind(this);
      this.filterForYear = this.filterForYear.bind(this);
   }

   resetButton() {
      this.props.resetButton();
   }

   fetchCarModels(requestBody) {
      this.setState({loading: true});

      setTimeout(
         function () {
            getCarModels(requestBody)
            .then(
               (res) => {
                  if ((res == {}) || (res.Error != '' && res.Error != null && res.Error != undefined)) {
                     console.log('Error fetching cars', res.Error);
                     this.setState({
                        redirect: true,
                        loading: false
                     });
                  } else {
                     let newState = this.state;
                     newState.models = res;
                     newState.filteredModels = res;
                     newState.initialModels = res;
                     newState.loading = false;

                     this.setState(newState);
                  }

               }
            )
            .catch(error => {
               console.log('Error fetching cars', error);
               this.setState({
                  error: error,
                  redirect: true,
                  loading: false
               });
            });
         }
         .bind(this),
         500
      );
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.buttonpushed == 'clicked') {
         if (this.state.typedetails != null) {
            let _state = this.state;
            _state.typedetails = null;
            this.setState(_state);
         }
         this.resetButton('3');
      }
      let selectedCar = nextProps.selectedCar;
      let _state = this.state;
      _state.selectedCar = selectedCar;
      _state.selectedCarDetails = nextProps.selectedCarDetails;
      this.setState(_state);
      if (this.state.selectedCar != null) {
         this.props.callbackFunction({visible: true});
      }
      if (selectedCar !== null && selectedCar.type) {
         this.fetchCarModels({
            carId: selectedCar.type[0].id,
            hsn: nextProps.selectedCar.type[0].hsn,
            tsn: nextProps.selectedCar.type[0].tsn
         });
      } else if (selectedCar !== null) {
         this.fetchCarModels({selectedCar: selectedCar});
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      this.scrollBarVisible();
   }

   buildyearoptions() {
      let max = 0;
      let min = new Date().getFullYear()
      .toString();
      this.state.initialModels.forEach(
         function (el) {
            let parts = el.constructionYear.split('-');
            if (!parts[1].length || parts[1] == '0') {
               parts[1] = new Date().getFullYear()
               .toString();
            }
            if (parts[0] < min) {
               min = parts[0];
            }
            if (parts[1] > max) {
               max = parts[1];
            }

         }
      );
      let options = [];
      if (min && max) {
         for (let i = min; i <= max; i++) {
            options.push(i);
         }
      }
      return (options);
   }

   buildfueloptions() {
      let fuelTypes = [];
      this.state.models.map((model, index) => {
            fuelTypes.push(model.fuel);
         }
      );
      var uniqueAndSorted = [...new Set(fuelTypes)].sort();
      return (uniqueAndSorted);
   }

   scrollBarVisible() {
      if (this.domElement.current) {
         if (this.state.scrollBarClass != 'scrollbar-visible' && this.domElement.current.scrollHeight > this.domElement.current.clientHeight) {
            this.setState({scrollBarClass: 'scrollbar-visible'});
         }
      }
   }

   buildpsoptions() {
      let psTypes = [];
      this.state.models.map((model, index) => {
            psTypes.push(model.ps + 'PS / ' + model.kw + 'kW');
         }
      );
      var uniqueAndSorted = [...new Set(psTypes.sort(this.sortNumeric))];
      return uniqueAndSorted;
   }

   filterYears(args) {
      let parts = args.car.constructionYear.split('-');
      if (!parts[1].length || parts[1] == '0') {
         parts[1] = new Date().getFullYear()
         .toString();
      }
      if (!args.year.length) {
         return true;
      }
      if ((Number(args.year) >= Number(parts[0])) && (Number(args.year) <= Number(parts[1]))) {
         return true;
      }
      return false;
   }

   sortNumeric(x, y) {
      let partsX = x.split('P');
      let partsY = y.split('P');
      return partsX[0] - partsY[0];
   }

   filterPS(args) {
      let parts = args.ps.split('P');
      if (!args.ps.length) {
         return true;
      }
      if (parts[0] == args.car.ps) {
         return true;
      }
      return false;
   }

   filterForFuel(e) {
      this.setState({
         fuel: e != '' ? e : 'Kraftstoff'
      }, () => this.setFilteredModels());
   }

   filterForYear(e) {
      this.setState({
         year: e != '' ? e : 'Baujahr'
      }, () => this.setFilteredModels());
   }

   filterForPS(e) {
      this.setState({
         ps: e != '' ? e : 'PS'
      }, () => this.setFilteredModels());
   }

   setFilteredModels() {
      let filteredModels = this.state.models;

      // Fuel
      if (this.state.fuel !== 'Kraftstoff') {
         let regex = new RegExp(this.state.fuel, 'i');
         filteredModels = filteredModels.filter(model => (model.fuel.match(regex)));
      }

      // Year
      if (this.state.year !== 'Baujahr') {
         filteredModels = filteredModels.filter(model => this.filterYears({
            car: model,
            year: this.state.year
         }));
      }

      // PS
      if (this.state.ps !== 'PS') {
         filteredModels = filteredModels.filter(model => this.filterPS({
            car: model,
            ps: this.state.ps
         }));
      }

      this.setState({
         filteredModels
      }, console.log(filteredModels));
   }

   render() {
      let loadingBoxes = [];
      for (let i = 0; i < 10; i++) {
         loadingBoxes.push(
            <div className={'loading boxitem linkbox '} key={i + "_loading_box"}>
               <div className="row">
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 tablecontent">
                     <div className="mobile-car-overview loading-animation "></div>
                     <div className="d-block d-lg-none loading-animation"></div>
                  </div>
                  <div className="col-md-2 col-lg-2 col-xl-2 tablecontent d-none d-md-block">
                     <div className={'loading-animation'}/>
                  </div>
                  <div className="col-lg-2 col-xl-2 tablecontent d-none d-none d-lg-block initcap ">
                     <div className={'loading-animation'}/>
                  </div>
                  <div className="col-lg-2 col-xl-2 tablecontent d-none d-lg-block">
                     <div className={'loading-animation'}/>
                  </div>
                  <div className="col-lg-1 col-xl-1 tablecontent d-none d-lg-block">
                     <div className={'loading-animation'}/>
                  </div>
                  <div className="col-md-1 col-lg-1 col-xl-1 tablecontent d-none d-md-block">
                     <div className={'loading-animation'}/>
                  </div>
                  <div className="col-md-2 col-lg-2 col-xl-2 tablecontent d-none d-md-block initcap">
                     <div className={'loading-animation'}/>
                  </div>
               </div>
            </div>
         );
      }

      if (this.state.redirect) {
         return <Redirect to={'/noRimsFound'}/>;
      } else {
         return (
            <div className="car-detail-wrapper">
               <div className="row">
                  <div
                     className={'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 pb-3 pb-lg-0 px-md-0 px-lg-3 pr-md-0'}>
                     <div className={'bg-milky p-3 content-height'}>
                        <div className={'separator'}>
                           <h3>Ihre Auswahl</h3>
                        </div>
                        <div className={'text-center'}>
                           {this.state.selectedCarDetails ?
                              <img className="car-image"
                                   src={this.state.selectedCarDetails.carImage ? this.state.selectedCarDetails.carImage : '/Fahrzeug_platzhalter.png'}/>
                              :
                              <img className={'car-image'} src={'/Fahrzeug_platzhalter.png'}/>
                           }
                        </div>
                        <div className={'mb-4'}>
                           <h4>Marke:</h4>
                           <div>
                              {this.state.models[0] ? this.state.models[0].manufacturer : ''}
                           </div>
                           {this.state.selectedCarDetails ?
                              <div>
                                 <h4>Modellreihe:</h4>
                                 <div>
                                    {this.state.selectedCarDetails ? this.state.selectedCarDetails.type : '-'}
                                 </div>
                                 {this.state.selectedCarDetails.yearFrom ?
                                    <div>
                                       <h4>Baujahr:</h4>
                                       <div>
                                          {this.state.selectedCarDetails.yearTo > 0 ?
                                             <div>
                                                {this.state.selectedCarDetails.yearFrom} - {this.state.selectedCarDetails.yearTo}
                                             </div>
                                             :
                                             <div>
                                                ab {this.state.selectedCarDetails.yearFrom}
                                             </div>
                                          }
                                       </div>
                                    </div>
                                    :
                                    <div>

                                    </div>
                                 }
                              </div>
                              :
                              <div>
                              </div>
                           }
                        </div>
                        <div className={'pb-4'}>
                           {(process.env.REACT_APP_CLUB == "ps-rims" || process.env.REACT_APP_CLUB == "am-rims") ?
                              <a href={'/'} className="btn btn-block btn-outline-primary">
                                 Fahrzeug ändern
                              </a>
                              : (process.env.REACT_APP_CLUB == "points" || process.env.REACT_APP_CLUB == "automeister") ?
                                 <a href={'/rimconfigurator'} className="btn btn-block btn-outline-primary">
                                    Fahrzeug ändern
                                 </a>
                                 :
                                 <a href={'/'} className="btn btn-block btn-outline-primary">
                                    Fahrzeug ändern
                                 </a>
                           }
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 px-md-0 content-height">
                     <div className={' bg-milky'}>
                        <div className={'col-lg' ? '' : 'sticky-top'}>
                           <div className={'pt-3 ' + ('col-lg' ? ' boxitem' : '')}>
                              <h3>Typ & Motorisierung</h3>
                           </div>
                           <div className="boxitem">
                              <div className="row">
                                 <div className="col" id="ps">
                                    <Dropdown onChangeCallBack={this.filterForPS} name={'ps'}
                                              items={this.state.models ? this.buildpsoptions() : ''}
                                              defaultValue={this.state.ps} defaultValueOnly={true}/>
                                 </div>
                                 <div className="col" id="fuelSelect">
                                    <Dropdown onChangeCallBack={this.filterForFuel} name={'fuel'}
                                              items={this.state.models ? this.buildfueloptions() : ''}
                                              defaultValueOnly={true}
                                              defaultValue={this.state.fuel}/>
                                 </div>
                                 <div className="col" id="yearSelect">
                                    <Dropdown onChangeCallBack={this.filterForYear}
                                              name={'years'}
                                              items={this.state.models ? this.buildyearoptions() : ''}
                                              defaultValueOnly={true}
                                              defaultValue={this.state.year}/>
                                 </div>
                              </div>
                           </div>
                           <div className="boxitem d-none d-lg-block">
                              <div className="row">
                                 <div className="col-sm-3 ">
                                    <div className="tableheading">Modell</div>
                                 </div>
                                 <div className="col-sm-2">
                                    <div className="tableheading">Baujahr</div>
                                 </div>
                                 <div className="col-sm-2">
                                    <div className="tableheading">Kraftstoff</div>
                                 </div>
                                 <div className="col-sm-2">
                                    <div className="tableheading">Leistung</div>
                                 </div>
                                 <div className="col-sm-1">
                                    <div className="tableheading">Zoll</div>
                                 </div>
                                 <div className="col-sm-2">
                                    <div className="tableheading d-none d-sm-block">LK</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div ref={this.domElement} className={'scrollable'}>
                           <div className={this.state.scrollBarClass}>
                              {this.state.loading == false ?
                                 <div>
                                    {this.state.filteredModels.map((model, index) => {
                                       let yearparts = model.constructionYear.split('-');
                                       return (
                                          <Link to={this.props.rimPath + '/car/' + model.id}
                                                key={model.id + '_' + index + '_carLink'}>
                                             <div className="boxitem linkbox" key={'model' + index}>
                                                <div className="row">
                                                   <div
                                                      className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 tablecontent clickable">
                                                      <div
                                                         className={'mobile-car-overview'}>{model.version}</div>
                                                      <div
                                                         className={'d-block d-lg-none'}>{model.constructionYear}</div>
                                                      <div>{model.drive}</div>
                                                   </div>
                                                   <div
                                                      className="col-md-2 col-lg-2 col-xl-2 tablecontent clickable d-none d-md-block">
                                                      {yearparts[1] ? (yearparts[0] + ' - ' + yearparts[1]) : ('ab ' + yearparts[0])}
                                                   </div>
                                                   <div
                                                      className="col-lg-2 col-xl-2 tablecontent clickable d-none d-none d-lg-block initcap">
                                                      {model.fuel}
                                                   </div>
                                                   <div
                                                      className="col-lg-2 col-xl-2 tablecontent clickable d-none d-none d-lg-block">
                                                      {model.ps}PS/{model.kw}KW
                                                   </div>
                                                   <div
                                                      className="col-lg-1 col-xl-1 tablecontent clickable d-none d-none d-lg-block">
                                                      ab {Number(model.cocMinSize)}"
                                                   </div>
                                                   <div
                                                      className="col-md-1 col-lg-1 col-xl-1 tablecontent clickable d-none d-md-block">
                                                      {model.holeCircle}"
                                                   </div>
                                                   {/* Wird nur bis Tabletansicht angezeigt */}
                                                   <div
                                                      className="col-6 col-xs-6 col-sm-6 col-md-4 tablecontent clickable d-block d-lg-none text-right">
                                                      <div className={'mobile-car-overview'}>
                                                         {model.ps}PS/{model.kw}KW
                                                      </div>
                                                      <div className={'initcap'}>{model.fuel}</div>
                                                   </div>
                                                </div>
                                             </div>
                                          </Link>
                                       );
                                    })
                                    }
                                 </div>
                                 :
                                 <div>
                                    {loadingBoxes}
                                 </div>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         );
      }
   }

   _render() {
      let car = this.props.car;
      let content = '';
      // TODO: error handling
      if (this.props.isLoading || car === undefined) {
         content = 'loading';
      } else {
         content = (
            <div className={'car-detail-wrapper full-width-wrapper'}>
               <div className={'container-full-width'}>
                  <div className={'car-detail-text'}>
                     <b><h4>{car.manufacturer}</h4>
                        <hr/>
                        {car.modelId}
                        <hr/>
                        {car.type}
                        <hr/>
                     </b>
                     <button className={'btn btn-primary'}>Fahrzeug ändern</button>
                     <hr/>
                  </div>
               </div>
            </div>
         );
      }
      return content;
   }
}

CarDetails.serverFetch = carDetailActionSSR;

const mapStateToProps = state => ({
   xxcar: state.car.car
});

const mapDispatchToProps = {
   carDetailAction
};

export default connect(mapStateToProps, mapDispatchToProps)(CarDetails);
