import { getTyreDetails, getTyres } from '../api';
import * as actionTypes from "./actionTypes";


const storeTyres = tyres => ({
  type: actionTypes.STORE_selectedTyreS,
  tyres: tyres
});

export const loadTyres = () => ({
  type: actionTypes.LOAD_selectedTyreS
});


const fetchTyresError = ( error ) => ( {
  type: actionTypes.FETCH_selectedTyreS_ERROR,
  error : error
} );


// SSR Search
export const fetchTyresSSR = (requestBody, env, page, id, sort,club) => dispatch => {
  const values = JSON.parse(
    Buffer.from(requestBody.searchString, "base64").toString("utf8")
  );
  let environment = env;
  let pageSize = 25;
  let debitorNr = id;
  let pageNr = page;
  let width = values.length;
  let height = values.height;
  let diameter = values.diameter;
  let sortValue = sort;
  if (sortValue === {} || sortValue === undefined) {
    sortValue = { sortDir: "ASC", sortField: "minPrice" };
  }
  let limit = values.limit ? values.limit : 20;
  limit = String(limit);
  let dimension = String(width) + String(height) + String(diameter);
  let seasons = values.season;
  if (seasons === "summer") {
    seasons = "season_summer";
  } else if (seasons === "winter") {
    seasons = "season_winter";
  } else if (seasons === "all") {
    seasons = "season_all";
  } else {
    // fallback
    seasons = "season_summer";
  }
  let vehicleType = values.vehicleType;
  if (vehicleType === "pkw") {
    vehicleType = "vehicle_pc";
  } else if (vehicleType === "lkw") {
    vehicleType = "vehicle_t";
  } else if (vehicleType === "suv") {
    vehicleType = "vehicle_suv";
  } else {
    // fallback
    vehicleType = "vehicle_pc";
  }
  let vehicleTypeSelection = null;
  vehicleTypeSelection = { id: "VEHICLE_TYPE", values: [vehicleType] };
  let loadIndex = values.load;
  let loadIndexSelection = null;
  if (loadIndex) {
    if (loadIndex === "Alles") {
      // loadIndexSelection = {"id":"LOAD_INDEX","values": ["89", "91", "94", "98"]};
    } else {
      loadIndexSelection = { id: "LOAD_INDEX", values: [loadIndex] };
    }
  }
  let speedIndex = values.speed;
  let speedIndexSelection = null;
  if (speedIndex) {
    if (speedIndex === "Alles") {
    } else {
      speedIndexSelection = { id: "SPEED_INDEX", values: [speedIndex] };
    }
  }
  let filterSelections = null;
  filterSelections = values.filterValues;
  let resultArray = null;
  let result = null;
  if (values.filterValues && values.filterValues.length !== 0) {
    resultArray = values.filterValues;
    resultArray = {
      ...filterSelections,
      vehicleTypeSelection
    };
    if (loadIndexSelection) {
      resultArray = {
        ...resultArray,
        loadIndexSelection
      };
    }
    if (speedIndexSelection) {
      resultArray = {
        ...resultArray,
        speedIndexSelection
      };
    }
    result = Object.values(resultArray);
  } else {
    result = [vehicleTypeSelection];
  }

  let requestJson;
   requestJson = {
    debitorNo: debitorNr,
    width: width,
    height: height,
    diameter: diameter,
    sort:
      sortValue === {} ? { sortDir: "ASC", sortField: "minPrice" } : sortValue,
    page: pageNr,
    pageSize: pageSize,
    season: seasons,
    showDotAndDemo: 'false',
    b2c:'true',
    showQualityGroup:'true',
    filterSelections: result
  };

  requestJson = JSON.stringify(requestJson);
  //dispatch(loadTyres());

  return getTyres(requestJson, environment).then(
      function (res) {
        if (res.Error != "" && res.Error !=null && res.Error != undefined) {
          dispatch (fetchTyresError ( res.Error ) );
        } else {
          dispatch( storeTyres( res ) );
        }
      }
  );
};

