import * as actionTypes from '../actions/actionTypes';

const initialState = {
    car: {},
    isLoading: false,
    error: null
};

const reducer = ( state = initialState, action ) => {
    let _car = state.car;
    let _error = state.error;
    let _isLoading = state.isLoading;

    switch (action.type) {

        case actionTypes.STORE_CAR:
            _isLoading = false;
            _car = action.car;
            return {
                car: _car,
                isLoading: _isLoading,
                error: null
            };

        case actionTypes.LOAD_CAR:
            _isLoading = true;
            return {
                isLoading: _isLoading
            };

        case actionTypes.LOAD_CAR_ERROR:
            _error = action.error;
            _isLoading = false;
            return {
                error: _error,
                isLoading: _isLoading,
                car: {}

            };

        default:
            break;
    }
    return state;
};

export default reducer;
