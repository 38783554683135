import React from 'react';

class RimCardButtons extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         firstSize: 0
      };
   }

   setFirstSize(index) {
      this.setState({firstSize: index});
   }

   priceHandler(rim) {
      for (let sizes of rim.items) {
         if (sizes.size == this.props.rimSize) {
            return sizes.retailPrice ? sizes.retailPrice : 'Preis auf Anfrage';
         }
      }
      return rim.items[0].retailPrice ? rim.items[0].retailPrice : 'Preis auf Anfrage'
   }

   render() {
      let firstSize = this.state.firstSize;
      let active = this.props.active;
      let visible = this.props.visible;
      let ar = this.props.ar;
      let price = this.priceHandler(this.props.rim);
      const visLeft = firstSize === 0 ? false : true;
      const visRight = (ar ? (ar.length > 3 || firstSize === (ar.length - 3)) ? true : false : '');
      return (
         <div style={{'visibility': visible && !active ? 'visible' : 'hidden'}} className={'mt-2'}>
            <div className={'rim-size-slide-wrapper'}>
               <div className={'rim-size-slide'}>
                  {ar}
               </div>
            </div>
            <div className={'m-auto btn-wrapper'}>
               <button onClick={() => this.props.goToDetail()}
                       disabled={this.props.loadingRims}
                       className={'btn btn-outline-primary'}> Details
               </button>
            </div>
         </div>
      );
   }
}

export default (RimCardButtons);
