import React from 'react';
import ReactTooltip from 'react-tooltip';

class RimDetailsTable extends React.Component {
   constructor(props) {
      super(props);
   }

   getServiceCenterString() {
      let serviceCenterName;
      switch (process.env.REACT_APP_CLUB) {
         case 'points':
         case 'ps-rims':
            serviceCenterName = 'Point-S – Service-Center';
            break;
         case 'automeister':
         case 'am-rims':
            serviceCenterName = 'Automeister – Service-Center';
            break;
         default:
            serviceCenterName = 'Service-Center';
            break;
      }
      return serviceCenterName;
   }

   render() {
      let rim = this.props.rim;
      let active = this.props.active;
      let visible = this.props.visible;
      let chosenItem = this.props.chosenItem;
      if (!rim.items[chosenItem]) {
         chosenItem = 0;
      }
      return (
         <div className={'details-table col-12 col-xs-9 col-sm-9 col-md-9 col-lg-5 ' +
         (active ? 'active' : 'nosize') + (this.props.hoverState ? ' hovered' : '')}
              style={{
                 'display': active && visible ? 'inherit' : 'none',
                 'height': active && visible ? 'inherit' : '0',
                 'visibility': this.props.hoverState ? 'hidden' : 'visible'
              }}>
            <div style={{'width': '100%'}}>
               <h3>{rim.manufacturer}</h3>
               <div>{rim.name}</div>
               <br/>
               <div className={'row'}>
                  <div className={'col'}>
                     <h3> Produktdetails</h3>
                  </div>
                  <div className={rim.items[chosenItem].rearRimId ? 'd-none' : 'col-6'}>
                     <img src={'/auto_hv.svg'} className={'axis'}/>
                  </div>
                  <div className={rim.items[chosenItem].rearRimId ? 'col-3' : 'd-none'}>
                     <img src={'/auto_v.svg'} className={'axis'}/>
                  </div>
                  <div className={rim.items[chosenItem].rearRimId ? 'col-3' : 'd-none'}>
                     <img src={'/auto_h.svg'} className={'axis'}/>
                  </div>
               </div>
               <hr className={'border-bottom-highlight'}/>
               <div className={'row border-bottom-gray'}>
                  <div className={'col'}>
                     <span>Größe:</span>
                  </div>
                  <div className={rim.items[chosenItem].rearRimId ? 'col-3' : 'col-6'}>
                            <span>
                                {rim.items[chosenItem].width + ' x ' + rim.items[chosenItem].size + '"'}
                            </span>
                  </div>
                  {rim.items[chosenItem].rearRimId ?
                     <div className={'col-3'}>
                                <span>
                                    {rim.items[chosenItem].rearWidth + ' x ' + rim.items[chosenItem].rearSize + '"'}
                                </span>
                     </div> : ''
                  }
                  <hr/>
               </div>
               <div className={'row border-bottom-gray'}>
                  <div className={'col'}>
                     <span>Einpresstiefe</span>
                     <i data-tip data-for={'ET'} className="fas fa-info-circle mr-1 ml-1"/>:
                     <ReactTooltip id='ET' place={'right'}>
                                <span>
                                    Die <b>Einpresstiefe (ET)</b> einer Felge beschreibt den Abstand von der Radmitte zur inneren
                                    Auflagefläche der Felge an der Radnabe. Je Größer die <b>ET</b> ist, desto weiter befindet sich
                                    die Felge im Radkasten. Somit ist die Spurbreite schmäler. Je niedriger die <b>ET</b> ist,
                                    desto weiter außen sitzt die Felge im Radkasten.
                                </span>
                     </ReactTooltip>
                  </div>
                  <div className={rim.items[chosenItem].rearRimId ? 'col-3' : 'col-6'}>
                            <span>
                                {rim.items[chosenItem].offset}
                            </span>
                  </div>
                  {rim.items[chosenItem].rearRimId ?
                     <div className={'col-3'}>
                                <span>
                                    {rim.items[chosenItem].rearOffset}
                                </span>
                     </div> : ''
                  }
                  <hr/>
               </div>
               <div className={'row border-bottom-gray'}>
                  <div className={'col'}>
                     <span>Lochanzahl / Lochkreis:</span>
                  </div>
                  <div className={'col-6'}>
                     <span>{rim.boltPattern}</span>
                  </div>
                  <hr/>
               </div>
               <div className={'row border-bottom-gray'}>
                  <div className={'col'}>
                     <span>Felgenart:</span>
                  </div>
                  <div className={'col-6'}>
                     <span>{rim.wheelType}</span>
                  </div>
                  <hr/>
               </div>
               <div className={'row border-bottom-gray'}>
                  <div className={'col'}>
                     <span>Farbe:</span>
                  </div>
                  <div className={'col-6'}>
                     <span>{rim.colorGroup}</span>
                  </div>
                  <hr/>
               </div>
               <div className={'row'}>
                  <div className={'col-12 mt-3 info-text'}>
                     Für die Konfiguration eines Komplettrades wenden Sie sich bitte an das
                     nächste {this.getServiceCenterString()}.
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default (RimDetailsTable);
