import React from 'react';
import { connect } from 'react-redux';
import { fetchTyresSSR } from ' ../../../redux-store/actions/tyres';
import * as actionCreators from '../../../../redux-store/actions';
import Helmet from 'react-helmet';
import Pagination from './components/Pagination';
import TyreResult from './components/TyreResult';
import Tyresearch from '../../shared/tyresearch/Tyresearch';
import Dropdown from '../../shared/dropDown/DropDownSort';
import LoadingTyreResult from './components/LoadingTyreResult/LoadingTyreResult';

let i = 0;
let sortValue = ['Preis aufsteigend', 'Preis absteigend'];

class SearchResult extends React.Component {
   constructor( props ) {
      super( props );
      this.state = {
         page: this.props.page,
         filterSelections: this.props.filterSelections,
         selectedSortValue: ''
      };
      this.sortChangeHandler = this.sortChangeHandler.bind( this );

      this.paginationHandler = () => {
         this.loadData();
      };

      this.onBackButtonEvent1 = e => {
         e.preventDefault();
      };
   }

   componentDidUpdate() {
      if (this.props.page >= 0) {
         window.onpopstate = this.onBackButtonEvent;
      }
   }

   componentDidMount() {
      this.scrollToTop();
      this.resetHandler = e => {
         e.preventDefault();
         this.props.onResetFilterSelections();
         this.props.onComputedUrlValue( this.props.url );
         this.props.onSetNavigation( this.props.url );
         setTimeout(
            function () {
               this.props.history.push( this.props.url );
            }.bind( this ),
            600
         );
      };

      this.onBackButtonEvent = e => {
         e.preventDefault();
         if (this.props.page > 0) {
            this.props.onSetPage( this.props.page - 1 );
            this.paginationHandler();
            this.props.history.push( this.props.url );
         } else {
            this.props.history.push( '/' );
            this.props.onSetNavigation( '/' );
         }
      };

      this.props.onResetTermsOfUse();
      this.props.onResetDataProtection();
      this.props.onSetNavigation( `/searchresult/${ this.props.match.params.searchString }` );
      if (this.props.homeSearch) {
         this.props.onSetPage( 0 );
         this.loadData();
         this.props.history.push( this.props.url );
      }
      if ('/searchresult/' + this.props.match.params.searchString !== this.props.urlNav) {
         this.props.onSetPage( 0 );
         this.loadData();
         this.props.history.push( this.props.url );
      } else if  (! this.props.tyres || this.props.tyres.length === 0) {
         this.props.onSetPage( 0 );
         this.loadData();
         this.props.history.push( this.props.url );
      }
      if (this.props.page >= 0) {
         window.onpopstate = this.onBackButtonEvent;
      }
      this.props.onResetHomeSearch();

   }

   loadData() {
      if ('/searchresult/' + this.props.match.params.searchString !== this.props.urlNav) {
         this.props.fetchTyresSSR(
            this.props.match.params,
            this.props.envMode,
            0,
            this.props.firmData[0].Id,
            this.props.sortValueJson
         );
      } else {
         this.props.fetchTyresSSR(
            this.props.match.params,
            this.props.envMode,
            this.props.page,
            this.props.firmData[0].Id,
            this.props.sortValueJson
         );
      }

   }

   setNav() {
      this.props.onSetNavigation( '/unresolvedHostname' );
      {
         this.props.navigation != '/unresolvedHostname' ?
            this.props.history.push( '/unresolvedHostname' )
            : '';
      }
   }

   showInfo() {
      if (this.props.isLoading) {
         return (
            <div>
               <LoadingTyreResult/>
               <LoadingTyreResult/>
               <LoadingTyreResult/>
               <LoadingTyreResult/>
               <LoadingTyreResult/>
            </div>
         );
      } else if (!this.props.tyres) {
         return (
            <div align="center">
               <h3> Sie haben nichts ausgewählt ... </h3>
            </div>
         );
      } else if (this.props.tyres.length === 0 && !this.props.isLoading && this.props.error != '' && this.props.error != null) {
         return (
            <div align="center">
               { this.setNav() }
            </div>
         );
      } else if (this.props.tyres.length === 0 && this.props.isLoading === false && this.props.error == null) {
         return (
            <div align="center">
               <h3> Keine Ergebnisse gefunden ... </h3>
            </div>
         );
      }
   }

   showSearchresult() {
      if (this.props.tyres) {
         return this.props.tyres.map( _selectedTyre => (
            <div className="result-position" key={ _selectedTyre.productId }>
               <TyreResult selectedTyre={ _selectedTyre }/>
            </div>
         ) );
      }
      return '';
   }

   scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
   }

   sortChangeHandler( event ) {
      this.scrollToTop();
      this.state.selectedSortValue = event;
      this.props.onSelectedSortValue( event );
      this.props.onSetPage( 0 );
      let searchUrl = this.props.url.split( '/' )[2];
      let searchUrlValues = {};
      let newbuffer = '';
      let path = '/searchresult/';
      let url = '';
      let sort = {
         sortDir: 'ASC',
         sortField: 'minPrice'
      };
      const values = JSON.parse(
         Buffer.from( searchUrl, 'base64' )
         .toString( 'utf8' )
      );
      if (event === 'Preis absteigend') {
         sort = {
            sortDir: 'DESC',
            sortField: 'minPrice'
         };
      } else if (event === 'Preis aufsteigend') {
         sort = {
            sortDir: 'ASC',
            sortField: 'minPrice'
         };
      }
      searchUrlValues = {
         ...values,
         sort
      };
      searchUrlValues = JSON.stringify( searchUrlValues );
      newbuffer = Buffer.from( searchUrlValues )
      .toString( 'base64' );
      url = path + newbuffer;
      this.props.onComputedUrlValue( url );
      this.props.history.push( url );
   }

   sort() {
      return (
         <div className="sort">
            <span className="sort-text">{ 'Sortieren:' }</span>
            <Dropdown
               items={ sortValue }
               id="sort"
               defaultValue={ this.props.sortValue }
               onChangeCallBack={ this.sortChangeHandler }
            />
         </div>
      );
   }

   showResultCountText(values) {
      if (this.props.tyres != undefined) {
         return <div>
                  <span>Ihre Suche zu </span> 
                  <span className="text-highlight">{`${values.length} ${values.height} ${values.diameter}`}</span> 
                  {" "}ergab{" "}
                  <span className="text-highlight">{this.props.totalCount}</span> Treffer
               </div>
      } else {
         return <div>
                  Ihre Suche <span className="text-highlight">{`${values.length} ${values.height} ${values.diameter}`}</span>
               </div>
      }
   }

   render() {
      if (this.props.filter) {
         this.props.history.push( this.props.url );
         this.props.onFilterSelections();
      }
      const values = JSON.parse(
         Buffer.from( this.props.match.params.searchString, 'base64' )
         .toString(
            'utf8'
         )
      );
      const { tyres } = this.props;
      return (
         <div className="searchResult-wrapper">
            <Helmet>
               <title>
                  Reifengröße { values !== undefined ? values.length : '' }{ ' ' }
                  { values.height } { values.diameter }{ ' ' }
               </title>
            </Helmet>

            <div className="container">
               <div className="row">
                  <div className="col-md-12 text-left">
                     <h2 className="text-contrast">Ihre Reifenauswahl</h2>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 d-block d-xl-none">
                     <Tyresearch
                        location={ this.props.history.location }
                        history={ this.props.history }
                     />
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-3 d-none d-xl-block"/>
                  <div className="col-xl-4 col-md-6 col-sm-5 col-12 text-left ">{ this.sort() }</div>
                  <div className="col-xl-5 col-md-6 col-sm-7 col-12 text-right">
                        {this.showResultCountText(values)}
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-3 d-none d-xl-block">
                     <Tyresearch
                        location={ this.props.history.location }
                        history={ this.props.history }
                     />
                  </div>
                  <div className="col-xl-9">
                     { this.showInfo() }
                     { this.showSearchresult() }
                     <Pagination paginationHandler={ this.paginationHandler }/>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

SearchResult.serverFetch = fetchTyresSSR;

const mapStateToProps = state => ( {
   state,
   tyres: state.tyres.tyres,
   isLoading: state.tyres.isLoading,
   filter: state.routeChanged.filter,
   url: state.routeChanged.url,
   page: state.navigation.page,
   filterSelections: state.routeChanged.filterSelections,
   totalCount: state.tyres.totalCount,
   navigation: state.navigation.location,
   sortValue: state.routeChanged.sortValue,
   sortValueJson: state.routeChanged.sortValueJson,
   changeFlag: state.routeChanged.changeFlag,
   firmData: state.firmData,
   envMode: state.envMode,
   urlNav: state.navigation.location,
   error: state.tyres.error,
   homeSearch: state.navigation.homeSearch,
   scTires: state.shoppingcart.shoppingCartTyres,
   timestamp: state.shoppingcart.timestamp
} );

const mapDispatchToProps = {
   fetchTyresSSR,
   onSetNavigation: nav => actionCreators.setNavigation( nav ),
   onFilterSelections: () => actionCreators.filterSelections(),
   onSetPage: page => actionCreators.setPage( page ),
   onComputedUrlValue: val => actionCreators.newComputedUrlValue( val ),
   onSelectedSortValue: val => actionCreators.selectedSortValue( val ),
   onResetFilterSelections: () => actionCreators.resetFilterSelections(),
   onChangeFlag: () => actionCreators.changeFlag(),
   onResetSetSearchFlag: () => actionCreators.resetSearchFlag(),
   onResetTermsOfUse: () => actionCreators.resetTermsOfUse(),
   onResetDataProtection: () => actionCreators.resetDataProtection(),
   onResetHomeSearch: () => actionCreators.resetHomeSearch(),
   onCreateTimeStamp: () => actionCreators.createTimeStamp()
};

export default connect( mapStateToProps, mapDispatchToProps )( SearchResult );
