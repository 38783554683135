import React from 'react';
import Helmet from 'react-helmet';

const PrivacyStatement = () => (
    <div className="privacy-wrapper">
        <h2>This is the privacy statement page</h2>
        <Helmet>
            <title>PrivacyStatement Page</title>
            <div className="container">
            <meta
                name="description"
                content="This is a proof of concept for React SSR"
            />
            </div>
        </Helmet>
    </div>
);

export default PrivacyStatement;
