// TODO postOrder in api
import { sendOrder } from "../api";
import * as actionTypes from "./actionTypes";

/*
    postOrder for CS Rendering
 */
export const postOrder = order => dispatch => {
  sendOrder(order)
    .then(function(res) {
      if (res && res.status && res.status === "OK") {
        dispatch(orderSent());
        dispatch(storeOrder(res));
      } else {
        dispatch(sendOrderError(res));
      }
    })
    .catch(function(err) {
    });
};

export const storeOrder = order => {
  return {
    type: actionTypes.STORE_ORDER,
    order: order
  };
};
export const orderSent = () => {
  return {
    type: actionTypes.ORDER_SENT,
    sent: true
  };
};

export const resetSent = () => {
  return {
    type: actionTypes.RESET_SENT,
    sent: false
  };
};
export const sendOrderError = res => {
  return {
    type: actionTypes.SEND_ORDER_ERROR,
    res: res
  };
};
