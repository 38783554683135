import { getCarModels } from '../api';
import * as actionTypes from './actionTypes';

export const carDetailAction = ( params ) => ( dispatch ) => {
    dispatch ( loadCar() );
    setTimeout(
        function () {
            getCarModels( params )
                .then(
                    function ( res ) {
                        if (res.Error != '' && res.Error != null && res.Error != undefined) {
                            dispatch( loadCarError( res.Error ) );
                        } else {
                            dispatch( storeCar( res ) );
                        }
                    }
                );
        }
            .bind( this ),
        500
    );
};

export const carDetailActionSSR = ( params ) => ( dispatch ) =>
    carDetailAction( params )
        .then( res => dispatch( storeCar( res ) ) );

const storeCar = ( car ) => {
    return {
        type: actionTypes.STORE_CAR,
        car: car
    };
};

const loadCar = () => ( {
    type: actionTypes.LOAD_CAR
} );

const loadCarError = ( error ) => ( {
    type: actionTypes.LOAD_CAR_ERROR,
    error : error
} );
