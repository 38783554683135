import React from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../../redux-store/actions/index';
import ShoppingCartFilled from '../components/ShoppingCartFilled';
import ShoppingCartEmpty from '../components/ShoppingCartEmpty';
import { Link } from 'react-router-dom';

class ShoppingCartWrapper extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            tyres: this.props.tyres
        };
    }

    shoppingCartChecker( props ) {
        if (props.tyres.length > 0) {
            return <ShoppingCartFilled/>;
        }
        return ShoppingCartEmpty();
    }

    render() {
        return (
            <div>
                { this.shoppingCartChecker( this.props ) }
                <div className="text-right">
                    { this.props.tyres.length > 0 ? (
                        <Link to="/checkoutprocess">
                            <button className="btn btn-success button-pos">
                                Zur Kaufabwicklung
                            </button>
                        </Link>
                    ) : (
                        ''
                    ) }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ( {
    tyres: state.shoppingcart.shoppingCartTyres
} );

const mapDispatchToProps = dispatch => ( {
    onRemoveButtonHandler: idxx => dispatch( actionCreators.removeCartTire( idxx ) )
} );

export default connect( mapStateToProps, mapDispatchToProps )( ShoppingCartWrapper );
