
import * as actionTypes from '../actions/actionTypes';

const initialState = {
tireFitting: false,
wheelAlignment: false
};

const reducer = (state, action) => {
    state = state || initialState;
    let _tireFitting = state.tireFitting;
    let _wheelAlignment = state.wheelAlignment;
    switch (action.type) {
        case actionTypes.SET_TIRE_FITTING:
        _tireFitting = action.tireFitting;
        {
            return Object.assign({}, state, {
                tireFitting: _tireFitting
            });
        }

        case actionTypes.RESET_TIRE_FITTING:
        {
            _tireFitting = action.tireFitting
            return Object.assign({}, state, {
                tireFitting: _tireFitting
            });
        }

        case actionTypes.SET_WHEEL_ALIGNMENT:
        {
            _wheelAlignment = action.wheelAlignment;
            return Object.assign({}, state, {
                wheelAlignment: _wheelAlignment
            });
        }

        case actionTypes.RESET_WHEEL_ALIGNMENT:
        {
            _wheelAlignment = action.wheelAlignment;
            return Object.assign({}, state, {
                wheelAlignment: _wheelAlignment
            });
        }
    }
    return state;
}

export default reducer;
