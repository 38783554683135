import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actionCreators from '../../../redux-store/actions';
import ToggleButton from './components/DrawerToggleButton/DrawerToggleButton';

class Header extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         burgerMenuOpen: false,
         scTires: this.props.scTires,
         totalCount: this.props.totalCount,
         firmData: this.props.firmData
      };
      this.drawerToggleClickHandler = () => {
         this.setState(prevState => {
            return {burgerMenuOpen: !prevState.burgerMenuOpen};
         });
      };
      this.resetSessionStorage = this.resetSessionStorage.bind(this);
   }

   computeAmount() {
      this.props.onAmountCount();
      return this.props.totalCount;
   }

   componentDidMount() {
      this.setNavigation = location => {
         if (this.props.navigation == '/') {
            this.props.onHomeSearch();
         }
         this.props.onSetNavigation(location);
         this.state.burgerMenuOpen === true ? this.drawerToggleClickHandler() : '';
      };

      if (this.props.scTires.length == 0) {
         if (this.props.timestamp == null) {
            this.props.onCreateTimeStamp();
         }
      }
   }

   resetSessionStorage() {
      this.props.onReset();
      this.props.onResetFilterSelections();
   }

   render() {
      let time = new Date().getTime();
      if (this.props.timestamp != null) {
         if (time > this.props.timestamp) {
            this.resetSessionStorage();
         }
      }
      if (this.props.timestamp == null) {
         this.props.onCreateTimeStamp();
      }
      const firmData = (this.props.firmData ? (this.props.firmData[0]['AllgemeineDaten'] ? this.props.firmData[0]['AllgemeineDaten'] : '') : (''));

      return (
         <div className={'header marg-top-header'}>
            {this.props.firmData ?
               <div>
                  <div className="container">
                     <div className="row">
                        <div className="col-xs-12 col-md-12 seperator-bot d-block d-md-none ">
                           <h1 className="header-title">
                              {firmData.Name}
                           </h1>
                        </div>
                        <div className="col-xs-12 col-md-4 col-xl-3 using-display-flex">
                           <ToggleButton status={this.state.burgerMenuOpen}
                                         click={this.drawerToggleClickHandler}/>
                           <NavLink to="/" onClick={() => this.setNavigation('/')}>
                              <img src="/points.png" className="img-fluid logo-size"/>
                           </NavLink>
                           <div className={'d-md-none icons-md-right'}>
                              <NavLink onClick={() => this.setNavigation('/')} to="/">
                                 <i className="fas fa-search search-icon-pos"/>
                              </NavLink>
                           </div>
                           <div className="d-md-none">
                              <ul className={'header-nav sc-button-pos'}>
                                 <li className={this.props.navigation === '/shoppingcart' ? 'active-nav' : ''}>
                                    <NavLink onClick={() => this.setNavigation('/shoppingcart')} to="/shoppingcart">
                                                    <span className={'pos-relative'}>
                                                        <span className="md-pos-amount"> {this.computeAmount()}</span>
                                                        <span className="d-none d-md-block">{' '}Artikel</span>
                                                       <i className="fas fa-shopping-cart sc-icon-size"/>
                                                    </span>
                                    </NavLink>
                                 </li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-md-5 col-xl-7 d-none d-md-block">
                           <h1 className="header-title">
                              {this.props.firmData[0]['AllgemeineDaten'].Name}
                           </h1>
                        </div>
                        <div className={'col-xs-2 col-md-3 col-xl-2 d-none d-md-block' +
                        (this.state.burgerMenuOpen === true ? ' menu-open' : '')}>
                           <div className="d-none d-md-block">
                              <ul className={'header-nav sc-button-pos'}>
                                 <li className={this.props.navigation === '/shoppingcart'
                                    ? 'active-nav'
                                    : ''
                                 }>
                                    {!this.props.loc.includes("/rimconfigurator") ?
                                       <NavLink onClick={() => this.setNavigation('/shoppingcart')}
                                                to="/shoppingcart">
                                                    <span className="scArticleCount">
                                                      {' '}
                                                       {this.computeAmount()}
                                                    </span>{' '}
                                          Artikel
                                          <img className="sc-count" src="/Icons/Shoppingcart_green.png"/>
                                       </NavLink>
                                       :
                                       ""
                                    }
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="container">
                     <div className={`hidden-print${this.state.burgerMenuOpen === true ? ' menu-open' : ''}`}>
                        <ul className="header-nav">
                           <li className={this.props.loc == '/' || this.props.loc == ''
                              ? 'active-nav'
                              : ''
                           }>
                              <NavLink to="/" onClick={() => this.setNavigation('/')}>
                                 Home
                              </NavLink>
                           </li>
                           <li
                              className={(this.props.loc.includes('/searchresult') || this.props.loc.includes('/tyre')) ? "active-nav" : ""}>
                              <NavLink to={this.props.url} onClick={() => this.setNavigation(this.props.url)}>
                                 Reifen
                              </NavLink>
                           </li>
                           <li className={(this.props.loc.includes('/rimconfigurator')) ? "active-nav" : ""}>
                              <NavLink to={'/rimconfigurator'} onClick={() => this.setNavigation('/rimconfigurator')}>
                                 Felgen
                              </NavLink>
                           </li>
                           <li className={this.props.loc === '/contact' ? 'active-nav' : ''}>
                              <NavLink to="/contact" onClick={() => this.setNavigation('/contact')}>
                                 Kontakt
                              </NavLink>
                           </li>
                           <li className={(this.props.loc === '/termsofuse' ? 'active-nav' : '') + 'd-block d-md-none'}>
                              <NavLink to="/termsofuse" onClick={() => this.setNavigation('/termsofuse')}>
                                 Nutzungsbedingungen
                              </NavLink>
                           </li>
                           <li className={(this.props.loc === '/imprint' ? ' active-nav ' : '') + 'd-block d-md-none'}>
                              <NavLink to="/imprint" onClick={() => this.setNavigation('/imprint')}>
                                 Impressum
                              </NavLink>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               :
               <div className={'container'}>
                  <img src="/points.png" className="img-fluid logo-size"/>
               </div>
            }
         </div>
      );
   }
}

const mapStateToProps = state => ({
   scTires: state.shoppingcart.shoppingCartTyres,
   totalCount: state.shoppingcart.totalCount,
   firmData: state.firmData,
   url: state.routeChanged.url,
   timestamp: state.shoppingcart.timestamp
});

const mapDispatchToProps = dispatch => {
   return {
      dispatch,
      onAmountCount: () => dispatch(actionCreators.countAmount()),
      onSetNavigation: loc => dispatch(actionCreators.setNavigation(loc)),
      onHomeSearch: () => dispatch(actionCreators.homeSearch()),
      onReset: () => dispatch(actionCreators.resetSc()),
      onResetFilterSelections: () => dispatch(actionCreators.resetFilterSelections()),
      onCreateTimeStamp: () => dispatch(actionCreators.createTimeStamp())

   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
