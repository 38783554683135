import React from 'react';

class Breadcrumb extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         path: this.props.path,
         mobile: false
      };
   }

   componentDidMount() {
      if (window.innerWidth < 700) {
         this.setState({mobile: true});
      } else if (window.innerWidth >= 700 && this.state.mobile === true) {
         this.setState({mobile: false});
      }
      this.changeBreadcrumbPath();
   }

   componentDidUpdate() {
      this.changeBreadcrumbPath();
   }

   changeBreadcrumbPath() {
      if (this.state.path != this.props.path) {
         this.setState({
            path: this.props.path
         });
      }
   }

   render() {
      return (
         <div>
            <div className={"rims breadcrumbs"}>
               <div className={this.state.mobile == true ? '' : 'container'}>
                  { /*-------------------------------------Fahrzeug bestimmen--------------------------------------------*/}
                  {this.state.path === '/rimconfigurator' ?
                     <span className="breadcrumbs__inner active">
                                <span className="breadcrumbs__title step-1">
                                    <b> Fahrzeug </b> <br/> bestimmen
                                </span>
                            </span>
                     :
                     <span onClick={() => this.setState({
                        path: '/rimconfigurator'
                     })}>
                                <a href="/rimconfigurator">
                                    <span className="breadcrumbs__inner clickable">
                                        <span className="breadcrumbs__title step-1">
                                            <b> Fahrzeug </b> <br/> bestimmen
                                        </span>
                                    </span>
                                </a>
                            </span>
                  }

                  { /*-------------------------------------Felgen wählen---------------------------------------------*/}
                  {this.state.path !== '/rimconfigurator/' && this.state.path !== '/rimconfigurator' && !this.state.path.includes("/rimconfigurator/manualSelect") ?
                     <span className="breadcrumbs__inner active">
                                <span className="breadcrumbs__title step-2">
                                    <b> Felgen </b> <br/> auswählen
                                </span>
                            </span>
                     :
                     <span className="breadcrumbs__inner ">
                                <span className="breadcrumbs__title step-2">
                                    <b> Felgen </b> <br/> auswählen
                                </span>
                            </span>
                  }

               </div>
            </div>
         </div>
      );
   }
}

export default Breadcrumb;
