import React from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../../redux-store/actions';

class Pagination extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            pages: [1, 2, 3, 4, 5],
            change: true,
            page: this.props.page,
            count: null,
            perPage: 20,
            pagesToShow: 5,
            loading: false,
            test: true,
            totalCount: this.props.totalCount,
            maxPages: null
        };
    }

    componentDidUpdate() {
        setTimeout(
            function () {
                this.getPages();
            }.bind( this ),
            200
        );
    }

    componentDidMount() {
        setTimeout(
            function () {
                this.getPages();
            }.bind( this ),
            200
        );
    }

    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    onPage( n ) {
        if (n == 0 || n < 0) {
            this.state.page = 0;
            this.props.onSetPage( 0 );
            setTimeout(
                function () {
                    this.props.paginationHandler( 0 );
                }.bind( this ),
                200
            );
        } else {
            this.state.page = n - 1;
            this.props.onSetPage( n - 1 );
            this.state.change = true;
            setTimeout(
                function () {
                    this.props.paginationHandler( n - 1 );
                }.bind( this ),
                200
            );
        }
        this.scrollToTop();
    }

    onIncrementPage( n ) {
        let maxPages = Math.ceil( this.props.totalCount / this.state.perPage ) - 1;
        this.state.maxPages = maxPages;
        if (n == maxPages) {
        } else {
            if (this.props.page >= this.props.totalCount - 1) {
            } else {
                this.state.page = n + 1;
                this.props.onSetPage( n + 1 );
                this.state.change = true;
                setTimeout(
                    function () {
                        this.props.paginationHandler( n + 1 );
                    }.bind( this ),
                    200
                );
            }
        }
        this.scrollToTop();
    }

    totalPages() {
        return Math.ceil( this.props.totalCount / this.state.perPage ) || 0;
    }

    lastPage() {
        return this.state.perPage * this.state.page > this.state.count;
    }

    getPages() {
        this.pages = [];
        let maxPages = this.totalPages();
        this.state.maxPages = maxPages;

        maxPages = String( maxPages );
        const range = Math.ceil( ( this.state.pagesToShow - 1 ) / 2 );
        let startPage,
            endPage;
        if (maxPages <= this.state.pagesToShow) {
            startPage = 1;
            endPage = maxPages;
        } else {
            maxPages = this.totalPages();
            this.state.maxPages = maxPages;

            if (this.props.page + range >= maxPages) {
                startPage = maxPages - 2 * range;
                if (this.props.page === maxPages - 1) {
                    startPage = maxPages - 2 * range;
                    endPage = maxPages;
                } else {
                    startPage = maxPages - 2 * range;
                    endPage = this.props.page + 2;
                }
            } else if (this.props.page - range <= 0) {
                startPage = 1;
                endPage = this.state.pagesToShow;
            } else {
                startPage = this.props.page - 1;
                endPage = this.props.page + 3;
            }
        }
        for (let i = startPage; i <= endPage; i++) {
            this.pages.push( i );
        }
        this.state.pages = this.pages;
    }

    render() {
        this.getPages();
        return (
            <ul
                key={ new Date().getMilliseconds() }
                className="pagination justify-content-center "
            >
                <div key={ 'Key1' }>
                    {/* TODO active/disabled  */ }
                    <li className={ 'first' }>
                        <a
                            className="elemente"
                            onClick={ () => ( this.props.page !== 0 ? this.onPage( 0 ) : '' ) }
                        >
                            <i className="fa fa-angle-double-left clickable"/> &nbsp;&nbsp;
                        </a>
                    </li>
                </div>

                <div key={ 'Key2' }>
                    <li className={ 'previous' }>
                        <a
                            className="elemente"
                            onClick={ () => {
                                this.props.page > 0 ? this.onPage( this.props.page ) : '';
                            } }
                        >
                            { ' ' }
                            &nbsp;&nbsp;
                            <i className="fa fa-angle-left clickable"/> &nbsp;&nbsp;
                        </a>
                    </li>
                </div>

                { this.state.pages.map( ( page, index ) => (
                    <div key={ 'Key3' + index }>
                        <li className={ this.props.page + 1 == page ? 'active-nav' : '' }>
                            <a className="elemente" onClick={ () => this.props.page + 1 !== page ? this.onPage( page ) : '' }>
                                &nbsp;&nbsp; { page } &nbsp;&nbsp;
                            </a>
                        </li>
                    </div>
                ) ) }

                <div key={ 'Key4' }>
                    <li className={ 'next' }>
                        <a
                            className="elemente"
                            onClick={ () =>
                                this.props.page !== this.state.maxPages - 1
                                    ? this.onIncrementPage( this.props.page )
                                    : ''
                            }
                        >
                            &nbsp;&nbsp;
                            <i className="fa fa-angle-right clickable"/> &nbsp;&nbsp;
                        </a>
                    </li>
                </div>

                <div key={ 'Key5' }>
                    <li className={ 'last' }>
                        <a
                            className="elemente"
                            onClick={ () =>
                                this.props.page !== this.state.maxPages - 1
                                    ? this.onPage( this.totalPages() )
                                    : ''
                            }
                        >
                            { ' ' }
                            &nbsp;&nbsp;
                            <i className="fa fa-angle-double-right clickable"/>
                        </a>
                    </li>
                </div>
            </ul>
        );
    }
}

const mapStateToProps = state => ( {
    page: state.navigation.page,
    totalCount: state.tyres.totalCount
} );

const mapDispatchToProps = {
    onSetPage: page => actionCreators.setPage( page )
};

export default connect( mapStateToProps, mapDispatchToProps )( Pagination );
