import React from 'react';

let openingTimes;
let arr2 = [];
let DateObject = [];
let editedOpeningTimes = [];
let SamstagOpeningHours = '';
const openingTimesFooterHandler = ( props ) => {
    if (props.data && props.data[0] && props.data[0].openingTimes) {
        openingTimes = props.data[props.vendorNo].openingTimes.split( '\n' );
        openingTimes.shift();
        for (let i = 0; i < openingTimes.length; i++) {
            editedOpeningTimes = openingTimes[i].toString()
                .split( ' ' );
            DateObject[i] = {
                date: editedOpeningTimes[0],
                time1: editedOpeningTimes[1],
                time2: editedOpeningTimes[2]
            };
            if (DateObject [i].date === 'Sa') {
                SamstagOpeningHours = DateObject [i].time1;
            }
            arr2[i] =
                ( <span key={ DateObject[i].date }>
                    { DateObject[i].time1 }
                    { DateObject[i].time2 !== undefined ?
                        <span className={"space-left"}>
                            & { DateObject[i].time2 }
                        </span>
                        :
                        ''
                    }
                </span> );
        }

        // return arr2;
        return (
            <div className="row">
                <div className="col-3">
                    <span className="boldified">  Mo.-Fr.</span>
                </div>
                <div className="col-9">
                    { arr2[0] }
                </div>
                <div className="col-3">
                    <span className="boldified">  Sa. </span>
                </div>
                <div className="col-9">
                    { SamstagOpeningHours }
                </div>
            </div>
        );
    } else {
        return (
            <span/>
        );
    }
};

export default openingTimesFooterHandler;
