import React, {Component} from 'react';

class Dropdown extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isOpen: false,
         hasSelected: this.props.defaultValue
      };

      this.handleClickOutside = this.handleClickOutside.bind(this);
      this.setWrapperRef = this.setWrapperRef.bind(this);

      this.handleText = ev => {
         if (this.props.onChangeCallBack) {
            let id = null;
            if (this.props.currentTyre) {
               id = this.props.currentselectedTyre;
            } else if (this.props.id) {
               id = this.props.id;
            }
            if (ev.currentTarget.textContent === 'Alle') {
               this.props.onChangeCallBack('', id);
            } else this.props.onChangeCallBack(ev.currentTarget.textContent, id);
         }

         this.setState({
            hasSelected: ev.currentTarget.textContent
         });
      };

      this.handleClick = () => {
         this.setState({
            isOpen: !this.state.isOpen
         });
      };

      this.doNothing = () => {
      };
   }

   setWrapperRef(node) {
      this.wrapperRef = node;
   }

   handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
         this.setState({
            open: 0,
            isOpen: false
         });
         if (event.target.id != this.state.open) {
            this.setState({
               open: 0,
               isOpen: false
            });
         }
      }
   }

   itemList(props) {
      let _props = [(<div onClick={this.handleText}
                          className="dropdown_item"
                          key={Date.now()}
      >
         Alle
      </div>)];
      _props.push(...props);
      const list = _props.map(item => (
         <div
            onClick={this.handleText}
            className="dropdown__item"
            key={item}
         >
            {item}
         </div>
      ));

      return <div className="dropdown__items"> {list} </div>;
   }

   componentWillUpdate(nextProps, nextState) {
      if (nextState && nextState.isOpen) {
         document.addEventListener('mousedown', this.handleClickOutside);
      } else {
         document.removeEventListener('mousedown', this.handleClickOutside);
      }
      if (this.props.defaultValueOnly && this.state.hasSelected != nextProps.defaultValue)
         nextState.hasSelected = nextProps.defaultValue;
   }

   render() {
      const {isOpen, hasSelected} = this.state;

      return (
         <React.Fragment>
            {this.props.disabled ?
               <div className={'dropdown disabled'}>
                  <div className="dropdown__text">
                     Alle
                  </div>
               </div>
               :
               <div
                  ref={this.setWrapperRef}
                  className={isOpen ? 'dropdown active' : 'dropdown'}
                  onClick={this.props.isLoading ? this.doNothing : this.handleClick}
               >
                  <div className="dropdown__text" ref={this.setWrapperRef}>
                     {!hasSelected ? 'Alle' : hasSelected}
                  </div>
                  {this.itemList(this.props.items)}
               </div>
            }
         </React.Fragment>
      );
   }
}

export default Dropdown;
