import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { tyreDetailAction, tyreDetailActionSSR } from '../../../../redux-store/actions/tyre';
import * as actionCreators from '../../../../redux-store/actions/index';
import DropDown from '../../shared/dropDown/DropDown';
import SeasonIcon from '../../shared/seasonIcon/SeasonIcon';
import ReactImageMagnify from 'react-image-magnify';
import TotalPrice from '../../shared/priceAmountCaption/TotalPrice';
import { getAssemblyCosts } from '../../../../redux-store/api';

let items = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
let content;

class TyreDetail extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            computedAmount: 4,
            bool: true,
            btn_class: 'btn-success',
            isInCart: 0,
            loading: false,
            init: true,
            selectedTyre: this.props.selectedTyre,
            imageUrl: ''
        };
        this.props.shoppingCartTyres.forEach( selectedTyre => {
            if (selectedTyre.productId === this.props.match.params.id) {
                this.state.isInCart = parseInt( selectedTyre.amount );
                this.state.computedAmount = parseInt( selectedTyre.amount );
            }
        } );
        this.state.init = false;

        this.onBackButtonEvent = e => {
            e.preventDefault();
            if (this.props.navigation !== '/') {
                this.props.history.push( this.props.navigation );
            } else {
                this.props.history.push( '/' );
            }
        };
    }

    componentDidMount() {
        this.scrollToTop();
        this.props.onResetTermsOfUse();
        this.props.onResetDataProtection();
        if (
            this.props.selectedTyre &&
            String( this.props.selectedTyre.productId ) === String( this.props.match.params.id )
        ) {
        } else {
            this.loadData( this.props.match.params );
        }
        window.onpopstate = this.onBackButtonEvent;
    }

    loadData( params ) {
        this.props.tyreDetailAction( params );
    }

    amountSelectionHandler( event, id ) {
        let amount = null;
        amount = event;
        this.setState( { computedAmount: amount } );
        this.props.onAmountSelected( amount, id );
    }

    handleShoppingCartButton( selectedTyre, amount ) {
        this.setState( { loading: true } );
        let tire = selectedTyre;
        let assemblyCosts = getAssemblyCosts( this.props.selectedTyre.productId )
            .then(
                ( response ) => {
                    if (response != '' || response != undefined) {
                        tire = {
                            ...this.props.selectedTyre,
                            response
                        };
                    }
                    this.addToCart( tire, amount );
                }
            );
    }

    addToCart( selectedTyre, amount ) {
        this.setState( { loading: true } );
        let arr = [];
        if (this.props.shoppingCartTyres.length != 0) {
            this.props.shoppingCartTyres.map( scTyre => {
                arr.push( scTyre.productId );
                if (!arr.includes( this.props.selectedTyre.productId )) {
                    this.props.onAmountSelected( amount, this.props.selectedTyre.productId );
                    this.props.onAddTyreToCart( selectedTyre, amount );
                    this.props.onUpdateTotalPrice();
                    setTimeout(
                        function () {
                            this.setState( { loading: false } );
                            this.setState( { isInCart: this.state.computedAmount } );
                        }.bind( this ),
                        1200
                    );
                } else {
                    if (scTyre.computedAmount) {
                        this.props.onAmountSelected( amount, this.props.selectedTyre.productId );
                        this.props.onAddTyreToCart( selectedTyre, amount );
                        this.props.onUpdateTotalPrice();
                        setTimeout(
                            function () {
                                this.setState( { loading: false } );
                            }.bind( this ),
                            1200
                        );
                    }
                }
            } );
        } else {
            this.props.onAddTyreToCart( selectedTyre, amount );
            this.props.onUpdateTotalPrice();
            setTimeout(
                function () {
                    this.setState( { loading: false } );
                }.bind( this ),
                1000
            );
        }
    }

    buttonContent() {
        let storeComputedAmount = null;
        let storeAmount = null;
        this.props.shoppingCartTyres.map( scTyre => {
            if (this.props.match.params.id == scTyre.productId) {
                storeAmount = scTyre.amount;
                storeComputedAmount = scTyre.computedAmount;
            }
        } );
        if (this.state.init) {
            return <span>...</span>;  //TODO überprüfen
        }
        if (this.state.loading === true) {
            return (
                <span>
                    <i className="fas fa-circle-notch fa-spin"/>
                </span>
            );
        }
        //------------------- Reifen im Warenkorb und Menge ungleich Dropdownmenge -----------------
        if (( this.state.isInCart > 0 && this.state.computedAmount !== this.state.isInCart ) ||
            ( this.state.isInCart === 0 && ( storeComputedAmount !== null && storeAmount !== null ) && storeAmount !== storeComputedAmount )) {
            return (
                <span>
                    <span className="from-size-714"> Menge ändern <i className="fas fa-check"/></span>
                    <span className="until-size-714"> <i className="fas fa-sync-alt"/> </span>
                </span>
            );
        }
        //------------------- Reifen im Warenkorb und Menge gleich Dropdownmenge -----------------
        if (this.state.isInCart > 0 && this.state.computedAmount == this.state.isInCart) {
            return (
                <span>
                    <span className="from-size-714">{ storeAmount } Stück </span>
                    <i className="fas fa-check"/>
                </span>
            );
        }

        if (( storeComputedAmount !== null && storeAmount !== null ) && storeComputedAmount === storeAmount) {
            return (
                <span>
                    <span className="from-size-714">{ storeAmount } Stück</span>
                    <i className="fas fa-check"/>
                </span>
            );
        }
        return (
            <span>
                <i className="fas fa-shopping-cart"/>
                <span className="from-size-714">In den Warenkorb</span>
            </span>
        );
    }

    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    render() {
        const { selectedTyre } = this.props;
        // (instead of writing it in the constructor)
        this.props.shoppingCartTyres.forEach( selectedTyre => {
            if (selectedTyre.productId === this.props.match.params.id) {
                this.setState( {
                    isInCart: parseInt( selectedTyre.amount ),
                    computedAmount: parseInt( selectedTyre.computedAmount )
                } );
            }
        } );
        this.state.init = false;
        const { shoppingCartTyres } = this.props;
        let computedValue = 4;
        if (shoppingCartTyres.length !== 0) {
            let isInCart = false;
            shoppingCartTyres.map( scTyre => {
                if (this.props.match.params.id == scTyre.productId) {
                    computedValue = scTyre.computedAmount;
                    isInCart = true;
                    if (scTyre.computedAmount) {
                        computedValue = scTyre.computedAmount;
                    }
                }
            } );
            if (!isInCart) {
                computedValue = 4;
                this.state.isInCart = 0;
            }
        } else {
            computedValue = this.state.computedAmount;
        }

        let imageUrl = '';
        let euTireLabel = '';

        {
            selectedTyre !== undefined && selectedTyre !== {} && selectedTyre.productImageUrl !== '' ?
                ( imageUrl = selectedTyre.productImageUrl )
                : ( imageUrl = '/images/noimage.png' );
        }
        this.state.imageUrl = imageUrl;
        let srcSet = [];
        srcSet = [this.state.imageUrl].join( ', ' );

        if (selectedTyre !== undefined && selectedTyre !== {}) {
            euTireLabel = selectedTyre.tyreLabel && selectedTyre.tyreLabel.documentNo === "1222/2009" ?
                ( euTireLabel = `https://eios.eu/tyre/label?rr=${
                selectedTyre.tyreLabel ? selectedTyre.tyreLabel.rollingResistance : ''
            }&wg=${ selectedTyre.tyreLabel ? selectedTyre.tyreLabel.wetGrip : '' }&n=${
                selectedTyre.tyreLabel ? selectedTyre.tyreLabel.noiseEmission : ''
            }&nc=${ selectedTyre.tyreLabel ? selectedTyre.tyreLabel.noiseClass : '' }&tc=${
                selectedTyre.tyreLabel ? selectedTyre.tyreLabel.vehicleClass : ''
            }&height=260` ) : selectedTyre.tyreLabel ? selectedTyre.tyreLabel.url : '';
        } else {
            euTireLabel = ''
        }

        if (this.props && this.props.selectedTyre && this.props.selectedTyre.type !== 'ERROR') {
            if (this.props.selectedTyre.productId == 0) {
                content = <div>{ this.props.history.push( '/tyrenotfound' ) }</div>;
            }

            content = (
                <div className="container">
                    <div className="tyredetail-wrapper">
                        <Helmet>
                            <title>{ selectedTyre !== undefined ? selectedTyre.brand + ' ' + selectedTyre.tread + ' ' + selectedTyre.description : '' }</title>
                        </Helmet>
                        <div className="row tyre-detail-marg-top">
                            <div className="col-12 text-right">
                                <button
                                    className="btn btn-primary"
                                    onClick={ () => {
                                        if (this.props.navigation !== '/') {
                                            this.props.history.push( this.props.navigation );
                                        } else {
                                            this.props.history.push( '/' );
                                        }
                                    } }>
                                    Zurück
                                </button>
                            </div>
                        </div>
                        { this.props.isLoading ? (
                            <div>
                                <div className={ 'loader text-center' }/>
                                <br/>
                                <h3 className="text-center">Loading...</h3>
                            </div>
                        ) : (
                            <div className="container-fluid">
                                <div className="row">
                                    <h1 className="text-highlight d-block d-md-none"><b>{ selectedTyre.brand } { selectedTyre.tread }</b></h1>
                                    <div className={ 'col-12 img-padding-md text-center d-block d-md-none ' }>
                                        <div className="card pmd-card">
                                            <div className="pmd-tabs">
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" href="#picture" aria-controls="picture" role="tab"
                                                           data-toggle="tab">
                                                            Reifenbild
                                                        </a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href="#tyrelabel" aria-controls="tyrelabel" role="tab"
                                                           data-toggle="tab">
                                                            EU-Reifenlabel
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="card-body">
                                                <div className="tab-content">
                                                    <div role="tabpanel" className="tab-pane active" id="picture">
                                                        { this.state.imageUrl !== '/images/noimage.png' ? (
                                                            <div>
                                                                <img src={ imageUrl } className="img-fluid"/>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <img src='/images/noimage.png' className="no-image"/>
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                    <div role="tabpanel" className="tab-pane" id="tyrelabel">
                                                        <img src={ euTireLabel } className="img-fluid"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={ 'col d-none d-md-block position-relative ' }>
                                        <img className="eu-tyre-label" src={ euTireLabel }/>
                                        <SeasonIcon season={ selectedTyre.seasons }/>
                                        { this.state.imageUrl !== '/images/noimage.png' ? (
                                            <ReactImageMagnify
                                                { ...{
                                                    smallImage: {
                                                        alt: 'watch selectedTyre details',
                                                        isFluidWidth: true,
                                                        src: this.state.imageUrl,
                                                        srcSet: srcSet,
                                                        sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                                    },
                                                    largeImage: {
                                                        src: this.state.imageUrl,
                                                        width: 1200,
                                                        height: 1800
                                                    }
                                                } }
                                            />
                                        ) : (
                                            <img src={
                                                selectedTyre !== undefined && selectedTyre !== {} && selectedTyre.productImageUrl
                                                    ? imageUrl
                                                    : '/images/noimage.png'
                                            }
                                                 className="no-image"
                                                 alt="no image"
                                            />
                                        ) }
                                    </div>
                                    <div className={ 'col-12 col-md-7 col-xl-8 tyre-detail' }>
                                        <h1 className="text-highlight d-none d-md-block"><b>{ selectedTyre.brand } { selectedTyre.tread }</b></h1>
                                        <div className={ 'row ' }>
                                            <div className={ 'col-12 col-sm-12 order-md-1 order-sm-2 order-2 col-sm-push-12 col-md-8 col-xl-8' }>
                                                <div className={ 'row ' }>
                                                    <div className="col-4 tyre-details ">
                                                        Marke:
                                                    </div>
                                                    <div className="col-8 tyre-details ">
                                                        { selectedTyre.brand }
                                                    </div>
                                                </div>
                                                <div className={ 'row ' }>
                                                    <div className="col-4 tyre-details ">
                                                        Profil:
                                                    </div>
                                                    <div className="col-8 tyre-details ">
                                                        { selectedTyre.tread }
                                                    </div>
                                                </div>
                                                <div className={ 'row ' }>
                                                    <div className="col-4 tyre-details ">
                                                        <div> Fahrzeugart:</div>
                                                    </div>
                                                    <div className="col-8 tyre-details ">
                                                        { selectedTyre.vehicleType === 'vehicle_pc' ?
                                                            'PKW'
                                                            : selectedTyre.vehicleType === 'vehicle_t' ?
                                                                'LKW'
                                                                : 'SUV' }
                                                    </div>
                                                </div>
                                                <div className={ 'row ' }>
                                                    <div className="col-4 tyre-details ">
                                                        Saison:
                                                    </div>
                                                    <div className="col-8 tyre-details ">
                                                        { selectedTyre.seasons === 'season_winter' ?
                                                            'Winter'
                                                            : selectedTyre.seasons === 'season_all' ?
                                                                'Ganzjahres'
                                                                : 'Sommer' }
                                                    </div>
                                                </div>
                                                <div className={ 'row ' }>
                                                    <div className="col-4 tyre-details ">
                                                        Reifengröße:
                                                    </div>
                                                    <div className="col-8 tyre-details ">
                                                        { selectedTyre.description }
                                                    </div>
                                                </div>
                                                <div className={ 'row ' }>
                                                    <div className="col-4 tyre-details ">
                                                        Lastindex:
                                                    </div>
                                                    <div className="col-8 tyre-details ">
                                                        { selectedTyre.loadIndex }
                                                    </div>
                                                </div>
                                                <div className={ 'row ' }>
                                                    <div className="col-4 tyre-details ">
                                                        Geschwindigkeitsindex:
                                                    </div>
                                                    <div className="col-8 tyre-details ">
                                                        { selectedTyre.speedIndex }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 order-md-2 order-sm-1 order-1 col-sm-pull-12 col-md-4 col-xl-4 ">
                                                <div className="row">
                                                    <div className="col-6 col-sm-12 col-md-12 col-xl-12">
                                                        <TotalPrice price={ ( this.props.selectedTyre.B2CPrice ? this.props.selectedTyre.B2CPrice : 0 ) }
                                                                    ownClass={ 'detail' }/>
                                                    </div>
                                                        <div className={this.props.selectedTyre.B2CPrice ? "col-6 col-sm-12 col-md-12 col-xl-12 pos-dd-btn" : "col d-none"}>
                                                        <div className="custom-dd">
                                                            <DropDown items={ items }
                                                                      onChangeCallBack={ event => this.amountSelectionHandler( event, selectedTyre.productId ) }
                                                                      onChange={ event => this.amountSelectionHandler( event, selectedTyre.productId ) }
                                                                      defaultValue={ computedValue }
                                                            />
                                                        </div>
                                                        <button className={ ' btn ' + this.state.btn_class + ' float-right btn-style' }
                                                                onClick={ () => {
                                                                    this.handleShoppingCartButton( selectedTyre, this.state.computedAmount );
                                                                    this.props.onUpdateTotalPrice();
                                                                } }
                                                        >
                                                            { this.buttonContent() }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) }
                    </div>
                </div>
            );

        } else if (!this.props.isLoading) {
            content = <div>{ this.props.history.push( '/tyrenotfound' ) }</div>;
        } else {
            content = (
                <div className={ 'loading' }>
                    <div className="container">
                        <div className={ 'tyredetail-wrapper loading' }>
                            <div className="row tyre-detail-marg-top">
                                <div className="col-12 text-right">
                                    <button
                                        className="btn btn-primary"
                                        onClick={ () => {
                                            if (this.props.navigation !== '/') {
                                                this.props.history.push( this.props.navigation );
                                            } else {
                                                this.props.history.push( '/' );
                                            }
                                        } }>
                                        Zurück
                                    </button>
                                </div>
                            </div>
                            <div className="row padd-left">
                                <div className=" col-12 d-block d-md-none title"></div>
                                <div className={ 'col tyre-image ' }/>
                                <div className={ 'separator' }/>
                                <div className={ 'col-12 col-md-7 col-xl-8 tyre-detail' }>
                                    <div className={ 'row ' }>
                                        <div className=" col-6 d-none d-md-block title"></div>
                                        <div className={ 'col-12 col-sm-12 order-md-1 order-sm-2 order-2 col-sm-push-12 col-md-8 col-xl-8' }>
                                            <div className={ 'row ' }>
                                                <div className="col-7 tyre-details description">
                                                </div>
                                            </div>
                                            <div className={ 'row ' }>
                                                <div className="col-7 tyre-details description">
                                                </div>
                                            </div>
                                            <div className={ 'row ' }>
                                                <div className="col-7 tyre-details description">
                                                </div>
                                            </div>
                                            <div className={ 'row ' }>
                                                <div className="col-7 tyre-details description">

                                                </div>
                                            </div>
                                            <div className={ 'row ' }>
                                                <div className="col-9 tyre-details description">
                                                </div>
                                            </div>
                                            <div className={ 'row ' }>
                                                <div className="col-7 tyre-details description">
                                                </div>
                                            </div>
                                            <div className={ 'row ' }>
                                                <div className="col-7 tyre-details description last-element">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 order-md-2 order-sm-1 order-1 col-sm-pull-12 col-md-4 col-xl-4 ">
                                            <div className="row">
                                                <div className="col-6 col-sm-12 col-md-12 col-xl-12 price">
                                                </div>
                                                <div className="col-6 col-sm-12 col-md-12 col-xl-12 button">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return content;
    }
}

TyreDetail.serverFetch = tyreDetailActionSSR; // static declaration of data requirements

const mapStateToProps = state => ( {
    selectedTyre: state.selectedTyre.selectedTyre,
    tyres: state.tyres.tyres,
    shoppingCartTyres: state.shoppingcart.shoppingCartTyres,
    buttonState: state.shoppingcart.buttonState,
    navigation: state.navigation.location,
    isLoading: state.selectedTyre.isLoading
} );

const mapDispatchToProps = {
    tyreDetailAction,
    onAddTyreToCart: ( selectedTyre, amount ) => actionCreators.setSelectedCartTyre( selectedTyre, amount ),
    onAmountSelected: ( amount, id ) => actionCreators.setSelectedAmount( amount, id ),
    onUpdateTotalPrice: () => actionCreators.updateTotalPrice(),
    onResetTermsOfUse: () => actionCreators.resetTermsOfUse(),
    onResetDataProtection: () => actionCreators.resetDataProtection()
};

export default connect( mapStateToProps, mapDispatchToProps )( TyreDetail );
