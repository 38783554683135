import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import sessionStorage from 'redux-persist/lib/storage/session';
import {persistReducer, PURGE, REHYDRATE} from 'redux-persist';
import tyresReducer from './reducers/tyres';
import carDetailsReducer from './reducers/carDetails';
import cookieReducer from './reducers/cookie';
import navigationReducer from './reducers/navigation';
import tyreReducer from './reducers/tyre';
import furtherServicesReducer from './reducers/furtherServices';
import shoppingcartReducer from './reducers/shoppingcart';
import orderReducer from './reducers/order';
import billingAddressReducer from './reducers/billingAddress';
import routeChangedReducer from './reducers/routeChanged';
import rimReducer from './reducers/rims';
import {composeWithDevTools} from 'redux-devtools-extension';

export const initializeSession = initialFirm => ({
   type: 'INITIALIZE_SESSION',
   firmData: initialFirm,
});
export const domainSession = domain => ({
   type: 'INITIALIZE_DOMAIN',
   domain: domain
});

// test
const myResetReducer = (state = {}, action) => {
   switch (action.type) {

      case 'TIMESTAMP':
         return {'date': action.date};
      case REHYDRATE:    // This added just to show that this action type also exists, can be omitted.
         return state;
      case PURGE:
         return {};
      // Return the initial state of this reducer to 'reset' the app
      default:
         return state;
   }
};

// end test
const sessionReducer = (state = false, action) => {
   switch (action.type) {
      case 'INITIALIZE_SESSION':
         return action.firmData;
      default:
         return state;
   }
};

const domainReducer = (state = false, action) => {
   switch (action.type) {
      case 'INITIALIZE_DOMAIN':
         return action.domain;
      default:
         return state;
   }
};

export const storeTireSizes = sizes => ({
   type: 'STORE_TIRE_SIZES',
   sizes
});

export const storeBrands = brands => ({
   type: 'STORE_BRANDS',
   brands
});

export const storeEnvMode = envMode => ({
   type: 'STORE_ENV_MODE',
   envMode
});

const dataReducer = (state = {}, action) => {
   switch (action.type) {
      case 'STORE_TIRE_SIZES':
         return action.sizes;
      // case "FETCH_TIRE_SIZES_ERROR":
      //     return action.error;
      default:
         return state;
   }
};

const envReducer = (state = {}, action) => {
   switch (action.type) {
      case 'STORE_ENV_MODE':
         return action.envMode;
      default:
         return state;
   }
};

export const setNavigation = location => ({
   type: 'SERVER_SET_NAVIGATION',
   location
});

const notFoundNavigationReducer = (state = '', action) => {
   switch (action.type) {
      case 'SERVER_SET_NAVIGATION':
         return action.location;
      default:
         return state;
   }
};

const logger = store => next => action => {
   const result = next(action);
   return result;
};

const persistConfig = {
   key: 'app',
   storage: sessionStorage
};

const reducers = combineReducers({
   sizes: dataReducer,
   envMode: envReducer,
   firmData: sessionReducer,
   domain: domainReducer,
   car: carDetailsReducer,
   tyres: tyresReducer,
   selectedTyre: tyreReducer,
   location: notFoundNavigationReducer,
   furtherServices: furtherServicesReducer,
   shoppingcart: shoppingcartReducer,
   routeChanged: routeChangedReducer,
   cookie: cookieReducer,
   navigation: navigationReducer,
   order: orderReducer,
   billingAddress: billingAddressReducer,
   favRims: rimReducer,
   resetStore: myResetReducer
});

const reducer = persistReducer(persistConfig, reducers);

export default initialState =>
   createStore(reducer, initialState, composeWithDevTools(applyMiddleware(logger, thunkMiddleware)));
