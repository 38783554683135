import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = ( { staticContext = {} } ) => {
    staticContext.notFound = true;
    return (
        <div className="error-page text-center">
            <div>
                <Link to={"/"}>
                    <div className="image-pos">
                    <img src="/images/errorpage.png"
                        className="img-fluid img-size"
                    />
                    </div>
                </Link>
            </div>
            <h2>
                <b>Seite nicht verfügbar</b>
                <br/>
                Hier geht es zurück zur Reifensuche:
            </h2>
            <div className="button-pos">
                <Link className="btn btn-primary" to={"/"}>
                    Zur Suche
                </Link>
            </div>
        </div>
    );
};

export default NotFoundPage;
