import React from 'react';

class CarSelectionHeader extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         rimPath: '/'
      };
      this.backButtonHandler = () => {
         if ((this.props.history.location.pathname !== this.state.rimPath) && this.props.history.length > 1)
            this.props.history.goBack();
         else if (this.props.history.location.pathname === this.state.rimPath)
            window.location.reload();
         else this.props.history.push(this.state.rimPath)
      }
   }

   componentDidMount() {
      if (process.env.REACT_APP_CLUB == "ps-rims" || process.env.REACT_APP_CLUB == "am-rims") {
         this.setState({rimPath: '/'})
      } else if (process.env.REACT_APP_CLUB == "points" || process.env.REACT_APP_CLUB == "automeister") {
         this.setState({rimPath: '/rimconfigurator'})
      }
   }

   render() {
      const content = (
         <div className="car-selection-header">
            <div className={'container'}>
               <div className={this.props.headerClass}>
                  <h4 className="text-center">
                     Wie möchten Sie Ihre Fahrzeugangaben machen?
                  </h4>
               </div>
               <div className={(this.props.headerClass == 'd-none') ? 'd-block' : 'd-none'}>
                  <button className={'btn btn-primary float-right '} onClick={this.backButtonHandler}>
                     Zurück
                  </button>
               </div>
            </div>
         </div>
      );
      return content;
   }
}

export default CarSelectionHeader;
