import React from 'react';
import { Link } from 'react-router-dom';

class Breadcrumb extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            step: 1,
            steps: 'BranchChecker',
            mobile: false
        };
    }

    componentDidMount() {
        if (window.innerWidth < 700) {
            this.setState( { mobile: true } );
        } else if (window.innerWidth >= 700 && this.state.mobile === true) {
            this.setState( { mobile: false } );
        }
    }

    componentDidUpdate() {
        if (this.state.step != this.props.step) {
            this.setState( {
                step: this.props.step,
                steps: this.props.steps
            } );
        }
    }

    render() {

        return (
            <div>
                <div className="breadcrumbs">
                    <div className={ this.state.mobile == true ? '' : 'container' }>
                        { /*-------------------------------------Branch Checker--------------------------------------------*/ }
                        { this.state.step == 1 ?
                            <span className="breadcrumbs__inner active">
                            <span className="breadcrumbs__title step-1">
                                <b> Filiale </b> <br/> bestimmen
                            </span>
                        </span>
                            :
                            ( this.state.step > 1 ?
                                    <span onClick={ () => this.props.callBackFromParent( {
                                        step: 1,
                                        steps: 'BranchChecker'
                                    } ) }>
                                    <span className="breadcrumbs__inner clickable">
                                        <span className="breadcrumbs__title step-1">
                                            <b> Filiale </b> <br/> bestimmen
                                        </span>
                                    </span>
                                    </span>
                                    :
                                    <span className="breadcrumbs__inner">
                                    <span className="breadcrumbs__title step-1">
                                        <b> Filiale </b> <br/> bestimmen
                                    </span>
                                </span>
                            )
                        }

                        { /*-------------------------------------Assembly Date---------------------------------------------*/ }
                        { this.state.step == 2 ?
                            <span className="breadcrumbs__inner active">
                            <span className="breadcrumbs__title step-2">
                                <b> Termine </b> <br/> wählen
                            </span>
                        </span>
                            :
                            ( this.state.step > 2 ?
                                    <span onClick={ () => this.props.callBackFromParent( {
                                        step: 2,
                                        steps: 'AssemblyDate'
                                    } ) }>
                                    <span className="breadcrumbs__inner clickable">
                                        <span className="breadcrumbs__title step-2">
                                            <b> Termine </b> <br/> wählen
                                        </span>
                                    </span>
                                    </span>
                                    :
                                    <span className="breadcrumbs__inner">
                                    <span className="breadcrumbs__title step-2">
                                        <b> Termine </b> <br/> wählen
                                    </span>
                                </span>
                            )
                        }

                        { /*-------------------------------------Billing Address-------------------------------------------*/ }
                        { this.state.step == 3 ?
                            <span className="breadcrumbs__inner active">
                            <span className="breadcrumbs__title step-3">
                                <b> Adresse </b> <br/> eingeben
                            </span>
                        </span>
                            :
                            ( this.state.step > 3 ?
                                    <span onClick={ () => this.props.callBackFromParent( {
                                        step: 3,
                                        steps: 'BillingAddress'
                                    } ) }>
                                    <span className="breadcrumbs__inner clickable">
                                        <span className="breadcrumbs__title step-3">
                                            <b> Adresse </b> <br/> eingeben
                                        </span>
                                    </span>
                                    </span>
                                    :
                                    <span className="breadcrumbs__inner">
                                    <span className="breadcrumbs__title step-3">
                                        <b> Adresse </b> <br/> eingeben
                                    </span>
                                </span>
                            )
                        }

                        { /*-----------------------------------Checkout Confirmation--------------------------------------*/ }
                        { this.state.step == 4 ?
                            <span className="breadcrumbs__inner active">
                            <span className="breadcrumbs__title step-4">
                                <b> Bestellung </b> <br/> bestätigen
                            </span>
                        </span>
                            :
                            ( this.state.step > 4 ?
                                    <div className={ 'bc-seperator' } onClick={ () => this.props.callBackFromParent( {
                                        step: 4,
                                        steps: 'CheckoutConfirmation'
                                    } ) }>
                                    <span className="breadcrumbs__inner clickable">
                                        <span className="breadcrumbs__title step-4">
                                            <b> Bestellung </b> <br/> bestätigen
                                        </span>
                                    </span>
                                    </div>
                                    :
                                    <span className="breadcrumbs__inner">
                                    <span className="breadcrumbs__title step-4">
                                        <b> Bestellung </b> <br/> bestätigen
                                    </span>
                                </span>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Breadcrumb;
