import React from 'react';
import { Link } from 'react-router-dom';

// redux
import { connect } from 'react-redux';
import * as actionCreators from '../../../../../../redux-store/actions/index';
import Breadcrumb from '../../../../shared/breadcrumb/Breadcrumb';

let fields;

class BillingAddress extends React.Component {
    constructor( props ) {
        super( props );
        // initial state
        this.state = {
            dataProtection: this.props.dataProtection,
            termsOfUse: this.props.termsOfUse,
            finish: !!this.props.finish || false,
            tyres: this.props.userdata.tyres,
            userdata: this.props.userdata.userdata,
            passedvalues: this.props.userdata.passedvalues,
            url: '',
            steps: 'CheckoutConfirmation',
            step: 4,
            buttonType: 'submit',
            buttonText: 'Zur Bestellübersicht',
            isDisabled: true
        };
    }

    validateFirm( _state ) {
        if (_state.dataProtection && _state.termsOfUse &&
            ( this.state.userdata.fields.adress != undefined && this.state.userdata.fields.adress != '' ) &&
            ( this.state.userdata.fields.name != undefined && this.state.userdata.fields.name != '' ) &&
            ( this.state.userdata.fields.firstname != undefined && this.state.userdata.fields.firstname != '' ) &&
            ( this.state.userdata.fields.city != undefined && this.state.userdata.fields.city != '' ) &&
            ( this.state.userdata.fields.zipcode != undefined && this.state.userdata.fields.zipcode != '' ) &&
            ( this.state.userdata.fields.phone != undefined && this.state.userdata.fields.phone != '' ) &&
            ( this.state.userdata.fields.email != undefined && this.state.userdata.fields.email != '' )) {
            return true;
        } else {
            return false;
        }
    }

    handleDataProtection() {
        let _state = this.state;
        _state.dataProtection = !this.state.dataProtection;
        _state.isDisabled = !this.validateFirm( _state );
        this.setState( {
            _state
        } );
    };

    handleTermsOfUse() {
        let _state = this.state;
        _state.termsOfUse = !this.state.termsOfUse;
        _state.isDisabled = !this.validateFirm( _state );
        this.setState( {
            _state
        } );
    }

    vendorMessageHandler( field, e ) {
        fields = this.state.userdata.fields;
        fields[field] = e.target.value;
        let userdata = this.state.userdata;
        userdata.fields = fields;
        this.setState( { userdata: userdata } );
    }

    contactSubmit( e ) {
        e.preventDefault();
        this.state.url = '/CheckoutConfirmation';
        this.props.callBackFromParent( this.state );
        this.setState( { buttonText: 'Zur Bestellübersicht' } );
    }

    formChangeHandler( field, e ) {
        let fields = this.state.userdata.fields;
        fields[field] = e.target.value;

        let _state = this.state;
        _state.userdata.fields = fields;
        _state.isDisabled = !this.validateFirm( _state );
        this.setState( { _state } );
    }

    render() {
        return (
            <div className="container">
                <div className={ 'billing-address-wrapper' }>
                    <div className="row">
                        <div className="col-12">
                            <h2 className="rechnungs-adresse-text ">RECHNUNGSADRESSE</h2>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-12">
                            <form
                                className="billingadress-form"
                                id="billingadress-form"
                                onSubmit={ this.contactSubmit.bind( this ) }
                            >
                                <div className="row">
                                    <div className="col-12 col-xl-6 col-md-6 col-sm-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    required={ true }
                                                    className={ 'form-control' }
                                                    id="firstname"
                                                    type="text"
                                                    defaultValue={ this.state.userdata.firstname }
                                                    placeholder="Vorname*"
                                                    onChange={ this.formChangeHandler.bind( this, 'firstname' ) }
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className={ 'row contact-form-elements-pos' }>
                                            <div className="col-12">
                                                <input
                                                    required={ true }
                                                    className={ 'form-control' }
                                                    id="lastname"
                                                    type="text"
                                                    defaultValue={ this.state.userdata.lastname }
                                                    placeholder="Nachname*"
                                                    onChange={ this.formChangeHandler.bind( this, 'name' ) }
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className={ 'row contact-form-elements-pos' }>
                                            <div className="col-12">
                                                <input
                                                    required={ true }
                                                    className={ 'form-control' }
                                                    id="adress"
                                                    type="text"
                                                    defaultValue={ this.state.userdata.adress }
                                                    placeholder="Adresse*"
                                                    onChange={ this.formChangeHandler.bind( this, 'adress' ) }
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className={ 'row contact-form-elements-pos' }>
                                            <div className="col-6">
                                                <input
                                                    required={ true }
                                                    className={ 'form-control' }
                                                    id="zipcode"
                                                    type="text"
                                                    defaultValue={ this.state.userdata.zipcode }
                                                    minLength={ 5 }
                                                    maxLength={ 5 }
                                                    placeholder="PLZ*"
                                                    pattern="[0-9]{5}"
                                                    onChange={ this.formChangeHandler.bind( this, 'zipcode' ) }
                                                />
                                            </div>
                                            <div className="col-6">
                                                <input
                                                    required={ true }
                                                    className={ 'form-control' }
                                                    id="city"
                                                    type="text"
                                                    pattern="[A-Z,a-z,\u00c4,\u00e4, \u00d6, \u00f6, \u00dc, \u00fc, \u00df]{2,}"
                                                    defaultValue={ this.state.userdata.city }
                                                    placeholder="Stadt*"
                                                    onChange={ this.formChangeHandler.bind( this, 'city' ) }
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row">
                                            <div className={ 'col-12 contact-form-elements-pos' }>
                                                <input
                                                    required={ true }
                                                    className={ 'form-control' }
                                                    id="phone"
                                                    type="phone"
                                                    // 'Deutsche Telefonnummern' Matches German phone and fax numbers (including cell phone numbers) in various formats like:
                                                    // 004989123456, +49 89 123456, +49(89)123456, 089-1234-5678, 089 1234 5678, (089)1234-5678 Max. number of digits is 21.
                                                    // Matches: +49(89)123456 | 089-1234-5678 | (089)1234-5678
                                                    // Non-Matches: 0172.55555
                                                    pattern="^(((((((00|\+)[ \-/]?)|0)[1-9][0-9]{1,4})[ \-/]?)|((((00|\+)\()|\(0)[1-9][0-9]{1,4}\)[ \-/]?))[0-9]{1,7}([ \-/]?[0-9]{1,5})?)$"
                                                    defaultValue={ this.state.userdata.phone }
                                                    placeholder="Telefon*"
                                                    onChange={ this.formChangeHandler.bind( this, 'phone' ) }
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className={ 'row contact-form-elements-pos' }>
                                            <div className="col-12">
                                                <input
                                                    required={ true }
                                                    className={ 'form-control' }
                                                    id="mail"
                                                    pattern="[A-Z0-9._%+-,a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                    type='email'
                                                    defaultValue={ this.state.userdata.email }
                                                    placeholder="E-Mail*"
                                                    onChange={ this.formChangeHandler.bind( this, 'email' ) }
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="row">
                                            <div className="col-12 text-highlight marg-bottom">
                                                *Pflichtfelder
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6 col-md-6 col-sm-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea
                                                    className={ 'message-field' }
                                                    placeholder="Ihre Mitteilung an uns"
                                                    onChange={ this.vendorMessageHandler.bind(
                                                        this,
                                                        'vendormessage'
                                                    ) }
                                                />
                                            </div>
                                        </div>
                                        <br/>

                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    type="checkbox"
                                                    id="checkbox1"
                                                    className=""
                                                    onClick={ this.handleTermsOfUse.bind( this ) }
                                                    defaultChecked={ this.state.termsOfUse }
                                                />
                                                <label className="checkout-info-text" for="checkbox1">
                                                    { ' ' } Ich habe die
                                                    <Link to="/termsofuse" target="_blank"> Nutzungsbedingungen </Link>
                                                    gelesen und akzeptiert*
                                                </label>
                                            </div>
                                        </div>
                                        <br/>

                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    type="checkbox"
                                                    id="checkbox2"
                                                    className=""
                                                    onClick={ this.handleDataProtection.bind( this ) }
                                                    defaultChecked={ this.state.dataProtection }
                                                />
                                                <label className="checkout-info-text" for="checkbox2">
                                                    { ' ' }Ich habe die
                                                    <Link to="/termsofuse" target="_blank"> Datenschutzerklärung </Link>
                                                    gelesen und akzeptiert*
                                                 </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className={ 'col-12 zur-bestelluebersicht-button' }>
                                                <button
                                                    className={ 'btn btn-success' }
                                                    id={ this.state.buttonType }
                                                    type={ this.state.buttonType }
                                                    disabled={ this.state.isDisabled }
                                                >
                                                    { this.state.buttonText }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        termsOfUse: state.billingAddress.termsOfUse,
        dataProtection: state.billingAddress.dataProtection
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetTermsOfUse: () => dispatch( actionCreators.setTermsOfUse() ),
        onResetTermsOfUse: () => dispatch( actionCreators.resetTermsOfUse() ),
        onResetDataProtection: () => dispatch( actionCreators.resetDataProtection() ),
        onSetDataProtection: () => dispatch( actionCreators.setDataProtection() )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)( BillingAddress );
