import React from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../../../../../redux-store/actions';
import Dropdown from '../../../../shared/dropDown/DropDown';
// RangeSlider
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
// react-select
import Select from 'react-select';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const handle = props => {
   const {value, dragging, index, ...restProps} = props;
   return (
      <Tooltip prefixCls="rc-slider-tooltip"
               overlay={value}
               visible={dragging}
               placement="top"
               key={index}>
         <Handle value={value} {...restProps} />
      </Tooltip>
   );
};

const wrapperStyle = {
   width: 250,
   margin: 0,
   padding: 0
};

class Filterelement extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         lowerBound: 25,
         upperBound: 9000,
         value: [25, 9000],
         selectedOption: [],
         nEValue: [66, 75],
         wGValue: [1, 6],
         rRValue: [1, 7],
         runflat: [],
         dotDemo: [],
         priceChanged: 250,
         maxPrice: 500,
         id: '',
         values: this.props.values,
         checked: []
      };
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleDropdownChange = this.handleDropdownChange.bind(this);
      this.handleDotDemoOptionChange = this.handleDotDemoOptionChange.bind(this);
      this.handleRFTOptionChange = this.handleRFTOptionChange.bind(this);
      this.handleBrandChange = this.handleBrandChange.bind(this);
      this.onLowerBoundChange = this.onLowerBoundChange.bind(this);
      this.onUpperBoundChange = this.onUpperBoundChange.bind(this);
      this.onSliderChange = this.onSliderChange.bind(this);
      this.handleApply = this.handleApply.bind(this);
   }

   onLowerBoundChange(e) {
      this.setState({lowerBound: +e.target.value});
   }

   onUpperBoundChange(e) {
      this.setState({upperBound: +e.target.value});
   }

   onSliderChange(value) {
      log(value);
      this.setState({
         value
      });
   }

   handleApply() {
      const {lowerBound, upperBound} = this.state;
      this.setState({value: [lowerBound, upperBound]});
   }

   handleBrandChange(selectedOption) {
      this.scrollToTop();
      this.props.onResetSetSearchFlag();
      this.state.selectedOption = selectedOption;
      this.props.onSetPage(0);
      this.props.onSetFilterSelections('BRAND', this.state.selectedOption);
      this.props.onFilterSelections();
      let newUrlValues = null;
      let filterValues = this.props.filterSelections;
      newUrlValues = {
         ...this.props.StateValues,
         filterValues
      };
      let newbuffer = Buffer.from(JSON.stringify(newUrlValues))
      .toString(
         'base64'
      );
      this.props.onComputedUrlValue(newbuffer);
   }

   tipFormat(val) {
      if (val === 1) {
         return 'A';
      } else if (val === 2) {
         return 'B';
      } else if (val === 3) {
         return 'C';
      } else if (val === 4) {
         return 'D';
      } else if (val === 5) {
         return 'E';
      } else if (val === 6) {
         return 'F';
      } else if (val === 7) {
         return 'G';
      }
   }

   handleDropdownChange(e) {
   }

   handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      this.setState({
         [name]: value
      });
   }

   handleRFTOptionChange(event) {
      this.scrollToTop();
      this.props.onResetSetSearchFlag();
      this.props.onSetPage(0);
      this.props.onSetFilterSelections(event.target.name, event.target.id);
      this.props.onFilterSelections();
      let newUrlValues = null;
      let filterValues = this.props.filterSelections;
      newUrlValues = {
         ...this.props.StateValues,
         filterValues
      };
      let newbuffer = Buffer.from(JSON.stringify(newUrlValues))
      .toString(
         'base64'
      );
      this.props.onComputedUrlValue(newbuffer);
   }

   handleDotDemoOptionChange(event) {
      this.scrollToTop();
      this.props.onResetSetSearchFlag();
      this.props.onSetPage(0);
      this.props.onSetFilterSelections(event.target.name, event.target.id);
      this.props.onFilterSelections();
      let newUrlValues = null;
      let filterValues = this.props.filterSelections;
      newUrlValues = {
         ...this.props.StateValues,
         filterValues
      };
      let newbuffer = Buffer.from(JSON.stringify(newUrlValues))
      .toString(
         'base64'
      );
      this.props.onComputedUrlValue(newbuffer);
   }

   handleNEChange(nEValue) {
      this.props.onResetSetSearchFlag();
      this.setState({
         nEValue: nEValue
      });
      this.state.nEValue = nEValue;
      this.props.onSetPage(0);
      this.props.onSetFilterSelections('noise_emission', this.state.nEValue);
      this.props.onFilterSelections();
      let newUrlValues = null;
      let filterValues = this.props.filterSelections;
      newUrlValues = {
         ...this.props.StateValues,
         filterValues
      };
      let newbuffer = Buffer.from(JSON.stringify(newUrlValues))
      .toString(
         'base64'
      );
      this.props.onComputedUrlValue(newbuffer);
      this.scrollToTop();
   }

   handleWGChange(wGValue) {
      this.props.onResetSetSearchFlag();
      this.setState({
         wGValue: wGValue
      });
      this.props.onSetPage(0);
      this.props.onSetFilterSelections('wet_grip', wGValue);
      this.props.onFilterSelections();
      let newUrlValues = null;
      let filterValues = this.props.filterSelections;
      newUrlValues = {
         ...this.props.StateValues,
         filterValues
      };
      let newbuffer = Buffer.from(JSON.stringify(newUrlValues))
      .toString(
         'base64'
      );
      this.props.onComputedUrlValue(newbuffer);
      this.scrollToTop();
   }

   handleRRChange(rRValue) {
      this.props.onResetSetSearchFlag();
      this.setState({
         rRValue: rRValue
      });
      this.props.onSetPage(0);
      this.props.onSetFilterSelections('rolling_resistance', rRValue);

      this.props.onFilterSelections();
      let newUrlValues = null;
      let filterValues = this.props.filterSelections;
      newUrlValues = {
         ...this.props.StateValues,
         filterValues
      };
      let newbuffer = Buffer.from(JSON.stringify(newUrlValues))
      .toString(
         'base64'
      );
      this.props.onComputedUrlValue(newbuffer);
      this.scrollToTop();
   }

   // {/*------------------------------------Noise Emission-------------------------------------*/ }
   noiseEmission() {
      return (
         <div>
            <h5>Geräuschentwicklung</h5>
            <div style={wrapperStyle}>
               <Range
                  min={66}
                  max={75}
                  defaultValue={this.props.nEValues}
                  allowCross={false}
                  onAfterChange={nEValue => this.handleNEChange(nEValue)}
                  disabled={this.props.isLoading ? true : false}
               />
               <span>von {this.props.nEValues[0]}</span>
               <span style={{float: 'right'}}>bis {this.props.nEValues[1]}</span>
            </div>
         </div>
      );
   }

   // {/*------------------------------------Rolling Resistance-------------------------------------*/ }
   rollingResistance() {
      return (
         <div>
            <h5>Rollwiderstand</h5>
            <div style={wrapperStyle}>
               <Range
                  min={1}
                  max={7}
                  tipFormatter={value => this.tipFormat(value)}
                  defaultValue={this.props.rRValues}
                  allowCross={false}
                  onAfterChange={rRValue => this.handleRRChange(rRValue)}
                  disabled={this.props.isLoading ? true : false}
               />
               <span>von {this.props.finalRRValues[0]}</span>
               <span style={{float: 'right'}}>
            bis {this.props.finalRRValues[1]}
          </span>
            </div>
         </div>
      );
   }

   priceRange() {
      return (
         <div>
            <h5>Preisspanne</h5>
            <div style={wrapperStyle}>
               <input className="priceRangeMin"
                      type="number"
                      value={this.state.lowerBound}
                      onChange={this.onLowerBoundChange}
               />
               <span className="minusToTop"> _ </span>
               <input className="priceRangeMax"
                      type="number"
                      value={this.state.upperBound}
                      onChange={this.onUpperBoundChange}
               />
               <button className={'btn btn-primary priceRangeFilterSubmit'}
                       onClick={() => {
                       }}>
                  <span>&#10132;</span>
               </button>
               {' '}
               <br/>
               <br/>
            </div>
         </div>
      );
   }

   // {/*------------------------------------Noise Emission-------------------------------------*/ }
   wetGrip() {
      return (
         <div>
            <h5>Nasshaftung</h5>
            <div style={wrapperStyle}>
               <Range min={1}
                      max={6}
                      defaultValue={this.props.wGValues}
                      tipFormatter={value => this.tipFormat(value)}
                      allowCross={false}
                      onAfterChange={wGValue => this.handleWGChange(wGValue)}
                      disabled={this.props.isLoading ? true : false}
               />
               <span>von {this.props.finalWGValues[0]}</span>
               <span style={{float: 'right'}}>
            bis {this.props.finalWGValues[1]}
          </span>
            </div>
         </div>
      );
   }

   // {/*-------------------------------Eigenschaft------------------------------------*/ }
   property() {
      // rft
      let checked = [];
      for (let i in this.props.vals) {
         checked[this.props.vals[i]] = true;
      }
      // dot/demo
      let checkd = [];
      for (let i in this.props.dotValues) {
         checkd[this.props.dotValues[i]] = true;
      }
      return (
         <div>
            <div className="custom-control custom-checkbox">
               <input className="custom-control-input"
                      id="property"
                      name="property"
                      type="checkbox"
                      onChange={this.handleInputChange}
               />
               <label className="custom-control-label-selection" htmlFor="property">
                  <h5> Eigenschaften</h5>
               </label>
            </div>
            {/*       Auswahl      */}
            <div className="row">
               <div className="col">
                  <div id="#showProperty">
                     <div className="custom-control custom-checkbox">
                        <input className="custom-control-input"
                               id="runflat"
                               name="property"
                               type="checkbox"
                               onChange={this.handleRFTOptionChange.bind(this)}
                               disabled={this.props.isLoading ? true : false}
                               defaultChecked={checked['runflat']}
                        />
                        <label className="custom-control-label" htmlFor="runflat">
                           <span className="checkboxText">Runflat</span>
                        </label>
                     </div>
                  </div>
               </div>
            </div>
            <br/>
         </div>
      );
   }

   brand() {
      return (
         <div>
            <h5> Marke</h5>
            <div className="react-select-container">
               <Select value={this.props.bValues}
                       onChange={this.handleBrandChange}
                       options={this.props.options}
                       isMulti={true}
                       isSearchable={true}
                       placeholder={'Auswählen'}
                       isDisabled={this.props.isLoading ? true : false}
               />
            </div>
         </div>
      );
   }

   // {/*-----------------------------------Qualitaet----------------------------------*/ }
   quality() {
      return (
         <div>
            <div className="custom-control custom-checkbox">
               <input
                  className="custom-control-input"
                  id="quality"
                  name="quality"
                  type="checkbox"
                  defaultChecked={this.state.quality}
                  onChange={this.handleInputChange}
               />
               <label className="custom-control-label-selection" htmlFor="quality">
                  <h5>Qualitiät</h5>
               </label>
            </div>
            <div
               id="#showQualtiy"
               className={this.state.quality ? 'show' : 'hidden'}
            >
               <Dropdown
                  items={['Alle', 'Budget', 'Quality', 'Premium']}
                  id="quality"
                  defaultValue={'Alle'}
                  onChangeCallBack={this.handleDropdownChange}
               />
            </div>
         </div>
      );
   }

   scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
   }

   render() {
      let {options} = this.props;

      function compare(a, b) {
         const valueA = a.label.toUpperCase();
         const valueB = b.label.toUpperCase();

         let comparison = 0;
         if (valueA > valueB) {
            comparison = 1;
         } else if (valueA < valueB) {
            comparison = -1;
         }
         return comparison;
      }

      options = options.sort(compare);
      return (
         <div>
            <div className="filter-seperator">{this.brand()}</div>
            <br/>
            <div className="filter-element seperator">
               {this.property()}
            </div>
            <div className="filter-element">
               {this.noiseEmission()}
               <br/>
            </div>
            <div className="filter-element">
               {this.wetGrip()}
               <br/>
            </div>
            <div className="filter-element">
               {this.rollingResistance()}
               <br/>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => ({
   values: state.routeChanged.values,
   filterSelections: state.routeChanged.filterSelections,
   // property rft
   vals: state.routeChanged.vals,
   // property dot/demo
   dotValues: state.routeChanged.dotValues,
   // noise emission
   nEValues: state.routeChanged.nEValues,
   // WET GRIP
   wGValues: state.routeChanged.wGValues,
   finalWGValues: state.routeChanged.finalWGValues,
   // rolling resistance
   rRValues: state.routeChanged.rRValues,
   finalRRValues: state.routeChanged.finalRRValues,
   // new selected brand values
   brandValues: state.routeChanged.brandValues,
   // brand values
   bValues: state.routeChanged.bValues,
   // brand options
   options: state.routeChanged.options,
   isLoading: state.tyres.isLoading,
   // test
   lastBValues: state.routeChanged.lastBValues,
   // test
   changeFlag: state.routeChanged.changeFlag
});

const mapDispatchToProps = {
   onFilterSelections: () => actionCreators.filterSelections(),
   onSetFilterSelections: (id, value) =>
      actionCreators.setFilterSelections(id, value),
   onComputedUrlValue: val => actionCreators.newComputedUrlValue(val),
   onSetPage: page => actionCreators.setPage(page),
   onResetSetSearchFlag: () => actionCreators.resetSearchFlag()
};

export default connect(mapStateToProps, mapDispatchToProps)(Filterelement);
