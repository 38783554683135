import * as actionTypes from "../actions/actionTypes";

const initialState = {
  termsOfUse: false,
  dataProtection: false
};

const reducer = (state, action) => {
  state = state || initialState;
  let _termsOfUse = state.termsOfUse;
  let _dataProtection = state.dataProtection;
  switch (action.type) {
    case actionTypes.SET_TERMS_OF_USE:
      _termsOfUse = action.termsOfUse;
      {
        return Object.assign({}, state, {
          termsOfUse: _termsOfUse
        });
      }

    case actionTypes.RESET_TERMS_OF_USE: {
      _termsOfUse = action.termsOfUse;
      return Object.assign({}, state, {
        termsOfUse: _termsOfUse
      });
    }
    case actionTypes.SET_DATA_PROTECTION:
      _dataProtection = action.dataProtection;
      {
        return Object.assign({}, state, {
          dataProtection: _dataProtection
        });
      }

    case actionTypes.RESET_DATA_PROTECTION: {
      _dataProtection = action.dataProtection;
      return Object.assign({}, state, {
        dataProtection: _dataProtection
      });
    }
  }
  return state;
};

export default reducer;
