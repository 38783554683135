import * as actionTypes from "./actionTypes";

export const setSelectedCartTyre = (selectedTyre, amount) => {
  return {
    type: actionTypes.SET_SELECTED_CART_selectedTyre,
    selectedTyre: selectedTyre,
    amount: amount
  };
};

export const setSelectedAmount = (computedSelectionAmount, id) => {
  return {
    type: actionTypes.SET_SELECTED_AMOUNT,
    computedAmount: computedSelectionAmount,
    tyreId: id
  };
};

export const setShoppingCartTireSelectedAmount = (amount, tyreId) => {
  return {
    type: actionTypes.SET_SHOPPING_CART_TIRE_SELECTED_AMOUNT,
    amount: amount,
    tyreId: tyreId
  };
};

export const setShoppingCartTireChanged = (tyre) => {
  return {
    type: actionTypes.SET_SHOPPING_CART_TIRE_CHANGED,
    selectedTyre: tyre
  };
};

export const removeCartTire = index => {
  return {
    type: actionTypes.DELETE_CART_TIRE,
    cartTireIndex: index
  };
};

export const amountSelectionChanged = amount => {
  return {
    type: actionTypes.AMOUNT_SELECTION_CHANGED,
    amountSelectionChanged: amount
  };
};

export const countAmount = () => {
  return {
    type: actionTypes.AMOUNT_COUNT
  };
};

export const updateTotalPrice = ( ) => {
  return {
    type: actionTypes.UPDATE_TOTAL_PRICE
  };
};

export const saveButtonState = state => {
  return {
    type: actionTypes.SAVE_BUTTON_STATE,
    buttonState: state
  };
};

export const resetSc = () => {
  return {
    type: actionTypes.RESET_SC
  };
};

export const createTimeStamp = () => {
  return {
    type: actionTypes.CREATE_TIME_STAMP
  };
};
export const selectedAssemblyCost = (assemblyCosts, tireId, selectedAssemblyCosts) => {
  return {
    type: actionTypes.SELECTED_ASSEMBLY_COST,
    assemblyCosts: assemblyCosts,
    tireId: tireId,
    selectedAssemblyCosts: selectedAssemblyCosts
  };
};

export const assemblyCosts = (assemblyCosts) => {
  return {
    type: actionTypes.ASSEMBLY_COSTS,
    assemblyCosts: assemblyCosts
  };
};
