import React from 'react';
import Helmet from 'react-helmet';

const News = () => (
    <div className="news-wrapper">
        <h2>This is the news page</h2>
        <Helmet>
            <title>News Page</title>
            <div className="container">
                <meta
                    name="description"
                    content="This is a proof of concept for React SSR"
                />
            </div>
        </Helmet>
    </div>
);

export default News;
