import React from 'react';

const drawerToggleButton = props => (
    <button
        type="button"
        className={
            'navbar-toggle toggle-button ' +
            ( props.status === true ? 'button-open' : 'collapsed' )
        }
        onClick={ props.click }
    >
        <span className="icon-bar top-bar"/>
        <span className="icon-bar middle-bar"/>
        <span className="icon-bar bottom-bar"/>
    </button>
);

export default drawerToggleButton;
