import React from 'react';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import Helmet from 'react-helmet';


class UnresolvedHostname extends React.Component {
    constructor( props ) {
        super( props );
        // initial state
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <Helmet>
                    <title> { "Fehler: unresolved hostname" } </title>
                </Helmet>
                <div className="row">
                    <div className="col-4"/>
                    <h2>Fehler: {this.props.errorTyre? this.props.errorselectedTyre : this.props.errorTyres}</h2>
                    <div className="col-8">
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ( {
    isLoading: state.selectedTyre.isLoading,
    errorselectedTyre: state.selectedTyre.error,
    errorTyres: state.tyres.error
} );

const mapDispatchToProps = {
};

export default connect( mapStateToProps, mapDispatchToProps )( UnresolvedHostname );

