import React from 'react';

class SeasonIcon extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            season: ''
        };
    }

    render() {
        return (
            <div className={ 'season-icon-wrapper' }>
                { this.props.season === 'season_winter'
                    ?
                    <span className={ 'icon-Schnee' }/>
                    : this.props.season === 'season_summer'
                        ? <span className={ 'icon-sonne' }/>
                        : <span className={ 'icon-allwetter' }/>
                }
            </div>
        );
    }
}

export default SeasonIcon;
