import * as actionTypes from "./actionTypes";

export const changeRouteFrom = from => {
  return {
    type: actionTypes.SET_ROUTE_CHANGED_FROM,
    from: from
  };
};

export const changeRouteTo = to => {
  return {
    type: actionTypes.SET_ROUTE_CHANGED_TO,
    to: to
  };
};

export const filterSelections = () => {
  return {
    type: actionTypes.FILTER_SELECTIONS
  };
};

export const setFilterSelections = (id, value) => {
  return {
    type: actionTypes.SET_FILTER_SELECTIONS,
    id: id,
    value: value
  };
};

export const resetFilterSelections = () => {
  return {
    type: actionTypes.RESET_FILTER_SELECTIONS
  };
};

export const newComputedUrlValue = val => {
  return {
    type: actionTypes.COMPUTED_URL_VALUE,
    url: val
  };
};

export const selectedSortValue = val => {
  return {
    type: actionTypes.SELECTED_SORT_VALUE,
    sortValue: val
  };
};
export const changeFlag = () => {
  return {
    type: actionTypes.FLAG_CHANGED
  };
};

export const resetSearchFlag = () => {
  return {
    type: actionTypes.RESET_SEARCH_FLAG
  };
};

export const vehicleTypeChanged = vehicleType => {
  return {
    type: actionTypes.SET_SELECTED_VEHICLE_TYPE,
    vehicleType: vehicleType
  };
};

export const seasonChanged = season => {
  return {
    type: actionTypes.SET_SELECTED_SEASON,
    season: season
  };
};

export const speedChanged = speed => {
  return {
    type: actionTypes.SET_SELECTED_SPEED,
    speed: speed
  };
};

export const loadChanged = load => {
  return {
    type: actionTypes.SET_SELECTED_LOAD,
    load: load
  };
};

export const lengthChanged = length=> {
  return {
    type: actionTypes.SET_SELECTED_LENGTH,
    length: length
  };
};
export const heightChanged = height=> {
  return {
    type: actionTypes.SET_SELECTED_HEIGHT,
    height: height
  };
};

export const diameterChanged = diameter=> {
  return {
    type: actionTypes.SET_SELECTED_DIAMETER,
    diameter: diameter
  };
};

export const searchRequest = () => {
  return {
    type: actionTypes.LIST_REQUEST
  };
};


export const searchSuccess = (json) => {
  return {
    type: actionTypes.LIST_SUCCESS,
    results: json
  };
};

export const searchError = (err) => {
  return {
    type: actionTypes.LIST_ERROR,
    errorMessage: err.message
  }
}
