import * as actionTypes from '../actions/actionTypes';

const initialState = {
    cookieStatus: null
};

const reducer = ( state = initialState, action ) => {
    let _cookieStatus = state.cookieStatus;

    switch (action.type) {

        case actionTypes.COOKIE_ACCEPTED:
            _cookieStatus = action.cookieStatus;
            return {
                cookieStatus: _cookieStatus
            };

        default: break;
    }
    return state;
};

export default reducer;