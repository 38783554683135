import * as actionTypes from "../actions/actionTypes";

const initialState = {
    tyres: [],
    isLoading: false,
    error: null,
    totalCount: null
};

const reducer = (state = initialState, action) => {
    let _selectedTyres = state.tyres;
    let _error = state.error;
    let _totalCount = state.totalCount;
    switch (action.type) {
        case actionTypes.STORE_selectedTyreS:
            _totalCount = action.tyres.pop();
            _selectedTyres = action.tyres;
            return {
                tyres: _selectedTyres,
                isLoading: false,
                totalCount: _totalCount,
                error: null
            };

        case actionTypes.LOAD_selectedTyreS:
            return {
                error: null,
                isLoading: true
            };

        case actionTypes.FETCH_selectedTyreS_ERROR:
            _error = action.error;
            return {
                error: _error,
                isLoading: false,
                totalCount: null,
                tyres: [],
            };

        default:
            break;
    }
    ;
    return state;
};

export default reducer;

